import { parseISO, format } from 'date-fns';

export const rejectionCodesDeselected = {
  'Avg. Claims Above Household Limit': false,
  'Above Household Limit Number': false,
  'IP Match': false,
  'Case Number Plaintiff Names': false,
  'Over Household Limit Address': false,
  'Ober Household Limit IP': false,
  'Case Over Household Limit': false,
  'Case Over Household Limit %': false,
  'Over Maximum Number': false,
  'Over Maximum %': false,
  'Close to Deadline': false,
  'Flagged Source': false,
  'Flagged Source Number': false,
  'Historic Claim Frequency': false,
  'Historic Claim Number': false,
  'Historic Rejected Number': false,
  'Historic Rejected %': false,
  'Below Maximum': false,
  'No Source': false,
  'No Source Number': false,
  'Plaintiff Names': false,
  'Outside Jurisdiction': false,
  'Over Household Limit': false,
  'Over Household Limit %': false,
  'Programmatic Duplicate': false,
  'Maximum Number of Units': false,
  "Duplicate Claim": false,
  "Random Handle": false,
  "IP Physical Mismatch": false,
  "Phone Address Mismatch": false,
  "Invalid Products": false,
  "Invalid Serial No": false,
  "No Documentation": false,
};

export const rejectionCodesAllSelected = {
  'Avg. Claims Above Household Limit': true,
  'Above Household Limit Number': true,
  'IP Match': true,
  'Case Number Plaintiff Names': true,
  'Over Household Limit Address': true,
  'Ober Household Limit IP': true,
  'Case Over Household Limit': true,
  'Case Over Household Limit %': true,
  'Over Maximum Number': true,
  'Over Maximum %': true,
  'Close to Deadline': true,
  'Flagged Source': true,
  'Flagged Source Number': true,
  'Historic Claim Frequency': true,
  'Historic Claim Number': true,
  'Historic Rejected Number': true,
  'Historic Rejected %': true,
  'Below Maximum': true,
  'No Source': true,
  'No Source Number': true,
  'Plaintiff Names': true,
  'Outside Jurisdiction': true,
  'Over Household Limit': true,
  'Over Household Limit %': true,
  'Programmatic Duplicate': true,
  'Maximum Number of Units': true,
  "Duplicate Claim": true,
  "Random Handle": true,
  "IP Physical Mismatch": true,
  "Phone Address Mismatch": true,
  "Invalid Products": true,
  "Invalid Serial No": true,
  "No Documentation": true,
};

const nameToKeyMap = {
  'Avg. Claims Above Household Limit': 'HouseLimit',
  'Above Household Limit Number': 'HouseLimit',
  'IP Match': 'Hist',
  'Case Number Plaintiff Names': 'MultiName',
  'Over Household Limit Address': 'HouseLimit',
  'Ober Household Limit IP': 'HouseLimit',
  'Case Over Household Limit': 'HouseLimit',
  'Case Over Household Limit %': 'HouseLimit',
  'Over Maximum Number': 'MaxUnits',
  'Over Maximum %': 'MaxUnits',
  'Close to Deadline': 'Deadline',
  'Flagged Source': 'ClaimSource',
  'Flagged Source Number': 'ClaimSource',
  'Historic Claim Frequency': 'Hist',
  'Historic Claim Number': 'Hist',
  'Historic Rejected Number': 'Hist',
  'Historic Rejected %': 'Hist',
  'Below Maximum': 'BelowMax',
  'No Source': 'ClaimSource',
  'No Source Number': 'ClaimSource',
  'Plaintiff Names': 'MultiName',
  'Outside Jurisdiction': 'Jurisdiction',
  'Over Household Limit': 'HouseLimit',
  'Over Household Limit %': 'HouseLimit',
  'Programmatic Duplicate': 'ProgDup',
  'Maximum Number of Units': 'MaxUnits',
  "Duplicate Claim": "DuplicateClaim",
  "Random Handle": "RandomHandle",
  "IP Physical Mismatch": "IPPhysicalMismatch",
  "Phone Address Mismatch": "PhoneAddressMismatch",
  "Invalid Products": "InvalidProducts",
  "Invalid Serial No": "InvalidSerialNo",
  "No Documentation": "NoDocumentation",
};

export const getActiveRejectionCodes = (rejectionCodeMap) =>
  Object.keys(rejectionCodeMap)
    .filter((key) => rejectionCodeMap[key])
    .map((key) => {
      return nameToKeyMap[key];
    });

export const checkboxes = {
  approved: false,
  rejected: false,
};

export const emptyForm = {
  name: '',
  reportName: '',
  type: 'summary',
  determination: checkboxes,
  rejectionCodes: rejectionCodesDeselected,
  scoreRange: {
    min: '',
    max: '',
  },
  eligibleUnits: {
    min: '',
    max: '',
  },
  timeFrame: {
    start: null,
    deadline: null,
  },
  payout: {
    min: {
      value: '',
      raw: '',
    },
    max: {
      value: '',
      raw: '',
    },
  },
};

export function mapFormToExportRequest(form, caseID) {
  return {
    rejectionCodes: getActiveRejectionCodes(form.rejectionCodes).filter(
      (code) => code !== null
    ),
    payout: {
      min: Number(form.payout.min.raw),
      max: Number(form.payout.max.raw),
    },
    timeFrame: {
      start: form.timeFrame.start,
      deadline: form.timeFrame.deadline,
    },
    eligibleUnits: {
      min: Number(form.eligibleUnits.min),
      max: Number(form.eligibleUnits.max),
    },
    scoreRange: {
      min: Number(form.scoreRange.min),
      max: Number(form.scoreRange.max),
    },
    determination: form.determination,
    type: form.type,
    caseID,
    reportName: form.name,
  };
}

export const formatClaimantsRequestUnits = (form) => {
  return {
    ...form,
    payout: {
      ...form.payout,
      min: Math.floor(form.payout.min * 100),
      max: Math.floor(form.payout.max * 100),
    },
    timeFrame: {
      start: form.timeFrame.start
        ? format(parseISO(form.timeFrame.start), 'MM/dd/yyyy')
        : null,
      deadline: form.timeFrame.deadline
        ? format(parseISO(form.timeFrame.deadline), 'MM/dd/yyyy')
        : null,
    },
    scoreRange: {
      ...form.scoreRange,
      min: Math.floor(form.scoreRange.min || 0),
      max: Math.floor(form.scoreRange.max || 0),
    },
    eligibleUnits: {
      ...form.eligibleUnits,
      min: Math.floor(form.eligibleUnits.min || 0),
      max: Math.floor(form.eligibleUnits.max || 0),
    },
  };
};

export function downloadItem(data, fileExtension) {
  const url = window.URL.createObjectURL(new Blob([data]));
  var element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute(
    'download',
    `${new Date().toLocaleDateString('en-US')}.${fileExtension}`
  );
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function handleCurrencyChange(event, type, values, setValues) {
  const value = event.target.value;

  const cursorPosition = event.target.selectionStart;
  const input = document.getElementById(`payout.${type}`);

  const valueWithoutDollarSign = value.replace('$', '');
  const [intPart, decimalPart] = valueWithoutDollarSign.split('.');

  const isThereAPoint = valueWithoutDollarSign.indexOf('.') !== -1;

  const intPartWithoutCommas = (intPart || '').replaceAll(',', '');
  const decimalPartWithoutCommas = (decimalPart || '').replaceAll(',', '');

  const intPartWithCommas = intPartWithoutCommas.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
  const decimalPartFormatted =
    decimalPartWithoutCommas.length > 0
      ? `.${decimalPartWithoutCommas}`
      : `${isThereAPoint ? '.' : ''}`;

  const shouldHasADollarSign =
    (intPartWithCommas && intPartWithCommas.length > 0) ||
    (decimalPartFormatted && decimalPartFormatted.length > 0);

  const formattedValue = `${shouldHasADollarSign ? '$' : ''
    }${intPartWithCommas}${decimalPartFormatted}`;
  const rawValue = `${intPartWithoutCommas}${decimalPartFormatted}`;

  if (
    (isNaN(Number(intPartWithoutCommas)) &&
      (!decimalPart || isNaN(Number(decimalPartWithoutCommas)))) ||
    (decimalPart && decimalPart.length > 2)
  )
    return { ...values };

  let moveCursor = 0;

  setValues((_prevState) => {
    const oldValue = _prevState.payout[type].value;
    if (!oldValue || oldValue === '$' || value.length === 1) {
      moveCursor = 1;
    } else {
      const numberCommasNewValue = intPartWithCommas.split(',').length - 1;
      const numberCommasOldValue = oldValue.split(',').length - 1;

      const moreCommasThanBefore = numberCommasOldValue < numberCommasNewValue;
      const lessCommasThanBefore = numberCommasOldValue > numberCommasNewValue;

      const newCommaPosition = intPartWithCommas.indexOf(',');
      const oldCommaPosition = oldValue.indexOf(',');

      const moveToRight = cursorPosition > newCommaPosition;
      const moveToLeft = cursorPosition > oldCommaPosition;

      if (moreCommasThanBefore && moveToRight) {
        moveCursor = 1;
      }
      if (lessCommasThanBefore && moveToLeft) {
        moveCursor = -1;
      }
    }

    return {
      ...values,
      payout: {
        ...values.payout,
        [type]: {
          value: formattedValue,
          raw: rawValue,
        },
      },
    };
  });

  window.requestAnimationFrame(() => {
    input.setSelectionRange(
      cursorPosition + moveCursor,
      cursorPosition + moveCursor
    );
  });
}

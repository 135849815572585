import React, {
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
  KeyboardEvent,
} from 'react';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Collapse,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { signOut } from '../../gcp/auth';
import { Badge } from '../badge';
import { PATHS } from '../../utils/routes/paths';

const StyledArrow = styled(KeyboardArrowDownIcon)<{ open: boolean }>`
  transform: ${(props) => (!props.open ? 'rotate(0deg)' : 'rotate(180deg);')};
`;

const InnerMenuItem = styled(MenuItem)`
  background-color: ${(props) => props.theme.palette.background.default};
  padding: 16px 36px;
`;

interface SuperAdminMenuProps {
  accountsInbox: number;
  casesInbox: number;
}

export function SuperAdminMenu({
  accountsInbox = 0,
  casesInbox = 0,
}: SuperAdminMenuProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const isSM = useMediaQuery((theme: any) => theme.breakpoints.down('sm'), {
    defaultMatches: true,
    noSsr: false,
  });

  return (
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          borderColor: '#D1D5DB',
          borderWidth: '1px',
          borderStyle: 'solid',
          marginLeft: isSM ? '10px' : '0px',
        }}
      >
        <StyledArrow open={open} sx={{ color: 'text.primary' }} />
        <Badge count={accountsInbox + casesInbox} leftMargin="5px" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ width: 400 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem>Account</MenuItem>
                  <MenuItem onClick={handleExpandClick}>
                    <ListItemIcon>
                      <Badge
                        count={accountsInbox + casesInbox}
                        rightMargin="10px"
                      />
                    </ListItemIcon>
                    <ListItemText>Super Admin Dashboard</ListItemText>
                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                      <StyledArrow open={expanded} />
                    </Typography>
                  </MenuItem>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <MenuList>
                      <Link
                        to={PATHS.superAdminAccounts}
                        style={{ textDecoration: 'none' }}
                      >
                        <InnerMenuItem sx={{ color: 'text.primary' }}>
                          <ListItemText
                            sx={{ maxWidth: isSM ? '90%' : '100%' }}
                          >
                            Accounts
                          </ListItemText>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'text.primary',
                              width: '12px',
                              textAlign: 'center',
                            }}
                          >
                            {accountsInbox}
                          </Typography>
                        </InnerMenuItem>
                      </Link>
                      <Link
                        to={PATHS.superAdminCases}
                        style={{ textDecoration: 'none' }}
                      >
                        <InnerMenuItem sx={{ color: 'text.primary' }}>
                          <ListItemText
                            sx={{ maxWidth: isSM ? '90%' : '100%' }}
                          >
                            Cases
                          </ListItemText>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'text.primary',
                              width: '12px',
                              textAlign: 'center',
                            }}
                          >
                            {casesInbox}
                          </Typography>
                        </InnerMenuItem>
                      </Link>
                    </MenuList>
                  </Collapse>
                  <MenuItem onClick={signOut}>
                    <ListItemText>Sign Out</ListItemText>
                    <Typography variant="body2" color="error">
                      <LogoutIcon />
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

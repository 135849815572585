import { Grid } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';
import ProofOfPurchaseClaims from '../../components/proof-of-purchase/proof-of-purchase-details';

function ProofOfPurchaseDetailsPage() {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Grid item lg={8} md={6} xs={12}>
          <ProofOfPurchaseClaims />
        </Grid>
      </Container>
    </Box>
  );
}

export default ProofOfPurchaseDetailsPage;

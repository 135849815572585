import Axios from 'axios';
import { PaginatedResult } from './types';
import { authenticateInterceptor } from './interceptors';
import { Ruling } from '../components/proof-of-purchase/pop.types';
import toQueryParams from '../utils/to-query-params';
import { UserInfo } from '../views/sign-up/types';

const usersService = Axios.create({
  baseURL: `${process.env.REACT_APP_USERS_SERVICE_URL}`,
});

export const getUsersList = (
  pageNumber: number,
  pageSize: number,
  search?: string,
  determination?: Ruling,
  status?: string
) =>
  usersService.get<PaginatedResult<UserInfo>>(
    `/users?${toQueryParams({
      pageNumber,
      pageSize,
      search,
      determination,
      status,
    })}`
  );

export const createUser = (userInfo: UserInfo) =>
  usersService.post(`/users/`, userInfo);

export const getUserInfo = () => usersService.get(`/user`);

export const updateUserEmailVerifiedStatus = (
  emailVerified: boolean,
  userId: string
) => usersService.put(`/user/${userId}/email-verified-sql`, { emailVerified });

export const sendPasswordResetEmail = (email: string) =>
  usersService.post('/users/send-reset-password-email', { email });

export const sendEmailVerification = (userId: string) =>
  usersService.post(`/users/${userId}/send-email-verification`);

export const updateUserStatus = (
  userId: string,
  status: Ruling,
  previousStatus: Ruling
) => usersService.put(`/users/${userId}/status`, { status, previousStatus });

export const getUserCaseRoutes = (userId: string, caseId: string) =>
  usersService.get(`/users/${userId}/routes/${caseId}`);

// INTERCEPTORS
usersService.interceptors.request.use(authenticateInterceptor);

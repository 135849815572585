import { Ruling } from './pop.types';

export const mockedRows = [
  {
    id: '00cd7166-6554-40dc-aed3-1',
    images: [
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/cute-cat-photos-1593441022.jpg?crop=0.670xw:1.00xh;0.167xw,0&resize=640:*',
      'https://t4.ftcdn.net/jpg/02/66/72/41/360_F_266724172_Iy8gdKgMa7XmrhYYxLCxyhx6J7070Pr8.jpg',
    ],
    name: 'Max Test',
    purchaseDate: '12/12/2022',
    quantity: 1,
    payout: 900000,
    score: 500,
    qty: 1,
    ruling: Ruling.Pending,
    rejectionCodes: ['HANDSOME'],

    reason: {
      duplicate: false,
      manipulated: false,
      mismatch: false,
      illegible: false,
    },
  },
  {
    id: '00cd7166-6554-40dc-aed3-2',
    images: [
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/cute-cat-photos-1593441022.jpg?crop=0.670xw:1.00xh;0.167xw,0&resize=640:*',
      'https://t4.ftcdn.net/jpg/02/66/72/41/360_F_266724172_Iy8gdKgMa7XmrhYYxLCxyhx6J7070Pr8.jpg',
    ],
    name: 'Max Test',
    purchaseDate: '12/12/2022',
    quantity: 1,
    payout: 900000,
    score: 500,
    qty: 1,
    ruling: Ruling.Approved,
    rejectionCodes: ['HANDSOME'],
    reason: {
      duplicate: false,
      manipulated: false,
      mismatch: false,
      illegible: false,
    },
  },
  {
    id: '00cd7166-6554-40dc-aed3-3',
    images: [
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/cute-cat-photos-1593441022.jpg?crop=0.670xw:1.00xh;0.167xw,0&resize=640:*',
      'https://t4.ftcdn.net/jpg/02/66/72/41/360_F_266724172_Iy8gdKgMa7XmrhYYxLCxyhx6J7070Pr8.jpg',
    ],
    name: 'Max Test',
    purchaseDate: '12/12/2022',
    quantity: 1,
    payout: 900000,
    score: 500,
    qty: 1,
    ruling: Ruling.Rejected,
    rejectionCodes: ['HANDSOME'],
    reason: {
      duplicate: false,
      manipulated: false,
      mismatch: false,
      illegible: false,
    },
  },
];

import create from 'zustand';
import { immer } from 'zustand/middleware/immer';

// Definitions
const createSelectedCaseSlice = (set) => ({
  selectedCase: {},
  setSelectedCase: (value) =>
    set((state) => {
      state.selectedCase = value;
    }),
});

const createSelectedCaseAggregatedDataSlice = (set) => ({
  selectedCaseAggregatedData: {},
  setSelectedCaseAggregatedData: (value) =>
    set((state) => {
      state.selectedCaseAggregatedData = value;
    }),
});

const createSelectedCaseTableRowsSlice = (set) => ({
  selectedCaseTableRows: [],
  setSelectedCaseTableRows: (value) =>
    set((state) => {
      state.selectedCaseTableRows = value;
    }),
});

const createSelectedCaseTotalTableEntriesCountSlice = (set) => ({
  selectedCaseTotalTableEntriesCount: 0,
  setSelectedCaseTotalTableEntriesCounts: (value) =>
    set((state) => {
      state.selectedCaseTotalTableEntriesCount = value;
    }),
});

const createPaginationSlice = (set) => ({
  pageSize: 10,
  pageNumber: 0,
  setPageNumber: (value) =>
    set((state) => {
      state.pageNumber = value;
    }),
  setPageSize: (value) =>
    set((state) => {
      state.pageSize = value;
    }),
});

const createCurrentUserSlice = (set) => ({
  currentUser: {},
  setCurrentUser: (value) =>
    set((state) => {
      state.currentUser = value;
    }),
});

const createFileUploadSlice = (set) => ({
  fileUploadProgress: 0,
  setFileUploadProgress: (value) =>
    set((state) => {
      state.fileUploadProgress = value;
    }),
});

const createLoadingSlice = (set) => ({
  showLoadingOverlay: false,
  loadingMessage: '',
  setLoadingOverlay: (isLoading, message = '') =>
    set((state) => {
      state.showLoadingOverlay = isLoading;
      state.loadingMessage = message;
    }),
});

const createReportFilters = (set) => ({
  reportFilters: {
    caseID: '',
    determination: {
      approved: false,
      rejected: false,
    },
    eligibleUnits: {
      min: 0,
      max: 0,
    },
    payout: {
      min: 0,
      max: 0,
    },
    rejectionCodes: [],
    scoreRange: {
      min: 0,
      max: 0,
    },
    timeFrame: {
      start: null,
      deadline: null,
    },
    type: 'summary',
  },
  setReportFilters: (_reportFilters) =>
    set((state) => {
      state.reportFilters = _reportFilters;
    }),
});

const createErrorSlice = (set) => ({
  showErrorOverlay: false,
  errorMessage: '',
  setErrorOverlay: (isShown, message = '') =>
    set((state) => {
      state.showErrorOverlay = isShown;
      state.errorMessage = message;
    }),
});

const createCaseListSlice = (set) => ({
  totalCases: 0,
  caseList: [],
  setTotalCases: (totalCases) =>
    set((state) => {
      state.totalCases = totalCases;
    }),
  setCaseList: (caseList) =>
    set((state) => {
      state.caseList = caseList;
    }),
});

const createSnackbar = (set) => ({
  showSnackbar: false,
  message: '',
  severity: 'info',
  closeSnackbar: () =>
    set((state) => {
      state.showSnackbar = false;
    }),
  openSnackbar: (message, severity) =>
    set((state) => {
      state.showSnackbar = true;
      state.message = message ?? state.message;
      state.severity = severity ?? state.severity;
    }),
});

const createPlaintiffIDSearch = (set) => ({
  plaintiffIDToSearch: undefined,
  setPlaintiffIDToSearch: (value) =>
    set((state) => {
      state.plaintiffIDToSearch = value;
    }),
});

const root = (set, get) => ({
  ...createCaseListSlice(set, get),
  ...createSelectedCaseSlice(set, get),
  ...createSelectedCaseAggregatedDataSlice(set, get),
  ...createSelectedCaseTableRowsSlice(set, get),
  ...createCurrentUserSlice(set, get),
  ...createFileUploadSlice(set, get),
  ...createLoadingSlice(set, get),
  ...createErrorSlice(set, get),
  ...createPaginationSlice(set, get),
  ...createSnackbar(set, get),
  ...createSelectedCaseTotalTableEntriesCountSlice(set, get),
  ...createReportFilters(set, get),
  ...createPlaintiffIDSearch(set, get),
});

export default create(immer(root));

import { Alert, Snackbar } from '@mui/material';
import styled from '@emotion/styled';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { Theme } from '@emotion/react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from '../../store';

const getColorbySeverity = (severity: string | undefined, theme: Theme) => {
  switch (severity) {
    case 'error':
      return theme.palette.warning.dark;
    case 'success':
      return theme.palette.secondary.main;
    case 'info':
      return theme.palette.info.main;
  }
};

const getIcon = (severity: string | undefined) => {
  switch (severity) {
    case 'error':
      return <ErrorIcon />;
    case 'success':
      return <CheckCircleIcon />;
    case 'info':
      return <InfoIcon />;
  }
};

const StyledAlert = styled(Alert)(({ severity, theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  border: 'none',
  '& .MuiAlert-icon': {
    color: getColorbySeverity(severity, theme),
    borderLeft: `3px solid ${getColorbySeverity(severity, theme)}`,
    paddingLeft: '8px',
  },
}));

export const SnackBar = ({ children }) => {
  const { showSnackbar, closeSnackbar, severity, message } = useSnackbar();

  const handleClose = () => closeSnackbar();

  return (
    <>
      <Snackbar
        open={showSnackbar}
        onClose={handleClose}
        message={message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <StyledAlert
          onClose={handleClose}
          severity={severity}
          variant="outlined"
          icon={getIcon(severity)}
        >
          {message}
        </StyledAlert>
      </Snackbar>
      {children}
    </>
  );
};

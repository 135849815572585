import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';

const StyledTitle = styled(Typography)`
  font-weight: 700;
  font-size: 32px;
`;

const ContentBox = styled(Box)`
  p {
    margin-bottom: 24px;
  }
  li {
    margin-bottom: 3px;
  }
  ul {
    margin-bottom: 24px;
  }
  li {
    margin-left: 64px;
  }
`;

const TermsOfUse = () => {
  return (
    <Box sx={{ p: 10 }}>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
          ClaimScore Terms of Use
        </Typography>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="overline"
          sx={{
            lineHeight: '250%',
            fontWeight: 500,
            fontSize: '12px',
            color: '#3E8682',
          }}
        >
          Last Modified: March 31, 2023
        </Typography>
      </Box>
      <ContentBox>
        <StyledTitle>Acceptance of the Terms of Use</StyledTitle>
        <p>
          These terms of use are entered into by and between You and ClaimScore
          LLC (“<strong>Company</strong>,” “<strong>we</strong>,” or “
          <strong>us</strong>”). The following terms and conditions (“
          <strong>Terms of Use</strong>”) govern your access to and use of
          www.claimscore.ai, including any content, functionality, and services
          offered on or through www.claimscore.ai (the “<strong>Website</strong>
          ”), whether as a guest or a registered user/customer.
        </p>
        <p>
          Please read the Terms of Use carefully before you use the Website.{' '}
          <strong>
            By using the Website, you accept and agree to be bound and abide by
            these Terms of Use and our Privacy Policy, found at PRIVACY POLICY
            URL, incorporated herein by reference.
          </strong>{' '}
          If you do not want to agree to these Terms of Use or the Privacy
          Policy, you must not access or use the Website.{' '}
        </p>
        <p>
          This Website is offered and available to users who are 18 years of age
          or older, and reside in the United States or any of its territories or
          possessions. By using this Website, you represent and warrant that you
          are of legal age to form a binding contract with the Company and meet
          all of the foregoing eligibility requirements. If you do not meet all
          of these requirements, you must not access or use the Website.
        </p>
        <StyledTitle>Changes to the Terms of Use</StyledTitle>
        <p>
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them, and apply to all access to and use of the Website thereafter.
          However, any changes to the dispute resolution provisions set out in
          Governing Law and Jurisdiction will not apply to any disputes for
          which the parties have actual notice on or before the date the change
          is posted on the Website.
        </p>
        <p>
          Your continued use of the Website following the posting of revised
          Terms of Use means that you accept and agree to the changes. You are
          expected to check this page from time to time so you are aware of any
          changes, as they are binding on you.
        </p>
        <StyledTitle>Accessing the Website and Account Security</StyledTitle>
        <p>
          We reserve the right to withdraw or amend this Website, and any
          service or material we provide on the Website, in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Website is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Website, or
          the entire Website, to users, including registered users.
        </p>
        <p>You are responsible for both:</p>
        <ul>
          <li>
            Making all arrangements necessary for you to have access to the
            Website.
          </li>
          <li>
            Ensuring that all persons who access the Website through your
            internet connection are aware of these Terms of Use and comply with
            them
          </li>
        </ul>
        <p>
          To access the Website or some of the resources it offers, you may be
          asked to provide certain registration details or other information. It
          is a condition of your use of the Website that all the information you
          provide on the Website is correct, current, and complete. You agree
          that all information you provide to register with this Website or
          otherwise, including, but not limited to, 2 through the use of any
          interactive features on the Website, is governed by our Privacy
          Policy, and you consent to all actions we take with respect to your
          information consistent with our Privacy Policy.
        </p>
        <p>
          If you choose, or are provided with, a user name, password, or any
          other piece of information as part of our security procedures, you
          must treat such information as confidential, and you must not disclose
          it to any other person or entity. You also acknowledge that your
          account is personal to you and agree not to provide any other person
          with access to this Website or portions of it using your user name,
          password, or other security information. You agree to notify us
          immediately of any unauthorized access to or use of your user name or
          password or any other breach of security. You also agree to ensure
          that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or
          shared computer so that others are not able to view or record your
          password or other personal information
        </p>
        <p>
          We have the right to disable any user name, password, or other
          identifier, whether chosen by you or provided by us, at any time in
          our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these Terms of Use.
        </p>
        <StyledTitle>
          Terms Applicable to Specific Areas or Functions of the Website
        </StyledTitle>
        <p>
          Some areas of the Website may have additional terms and conditions
          that apply to your access or use of that area of the Website
          (including terms and conditions applicable to a corporation or other
          organization and its users). Your use of any software made available
          on or accessible through the Website is subject to all agreements,
          such as a license agreement or user agreement, that accompanies or is
          included with the software, ordering documents, exhibits, and other
          terms and conditions that apply (“License Agreement”). In the event
          that software is provided on or through the Website and is not
          licensed for your use through License Agreement, you may use the
          software subject to the following: (a) the software may be used solely
          for your personal, informational, noncommercial purposes; (b) the
          software may not be modified or altered in any way; and (c) the
          software may not be redistributed.
        </p>
        <StyledTitle>Intellectual Property Rights</StyledTitle>
        <p>
          The Website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof) are owned by the Company, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </p>
        <p>
          These Terms of Use permit you to use the Website for your personal,
          non-commercial use only. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store, “scrape,” mirror or transmit any of the
          material on our Website, except as follows:
        </p>
        <ul>
          <li>
            Your computer may temporarily store copies of such materials in RAM
            incidental to your accessing and viewing those materials.
          </li>
          <li>
            You may store files that are automatically cached by your Web
            browser for display enhancement purposes.
          </li>
          <li>
            You may print or download one copy of a reasonable number of pages
            of the Website for your own personal, non-commercial use and not for
            further reproduction, publication, or distribution.
          </li>
          <li>
            If we provide desktop, mobile, or other applications for download,
            you may download a single copy to your computer or mobile device
            solely for your own personal, non-commercial use, provided you agree
            to be bound by our end user license agreement for such applications.
          </li>
        </ul>
        <p>You must not:</p>
        <ul>
          <li> Modify copies of any materials from this site.</li>
          <li>
            Use any illustrations, photographs, video or audio sequences, or any
            graphics separately from the accompanying text.
          </li>
          <li>
            Delete or alter any copyright, trademark, or other proprietary
            rights notices from copies of materials from this site.
          </li>
        </ul>
        <p>
          You must not access or use for any commercial purposes any part of the
          Website or any services or materials available through the Website.
        </p>
        <p>
          If you wish to make any use of material on the Website other than that
          set out in this section, please address your request to:
          termsofuse@claimscore.ai.
        </p>
        <p>
          If you print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of the Website in breach of the
          Terms of Use, your right to use the Website will stop immediately and
          you must, at our option, return or destroy any copies of the materials
          you have made. No right, title, or interest in or to the Website or
          any content on the Website is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Website
          not expressly permitted by these Terms of Use is a breach of these
          Terms of Use and may violate copyright, trademark, and other laws.
        </p>
        <StyledTitle>Trademarks</StyledTitle>
        <p>
          The Company name, the Company logo, and all related names, logos,
          product and service names, designs, and slogans are trademarks of the
          Company or its affiliates or licensors. You must not use such marks
          without the prior written permission of the Company. All other names,
          logos, product and service names, designs, and slogans on this Website
          are the trademarks of their respective owners.
        </p>
        <StyledTitle>Prohibited Uses</StyledTitle>
        <p>
          You may use the Website only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the Website:
        </p>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the US or
            other countries).
          </li>
          <li>
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information, or otherwise.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail,” “chain letter,”
            “spam,” or any other similar solicitation.
          </li>
          <li>
            To impersonate or attempt to impersonate the Company, a Company
            employee, another user, or any other person or entity (including,
            without limitation, by using email addresses associated with any of
            the foregoing).
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the Website, or which, as determined by us, may
            harm the Company or users of the Website, or expose them to
            liability.
          </li>
        </ul>
        <p>Additionally, you agree not to:</p>
        <ul>
          <li>
            Use the Website in any manner that could disable, overburden,
            damage, or impair the site or interfere with any other party's use
            of the Website, including their ability to engage in real time
            activities through the Website.
          </li>
          <li>
            Use any robot, spider, or other automatic device, process, or means
            to access the Website for any purpose, including monitoring or
            copying any of the material on the Website.
          </li>
          <li>
            Use any manual process to monitor or copy any of the material on the
            Website, or for any other purpose not expressly authorized in these
            Terms of Use, without our prior written consent.
          </li>
          <li>
            {' '}
            Use any device, software, or routine that interferes with the proper
            working of the Website.
          </li>
          <li>
            Introduce any viruses, Trojan horses, worms, logic bombs, or other
            material that is malicious or technologically harmful.
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer, or database connected to the
            Website.{' '}
          </li>
          <li>
            Attack the Website via a denial-of-service attack or a distributed
            denial-of-service attack.
          </li>
          <li>
            Otherwise attempt to interfere with the proper working of the
            Website.
          </li>
        </ul>
        <StyledTitle>Changes to the Website</StyledTitle>
        <p>
          We may update the content on this Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </p>
        <StyledTitle>
          Information About You and Your Visits to the Website
        </StyledTitle>
        <p>
          All information we collect on this Website is subject to our Privacy
          Policy. By using the Website, you consent to all actions taken by us
          with respect to your information in compliance with the Privacy
          Policy.
        </p>
        <StyledTitle>Links from the Website</StyledTitle>
        <p>
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites 5 or resources, and accept no responsibility
          for them or for any loss or damage that may arise from your use of
          them. If you decide to access any of the third-party websites linked
          to this Website, you do so entirely at your own risk and subject to
          the terms and conditions of use for such websites.
        </p>
        <StyledTitle>Geographic Restrictions</StyledTitle>
        <p>
          The owner of the Website is based in the United States. We provide
          this Website for use only by persons located in the United States. We
          make no claims that the Website or any of its content is accessible or
          appropriate outside of the United States. Access to the Website may
          not be legal by certain persons or in certain countries. If you access
          the Website from outside the United States, you do so on your own
          initiative and are responsible for compliance with local laws.
        </p>
        <StyledTitle>Disclaimer of Warranties</StyledTitle>
        <p>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
          LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
          TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
          ANY WEBSITE LINKED TO IT.
        </p>
        <p>
          YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE.
          WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
          ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE,
          ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
          WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
          WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
          ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE
          OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE
          MEET YOUR NEEDS OR EXPECTATIONS.
        </p>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY 6 WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <StyledTitle>Indemnification</StyledTitle>
        <p>
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys' fees) arising out
          of or relating to your violation of these Terms of Use or your use of
          the Website, including, but not limited to, your User Contributions,
          any use of the Website's content, services, and products other than as
          expressly authorized in these Terms of Use, or your use of any
          information obtained from the Website
        </p>
        <StyledTitle>Governing Law and Jurisdiction</StyledTitle>
        <p>
          All matters relating to the Website and these Terms of Use, and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the State of New
          Jersey without giving effect to any choice or conflict of law
          provision or rule (whether of the State of New Jersey or any other
          jurisdiction).
        </p>
        <p>
          Any legal suit, action, or proceeding arising out of, or related to,
          these Terms of Use or the Website shall be instituted exclusively in
          the federal courts of the United States or the courts of the State of
          New Jersey, in each case located in the City of Pompton Plains and
          County of Morris, although we retain the right to bring any suit,
          action, or proceeding against you for breach of these Terms of Use in
          your country of residence or any other relevant country. You waive any
          and all objections to the exercise of jurisdiction over you by such
          courts and to venue in such courts
        </p>
        <StyledTitle>Arbitration</StyledTitle>
        <p>
          At Company's sole discretion, it may require You to submit any
          disputes arising from these Terms of Use or use of the Website,
          including disputes arising from or concerning their interpretation,
          violation, invalidity, non-performance, or termination, to final and
          binding arbitration under the Rules of Arbitration of the American
          Arbitration Association applying New Jersey law.
        </p>
        <StyledTitle>Limitation on Time to File Claims</StyledTitle>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <StyledTitle>Waiver and Severability</StyledTitle>
        <p>
          No waiver by the Company of any term or condition set out in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </p>
        <p>
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>
        <StyledTitle>Entire Agreement</StyledTitle>
        <p>
          The Terms of Use and our Privacy Policy constitute the sole and entire
          agreement between you and ClaimScore LLC regarding the Website and
          supersede all prior and contemporaneous understandings, agreements,
          representations, and warranties, both written and oral, regarding the
          Website.
        </p>
        <StyledTitle>Your Comments and Concerns</StyledTitle>
        <p>This website is operated by:</p>

        <p>ClaimScore LLC</p>
        <p>108 Wanaque Avenue</p>
        <p>Pompton Lakes, NJ 07442</p>
        <p>
          All other feedback, comments, requests for technical support, and
          other communications relating to the Website should be directed to
          termsofuse@claimscore.ai.
        </p>
      </ContentBox>
    </Box>
  );
};

export default TermsOfUse;

import Axios from 'axios';
import { PaginatedResult } from './types';
import { authenticateInterceptor } from './interceptors';
import toQueryParams from '../utils/to-query-params';
import { UserRole, Role } from '../views/case-permissions/types';
import { BillingCase } from '../views/super-admin-cases/types';

const casesClient = Axios.create({
  baseURL: `${process.env.REACT_APP_CASES_SERVICE_URL}`,
});

// TODO: add proper typing to current implicit any

type InviteUserRequestBody = {
  caseName: string;
  caseId: string;
  role: Role;
  senderName: string;
  guestEmail: string;
};

// CASES
export const createNewCase = (requestBody) =>
  casesClient.post('/cases', requestBody);
export const updateCase = (requestBody) =>
  casesClient.put(`/cases/${requestBody.caseID}`, requestBody);

export const getCasePage = (pageNumber: number, filter: string) =>
  casesClient.get(
    `/cases?${toQueryParams({ pageNumber, pageSize: 6, search: filter })}`
  );

export const getCase = (caseID: string) => casesClient.get(`/cases/${caseID}`);

export const getUserCasePermission = (caseID: string, userID: string) =>
  casesClient.get(`/cases/${caseID}/permissions/${userID}`);

export const getCaseUserPermissions = (
  caseID: string,
  cursor: string,
  pageSize: number
) =>
  casesClient.get<PaginatedResult<UserRole>>(
    `/cases/${caseID}/permissions?${toQueryParams({ cursor, pageSize })}`
  );

export const updateUserRole = (
  caseID: string,
  userEmail: string,
  role: string
) => casesClient.put(`/cases/${caseID}/permissions/${userEmail}`, { role });

export const deleteUserFromCase = (caseID: string, userEmail: string) =>
  casesClient.delete(`/cases/${caseID}/permissions/${userEmail}`);

export const inviteNewUser = (inviteUserBody: InviteUserRequestBody) =>
  casesClient.post(
    `/cases/${inviteUserBody.caseId}/permissions/send-invitation-email`,
    inviteUserBody
  );

export const getCaseAggregatedDetails = (caseID: string, body) =>
  casesClient.post(`/cases/${caseID}/aggregates`, body);

export const getAllPlaintiffs = (
  caseID: string,
  pageNumber: number,
  pageSize: number,
  field,
  sort,
  body,
  search?: string,
) =>
  casesClient.post(
    `/cases/${caseID}/results?${toQueryParams({
      pageNumber,
      pageSize,
      field,
      sort,
      search,
    })}`,
    body
  );

export const getAllBillingCases = (
  pageNumber: number,
  pageSize: number,
  search?: string,
  caseType?: string,
  paymentStatus?: string
) =>
  casesClient.get<PaginatedResult<BillingCase>>(
    `/cases/billing?${toQueryParams({
      pageNumber,
      pageSize,
      search,
      caseType,
      paymentStatus,
    })}`
  );

export const updateCaseStatus = (caseId: string, status: string) =>
  casesClient.put(`/cases/${caseId}/status`, { status });

// INTERCEPTORS
casesClient.interceptors.request.use(authenticateInterceptor);

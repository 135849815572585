import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseISO, add } from 'date-fns';
import { useMediaQuery } from '@mui/material';
import {
  checkboxes,
  emptyForm,
  mapCaseDetailsToForm,
  mapFormToCaseDetails,
  unrestrictedJurisdiction,
  caseTypeValues,
} from './utils';
import { currencyFormatter } from '../../utils/msw/number-formatter';
import { useLoadingOverlay, useSelectedCase, useSnackbar } from '../../store';
import { createNewCase, updateCase } from '../../services/cases.service';
import { LoadingOverlay } from '../loading-overlay/loading-overlay';
import { PATHS } from '../../utils/routes/paths';

const CaseDetailsForm = ({ newCase }) => {
  const [selectedCase, setSelectedCase] = useSelectedCase();
  const [loadingOverlay, setLoading] = useLoadingOverlay();
  const { openSnackbar } = useSnackbar();
  const [values, setValues] = useState(
    newCase ? emptyForm : mapCaseDetailsToForm(selectedCase)
  );
  const [claimTypes, setClaimTypes] = useState(values.claimTypes);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!newCase) setValues(mapCaseDetailsToForm(selectedCase));
  }, [selectedCase]);

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleCurrencyChange = (event, claim) => {
    const key = event.key;
    const claimCopy = { ...claim };
    let raw = claimCopy.payout.raw || '';
    if (key === 'Backspace') {
      raw = raw.substring(0, raw.length - 1);
      if (raw[raw.length - 1] === '.') {
        raw = raw.substring(0, raw.length - 1);
      }
    } else {
      if (!raw.split('.')[1] || raw.split('.')[1].length < 2) raw += key;
    }
    if (isNaN(Number(raw))) return { ...claimCopy };
    return {
      ...claimCopy,
      payout: { value: currencyFormatter.format(raw), raw },
    };
  };

  const handleClaimTypeChange = (event, index) => {
    let claim = { ...claimTypes[index] };
    if (event.target.name === 'payout') {
      claim = handleCurrencyChange(event, claim);
    } else {
      claim[event.target.name] = event.target.value;
    }
    setClaimTypes((prev) => {
      const newClaims = [...prev];
      newClaims[index] = claim;
      return newClaims;
    });
  };

  const handleDeadlineChange = (time) => {
    setValues({
      ...values,
      deadline: time ? time.toISOString() : null,
    });
  };

  const handleCheckBoxChange = (event) => {
    const defaults = { ...checkboxes };
    const unrestrictedDefaults = { ...unrestrictedJurisdiction };
    if (event.target.name === 'unrestricted') {
      setValues({
        ...values,
        jurisdiction: values.jurisdiction.unrestricted
          ? defaults
          : unrestrictedDefaults,
      });
    } else {
      values.jurisdiction[event.target.name] =
        !values.jurisdiction[event.target.name];
      const { unrestricted, usa, ust, canada } = values.jurisdiction;
      if (!unrestricted && usa && ust && canada) {
        setValues({
          ...values,
          jurisdiction: unrestrictedDefaults,
        });
      } else {
        values.jurisdiction.unrestricted = false;
        setValues({
          ...values,
        });
      }
    }
  };

  const validateClaimTypes = () => {
    return claimTypes
      .filter((_, i) => selectedRows.includes(i))
      .every((entry) =>
        Object.keys(entry).every((key) => {
          if (key === 'payout') {
            return entry.payout.raw > 0;
          }
          return entry[key];
        })
      );
  };

  const submitNewCase = async () => {
    setLoading(true);
    if (Object.values(values).every((value) => value) && validateClaimTypes()) {
      const requestBody = mapFormToCaseDetails(values, claimTypes);
      try {
        const { data } = await createNewCase(requestBody);
        setSelectedCase(data);
        navigate(PATHS.caseDashboard.replace(':caseId', data.caseID));
        openSnackbar('Yay! Case created successfully', 'success');
      } catch (error) {
        openSnackbar('Failed to create new Case', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const shouldBeDisabled = () => {
    if (newCase)
      return (
        !Object.keys(values).every((key) => values[key]) ||
        !validateClaimTypes()
      );
    return !values.caseName || !values.caseNumber || !values.stateId;
  };

  const submitUpdateCase = async () => {
    setLoading(true);
    if (values.caseName && values.caseNumber && values.stateId) {
      const requestBody = {
        caseID: location.pathname.split('/')[2],
        caseName: values.caseName,
        caseNumber: values.caseNumber,
        stateID: values.stateId,
      };
      try {
        const { data } = await updateCase(requestBody);
        if (data) {
          setSelectedCase({ ...data, aggregates: selectedCase.aggregates });
          openSnackbar('Yay! Case updated successfully', 'success');
        }
      } catch (error) {
        openSnackbar('Failed to update Case', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const getTomorrowDate = () => add(new Date(), { days: 1 });

  return (
    <form autoComplete="off" noValidate>
      <LoadingOverlay open={loadingOverlay.showLoadingOverlay} />
      <Card>
        <Typography sx={{ m: 3 }} variant="h4">
          {newCase && 'New '}Case Details
        </Typography>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                label="Case Name"
                name="caseName"
                onChange={handleChange}
                value={values.caseName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth disabled={!newCase}>
                <InputLabel id="case-type">Case Type</InputLabel>
                <Select
                  labelId="case-type"
                  variant="outlined"
                  name="caseType"
                  value={values.caseType || ''}
                  label="Case Type"
                  onChange={handleChange}
                >
                  {caseTypeValues.map((caseType) => (
                    <MenuItem value={caseType} key={caseType}>
                      {caseType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Case Number"
                name="caseNumber"
                onChange={handleChange}
                value={values.caseNumber}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="State Id"
                name="stateId"
                onChange={handleChange}
                value={values.stateId}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 4 }} />
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={{ m: 3, fontWeight: 600 }} variant="span">
                Claim Details
              </Typography>
            </Grid>
          </Grid>
          {claimTypes.map((claim, index) => {
            return (
              <Grid
                container
                spacing={3}
                key={claim.type + index}
                sx={{ px: 3, mb: 3 }}
                alignItems="center"
                flexWrap={'wrap'}
              >
                <Grid item>
                  <FormControlLabel
                    label={isMdUp ? '' : `Enable ${claimTypes[index].type}`}
                    control={
                      <Checkbox
                        disabled={!newCase}
                        checked={selectedRows.includes(index)}
                        onChange={() => {
                          setSelectedRows((prev) => {
                            const newSelectedRows = [...prev];
                            if (newSelectedRows.includes(index)) {
                              newSelectedRows.splice(
                                newSelectedRows?.indexOf(index),
                                1
                              );
                            } else {
                              newSelectedRows.push(index);
                            }
                            return newSelectedRows;
                          });
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <FormControl fullWidth disabled={!newCase}>
                    <InputLabel id="claim-type">Claim Type</InputLabel>
                    <Select
                      disabled={!selectedRows.includes(index)}
                      labelId="claim-type"
                      variant="outlined"
                      name="type"
                      value={claimTypes[index].type}
                      label="Claim Type"
                      onChange={(event) => {
                        handleClaimTypeChange(event, index);
                      }}
                    >
                      <MenuItem value="Non-PoP">Non-PoP</MenuItem>
                      <MenuItem value="PoP">Pop</MenuItem>
                      <MenuItem value="Direct Notice">Direct Notice</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    disabled={!newCase || !selectedRows.includes(index)}
                    label="Max Units"
                    name="maxUnits"
                    onChange={(event) => {
                      handleClaimTypeChange(event, index);
                    }}
                    value={claimTypes[index].maxUnits}
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    disabled={!newCase || !selectedRows.includes(index)}
                    label="Household Limit"
                    name="householdLimit"
                    onChange={(event) => {
                      handleClaimTypeChange(event, index);
                    }}
                    value={claimTypes[index].householdLimit}
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    disabled={!newCase || !selectedRows.includes(index)}
                    label="Payout"
                    name="payout"
                    value={claimTypes[index].payout.value}
                    onKeyDown={(event) => {
                      handleClaimTypeChange(event, index);
                    }}
                    type="text"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            );
          })}

          <Divider sx={{ mt: 3, mb: 2 }} />
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <MobileDatePicker
                label="Deadline"
                disabled={!newCase}
                name="deadline"
                inputFormat="MM/dd/yyyy"
                minDate={getTomorrowDate()}
                value={values.deadline ? parseISO(values.deadline) : null}
                onChange={handleDeadlineChange}
                renderInput={(params) => (
                  <TextField {...params} sx={{ mt: 1 }} fullWidth />
                )}
                variant="outlined"
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <FormControl
                disabled={!newCase}
                component="fieldset"
                sx={{ width: '100%' }}
                variant="standard"
              >
                <FormLabel component="legend">Jurisdiction</FormLabel>
                <FormGroup
                  sx={{
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <FormControlLabel
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    control={
                      <Checkbox
                        checked={values.jurisdiction.unrestricted}
                        onChange={handleCheckBoxChange}
                        name="unrestricted"
                      />
                    }
                    label="Unrestricted"
                  />
                  <Divider
                    sx={{
                      display: { xs: 'inline-block', md: 'none' },
                      width: '100%',
                      height: '2px',
                    }}
                  />
                  <FormControlLabel
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    control={
                      <Checkbox
                        checked={values.jurisdiction.usa}
                        onChange={handleCheckBoxChange}
                        name="usa"
                      />
                    }
                    label="United States"
                  />
                  <FormControlLabel
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    control={
                      <Checkbox
                        checked={values.jurisdiction.ust}
                        onChange={handleCheckBoxChange}
                        name="ust"
                      />
                    }
                    label="US Territories"
                  />
                  <FormControlLabel
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    control={
                      <Checkbox
                        checked={values.jurisdiction.canada}
                        onChange={handleCheckBoxChange}
                        name="canada"
                      />
                    }
                    label="Canada"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            disabled={shouldBeDisabled()}
            color="primary"
            variant="contained"
            onClick={newCase ? submitNewCase : submitUpdateCase}
          >
            {newCase ? 'Create New Case' : 'Save details'}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default CaseDetailsForm;

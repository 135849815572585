/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  ButtonWrapper,
  DotsContainer,
  FullScreenImage,
  FullScreenModal,
  MainImage,
  NavigationArrow,
  NavigationDots,
  RelativeContainer,
} from './carousel.styles';
interface Props {
  images: string[];
}

const ImageCarousel: React.FC<Props> = ({ images = [] }) => {
  const [index, setIndex] = useState(0);
  const [isFullScreenOpen, setFullScreenOpen] = useState(false);

  return (
    <>
      <RelativeContainer>
        <NavigationArrow
          className="left"
          onClick={() => setIndex(index === 0 ? images.length - 1 : index - 1)}
        >
          <KeyboardArrowLeftIcon />
        </NavigationArrow>
        <ButtonWrapper onClick={() => setFullScreenOpen(true)}>
          <MainImage src={images[index]} />
        </ButtonWrapper>
        <NavigationArrow
          className="right"
          onClick={() => setIndex(index === images.length - 1 ? 0 : index + 1)}
        >
          <KeyboardArrowRightIcon />
        </NavigationArrow>
      </RelativeContainer>
      <DotsContainer>
        {images.map((_, i) => (
          <NavigationDots
            key={i}
            onClick={() => setIndex(i)}
            active={i === index}
          />
        ))}
      </DotsContainer>
      <FullScreenModal
        open={isFullScreenOpen}
        onClose={() => setFullScreenOpen(false)}
      >
        <>
          <NavigationArrow
            className="left"
            onClick={() =>
              setIndex(index === 0 ? images.length - 1 : index - 1)
            }
          >
            <KeyboardArrowLeftIcon />
          </NavigationArrow>
          <FullScreenImage src={images[index]} />
          <NavigationArrow
            className="right"
            onClick={() =>
              setIndex(index === images.length - 1 ? 0 : index + 1)
            }
          >
            <KeyboardArrowRightIcon />
          </NavigationArrow>
        </>
      </FullScreenModal>
    </>
  );
};

export default ImageCarousel;

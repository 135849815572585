import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import styled from '@emotion/styled';
import { TableContentTypes } from '../../../views/privacy-policy/types';
import Markdown from '../../markdown';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#252422',
    color: '#FFFFFF',
    fontSize: '24px',
    fontWeight: 500,
    fontFamily: 'DM Sans',
    textTransform: 'none',
    padding: '16px',
    gap: '10px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000',
  },
}));

const StyledTableCellSubtitle = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#252422',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'DM Sans',
    textTransform: 'none',
    lineHeight: '157%',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#221F20',
    textTransform: 'none',
    fontFamily: 'DM Sans',
    lineHeight: '150%',
  },
}));

const TableContent = (props: TableContentTypes) => {
  const {
    title,
    content,
    paragraph,
    subtitle,
    items,
    body,
    secondBody,
    additionalPurposes,
    left,
    markdown,
  } = props;

  return (
    <TableContainer sx={{ border: '1px solid #D1D5DB' }}>
      <Table>
        <TableHead>
          {title && (
            <StyledTableCell align={left ? 'left' : 'center'}>
              {title}
            </StyledTableCell>
          )}
          {subtitle && (
            <StyledTableCellSubtitle align={left ? 'left' : 'center'}>
              {subtitle}
            </StyledTableCellSubtitle>
          )}
        </TableHead>
        <TableBody>
          <TableRow>
            {items && (
              <TableCell>
                {items.map((item, index: number) => (
                  <Box sx={{ mb: 3, pl: 4 }} key={index}>
                    <ul>
                      <li>
                        <strong>{item.boldTitle}</strong>
                        {item.paragraph}
                      </li>
                    </ul>
                  </Box>
                ))}
              </TableCell>
            )}
            {body && (
              <TableCell>
                {body.map((item, index: number) => (
                  <Box sx={{ mb: 3, pl: 4 }} key={index}>
                    <ul>
                      <li>{item}</li>
                    </ul>
                  </Box>
                ))}
                {additionalPurposes && (
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        lineHeight: '175%',
                        fontWeight: 500,
                      }}
                    >
                      {additionalPurposes}
                    </Typography>
                  </Box>
                )}
                {secondBody &&
                  secondBody.map((item, index: number) => (
                    <Box sx={{ mb: 3, pl: 4 }} key={index}>
                      <ul>
                        <li>{item}</li>
                      </ul>
                    </Box>
                  ))}
              </TableCell>
            )}
            {paragraph && <StyledTableCell>{paragraph}</StyledTableCell>}
            {markdown && (
              <StyledTableCell>
                <Markdown source={markdown} />
              </StyledTableCell>
            )}
            {content && (
              <StyledTableCellSubtitle>{content}</StyledTableCellSubtitle>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableContent;

import { Box, Container, Grid, Pagination } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { LoadingOverlay } from '../../components/loading-overlay/loading-overlay';
import { CaseCard } from '../../components/my-cases/case-card';
import CaseListToolbar from '../../components/my-cases/case-list-toolbar';
import { getCasePage } from '../../services/cases.service';
import {
  useCaseList,
  useLoadingOverlay,
  useSelectedCase,
  useTotalCases,
} from '../../store';

const CASES_PER_PAGE = 6;

function MyCases() {
  const [caseList, setCaseList] = useCaseList();
  const [totalCases, setTotalCases] = useTotalCases();
  const [loadingOverlay, setLoadingOverlay] = useLoadingOverlay();
  const [currentPage, setCurrentPage] = useState(0);
  const [, setSelectedCase] = useSelectedCase();
  const [filter, setFilter] = useState('');

  const getPageHandler = useCallback(
    debounce(async (currentPage, filter) => {
      setLoadingOverlay(true);
      const { data } = await getCasePage(currentPage, filter);
      setTotalCases(data.total);
      setCaseList(data.results);
      setLoadingOverlay(false);
    }, 500),
    []
  );

  useEffect(() => {
    getPageHandler(currentPage, filter);
  }, [currentPage, filter, getPageHandler]);

  useEffect(() => {
    setSelectedCase({});
  }, []);

  const handleSearchBox = (event) => {
    setFilter(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth={false}>
        <LoadingOverlay open={loadingOverlay.showLoadingOverlay} />
        <CaseListToolbar onSearch={handleSearchBox} />
        <Box sx={{ pt: 3 }}>
          <Grid container spacing={3}>
            {caseList.map((caseDetail) => (
              <Grid item key={caseDetail.caseID} lg={4} md={6} xs={12}>
                <CaseCard caseDetails={caseDetail} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 3,
          }}
        >
          {!filter && (
            <Pagination
              onChange={handlePageChange}
              color="primary"
              count={Math.ceil(totalCases / CASES_PER_PAGE)}
              size="small"
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default MyCases;

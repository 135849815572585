import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Stack } from '@mui/system';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { getDownloadURL } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import {
  defaultForm,
  getNewDate,
  getNewDocument,
  getNewFAQ,
  getNewLegalAction,
} from './utils';
import privacyPolicy from './privacyPolicy.json';
import { useLoadingOverlay, useSelectedCase, useSnackbar } from '../../store';
import { LoadingOverlay } from '../loading-overlay/loading-overlay';
import TextEditor from '../text-editor';
import SelectDragList from '../select-drag-list';
import { db } from '../../gcp/config';

import { uploadFileToStorage } from '../../gcp/storage';
import { initializeTemplateDeployment } from '../../services/pipeline.service';
import { useCaseId } from '../../hooks/useCaseId';

const getStoragePath = (caseID, docId) => `documents/${caseID}/${docId}.pdf`;

const CaseDetailsForm = () => {
  useCaseId();
  const { caseId } = useParams();
  const [selectedCase] = useSelectedCase();
  const [loadingOverlay, setLoading] = useLoadingOverlay();
  const [values, setValues] = useState(defaultForm);
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    const getCaseNoticeValues = async () => {
      setLoading(true);

      const docRef = doc(db, 'Templates', selectedCase.caseID || caseId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setValues({ ...data.home, faqs: data.faqs });
      }
      setLoading(false);
    };
    getCaseNoticeValues();
  }, [selectedCase.caseID, setLoading, caseId]);

  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const handleContactInfo = ({ target }) => {
    const newValues = { ...values };
    const newContactInfo = { ...values.contactInformation };
    newContactInfo[target.name] = target.value;
    newValues.contactInformation = newContactInfo;
    setValues(newValues);
  };

  const saveForm = async () => {
    setLoading(true);
    const promises = values.documents.map(async ({ file, id, url }) => {
      if (url) {
        return url;
      }
      const snapshot = await uploadFileToStorage(
        file,
        getStoragePath(selectedCase.caseID, id)
      );
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    });
    const urls = await Promise.all(promises);
    const updatedDocumentList = values.documents.map((d, i) => {
      const fileName = d.file.name;
      const documentCopy = { ...d };
      delete documentCopy.file;
      return { ...documentCopy, file: { name: fileName }, url: urls[i] };
    });

    const homeValues = { ...values, documents: updatedDocumentList };
    delete homeValues.faqs;

    const docId = selectedCase.caseID;
    const docData = {
      caseID: docId,
      caseName: selectedCase.caseName,
      caseNumber: selectedCase.caseNumber,
      stateId: selectedCase.stateID,
      home: homeValues,
      faqs: values.faqs,
      privacyPolicy,
    };

    setLoading(true);
    try {
      const docRef = doc(db, 'Templates', docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(doc(db, 'Templates', docId), docData);
      } else {
        await setDoc(doc(db, 'Templates', docId), docData);
      }
      openSnackbar('Yay! Notice template saved successfully', 'success');
    } catch (error) {
      openSnackbar('Failed to upload Notice Page Data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const saveAndPublish = async () => {
    await saveForm();
    await initializeTemplateDeployment({ caseId: selectedCase.caseID });
    // TODO: handle loading the deployment
    setLoading(false);
  };

  return (
    <form autoComplete="off" noValidate>
      <LoadingOverlay open={loadingOverlay.showLoadingOverlay} />
      <Card>
        <Typography sx={{ m: 3 }} variant="h4">
          Notice Website
        </Typography>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ minHeight: '200px' }}>
              <TextEditor
                label="Update"
                value={values.updateNotice}
                setValue={(value) => {
                  handleChange({
                    target: {
                      name: 'updateNotice',
                      value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Headline"
                name="headline"
                onChange={handleChange}
                value={values.headline}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sx={{ minHeight: '200px' }}>
              <TextEditor
                label="Description"
                value={values.description}
                setValue={(value) => {
                  handleChange({
                    target: {
                      name: 'description',
                      value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <SelectDragList
                title="Legal Rights & Options"
                list={values.legalActions}
                onChange={(newList) => {
                  handleChange({
                    target: {
                      name: 'legalActions',
                      value: newList,
                    },
                  });
                }}
                onAdd={() => {
                  const newValue = getNewLegalAction();
                  const newList = [...values.legalActions, newValue];
                  handleChange({
                    target: {
                      name: 'legalActions',
                      value: newList,
                    },
                  });
                }}
              >
                {(item, index, list) => (
                  <>
                    <TextField
                      type="text"
                      label={`Legal Option ${index + 1}`}
                      fullWidth
                      sx={{ mt: 1 }}
                      value={item.title}
                      onChange={({ target }) => {
                        const result = [...list];
                        result[index].title = target.value;
                        handleChange({
                          target: {
                            name: 'legalActions',
                            value: result,
                          },
                        });
                      }}
                    />
                    <TextField
                      type="text"
                      label={`Description ${index + 1}`}
                      fullWidth
                      multiline
                      minRows={4}
                      sx={{ mt: 3 }}
                      value={item.description}
                      onChange={({ target }) => {
                        const result = [...list];
                        result[index].description = target.value;
                        handleChange({
                          target: {
                            name: 'legalActions',
                            value: result,
                          },
                        });
                      }}
                    />
                  </>
                )}
              </SelectDragList>
            </Grid>
            <Grid item xs={12} sx={{ minHeight: '200px' }}>
              <TextEditor
                label="Rights & Options Disclaimer"
                value={values.disclaimer}
                setValue={(value) => {
                  handleChange({
                    target: {
                      name: 'disclaimer',
                      value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <SelectDragList
                title="Important Documents"
                list={values.documents}
                onChange={(newList) => {
                  handleChange({
                    target: {
                      name: 'documents',
                      value: newList,
                    },
                  });
                }}
                components={{
                  Button: () => (
                    <Button startIcon={<FileUploadIcon />} component="label">
                      Upload Document
                      <input
                        type="file"
                        accept=".pdf"
                        hidden
                        onChange={({ target }) => {
                          const newValue = {
                            ...getNewDocument(),
                            file: target.files[0],
                          };
                          const newList = [...values.documents, newValue];
                          handleChange({
                            target: {
                              name: 'documents',
                              value: newList,
                            },
                          });
                        }}
                      />
                    </Button>
                  ),
                }}
              >
                {(item, index, list) => (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Document Name"
                          value={item.name}
                          onChange={({ target }) => {
                            const result = [...list];
                            result[index].name = target.value;
                            handleChange({
                              target: {
                                name: 'documents',
                                value: result,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          readOnly
                          value={item.file.name}
                          label="File"
                          InputProps={{
                            sx: {
                              fontSize: '14px',
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </SelectDragList>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <SelectDragList
                title="Important Dates"
                list={values.importantDates}
                onChange={(newList) => {
                  handleChange({
                    target: {
                      name: 'importantDates',
                      value: newList,
                    },
                  });
                }}
                buttonLabel="Add Another Date"
                onAdd={() => {
                  const newValue = getNewDate();
                  const newList = [...values.importantDates, newValue];
                  handleChange({
                    target: {
                      name: 'importantDates',
                      value: newList,
                    },
                  });
                }}
              >
                {(item, index, list) => (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <MobileDatePicker
                          label="Date"
                          inputFormat="MM/dd/yyyy"
                          value={item.deadline ? parseISO(item.deadline) : null}
                          onChange={(value) => {
                            const result = [...list];
                            result[index].deadline = value
                              ? value.toISOString()
                              : '';
                            handleChange({
                              target: {
                                name: 'importantDates',
                                value: result,
                              },
                            });
                          }}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ mt: 1 }} fullWidth />
                          )}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Event"
                          value={item.detail}
                          onChange={({ target }) => {
                            const result = [...list];
                            result[index].detail = target.value;
                            handleChange({
                              target: {
                                name: 'importantDates',
                                value: result,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </SelectDragList>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ mb: 2, fontWeight: 500, fontSize: '18px' }}>
                Contact Information (Questions)
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={values.contactInformation.email}
                    name="email"
                    onChange={handleContactInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Phone"
                    value={values.contactInformation.phone}
                    name="phone"
                    onChange={handleContactInfo}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Claim Administrator"
                    value={values.contactInformation.claimAdmin}
                    name="claimAdmin"
                    onChange={handleContactInfo}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Street"
                    value={values.contactInformation.street}
                    name="street"
                    onChange={handleContactInfo}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="City"
                    value={values.contactInformation.city}
                    name="city"
                    onChange={handleContactInfo}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <TextField
                    fullWidth
                    label="City"
                    value={values.contactInformation.state}
                    name="state"
                    onChange={handleContactInfo}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    label="Zip Code"
                    value={values.contactInformation.zipCode}
                    name="zipCode"
                    onChange={handleContactInfo}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sx={{ minHeight: '200px' }}>
              <TextEditor
                label="Footer"
                value={values.footerDisclaimer}
                setValue={(value) => {
                  handleChange({
                    target: {
                      name: 'footerDisclaimer',
                      value,
                    },
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <SelectDragList
              title="Frequently Asked Questions"
              list={values.faqs}
              onChange={(newList) => {
                handleChange({
                  target: {
                    name: 'faqs',
                    value: newList,
                  },
                });
              }}
              buttonLabel="Add Question"
              onAdd={() => {
                const newValue = getNewFAQ();
                const newList = [...values.faqs, newValue];
                handleChange({
                  target: {
                    name: 'faqs',
                    value: newList,
                  },
                });
              }}
            >
              {(item, index, list) => (
                <>
                  <TextField
                    type="text"
                    label={`Question ${index + 1}`}
                    fullWidth
                    sx={{ mt: 1 }}
                    value={item.question}
                    onChange={({ target }) => {
                      const result = [...list];
                      result[index].question = target.value;
                      handleChange({
                        target: {
                          name: 'faqs',
                          value: result,
                        },
                      });
                    }}
                  />
                  <TextField
                    type="text"
                    label={`Answer ${index + 1}`}
                    fullWidth
                    multiline
                    minRows={4}
                    sx={{ mt: 3 }}
                    value={item.response}
                    onChange={({ target }) => {
                      const result = [...list];
                      result[index].response = target.value;
                      handleChange({
                        target: {
                          name: 'faqs',
                          value: result,
                        },
                      });
                    }}
                  />
                </>
              )}
            </SelectDragList>
          </Grid>
        </CardContent>
        <Divider />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{
            p: 2,
          }}
        >
          <Button color="primary" variant="outlined" onClick={saveForm}>
            Save w/o Publishing
          </Button>
          <Button color="primary" variant="contained" onClick={saveAndPublish}>
            Save & Publish
          </Button>
        </Stack>
      </Card>
    </form>
  );
};

export default CaseDetailsForm;

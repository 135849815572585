import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Typography,
  Stack,
  // InputAdornment,
  // InputLabel,
  // MenuItem,
  // Select,
  // SelectChangeEvent,
  // TextField,
  // FormControl,
} from '@mui/material';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { GridColumns } from '@mui/x-data-grid';
// import DeleteIcon from '@mui/icons-material/Delete';
// import SearchIcon from '@mui/icons-material/Search';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { doc, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { DeleteModal } from './delete-modal';
import { Report, ReportInProgress, ReportJobStatus } from './types';
import { LoadingOverlay } from '../../components/loading-overlay/loading-overlay';
import { StyledDataGrid } from '../../components/proof-of-purchase/pop.styles';
import { PaginatedResult } from '../../services/types';
import { getCaseReportsHistory } from '../../services/reports.service';
import { useSnackbar, useLoadingOverlay } from '../../store';
import { Csv as PictureAsCsvIcon } from '../../icons/csv.js';
import { ProgressBanner } from '../../components/progress-banner';
import { ReportFilter } from '../../components/case-dashboard/report-filter';
import { db } from '../../gcp/config';
import formatNameToShow from '../../utils/formatNameToShow';
import usePrevious from '../../hooks/usePrevious';

const DATE_FORMAT = 'MM/dd/yyyy';
const getRowDateRange = ({ row }) => {
  const dateStartString = row.dateStart
    ? format(new Date(row.dateStart._seconds * 1000), DATE_FORMAT)
    : 'N/A';
  const dateEndString = row.dateEnd
    ? format(new Date(row.dateEnd._seconds * 1000), DATE_FORMAT)
    : 'N/A';

  return dateStartString === 'N/A' && dateEndString === 'N/A'
    ? 'N/A'
    : `${dateStartString}-${dateEndString}`;
};
const columns: GridColumns = [
  {
    field: 'createdAt',
    flex: 1,
    minWidth: 130,
    filterable: false,
    renderCell: ({ value }) => (
      <Typography sx={{ textTransform: 'capitalize' }}>
        {format(value._seconds * 1000, DATE_FORMAT)}
      </Typography>
    ),
    headerName: 'CREATED ON',
  },
  {
    field: 'name',
    flex: 1,
    minWidth: 300,
    filterable: false,
    renderCell: ({ value }) => (
      <Typography>{formatNameToShow(value, 30)}</Typography>
    ),
    headerName: 'NAME',
  },
  {
    field: 'type',
    flex: 1,
    minWidth: 150,
    filterable: false,
    headerName: 'TYPE',
    renderCell: ({ value }) => (
      <Typography sx={{ textTransform: 'capitalize' }}>
        {value === 'summary' ? 'Insight Report' : 'Raw CSV Data'}
      </Typography>
    ),
  },
  {
    field: 'dateStart',
    flex: 1,
    minWidth: 250,
    filterable: false,
    headerName: 'DATE RANGE',
    renderCell: ({ value }) => (
      <Typography sx={{ textTransform: 'capitalize' }}>{value}</Typography>
    ),
    valueGetter: getRowDateRange,
  },
  {
    field: 'download',
    sortable: false,
    flex: 1,
    minWidth: 120,
    filterable: false,
    headerName: '',
    renderCell: ({ row }) => {
      const isPDF = row.name.includes('.pdf');
      return (
      <Stack flexDirection="row" justifyContent="flex-end" width="100%">
        <a href={row.csv} target="_blank" rel="noreferrer">
          <IconButton color={isPDF ? "error" : "primary"}>
            {isPDF ? <PictureAsPdfIcon /> : <PictureAsCsvIcon />}
          </IconButton>
        </a>
      </Stack>
    )},
  },
];

const CaseReports = () => {
  const { caseId } = useParams();
  const { openSnackbar } = useSnackbar();

  const [loadingOverlay, setLoadingOverlay] = useLoadingOverlay();

  const [rows, setRows] = useState<PaginatedResult<Report>>();
  const [selectedRows, setSelectedRows] = useState<Report[]>([]);
  const [reportInProgress, setReportInProgress] = useState<ReportInProgress>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  // const [search, setSearch] = useState('');
  // const [reportTypeFilter, setReportTypeFilter] = useState('all');
  // const [dateFilters, setDateFilters] = useState({ rangeStart: null, rangeEnd: null });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const handleLimitChange = (size: number) => {
    setPageSize(size);
  };

  const handlePageChange = (number: number) => {
    setPage(number);
  };

  // const handleDelete = () => {
  //   setShowDeleteModal(true);
  // };

  // const handleFilterChange = (name: string) => (value: Date | null) => {
  //   setDateFilters({
  //     ...dateFilters,
  //     [name]: value ? value?.toISOString() : null,
  //   });
  // };

  const getReportsHistory = useCallback(async () => {
    if (!caseId) return;

    try {
      const lastReportId =
        page === 0 ? '' : rows?.results[rows?.results.length - 1].id;
      const response = await getCaseReportsHistory(
        caseId,
        pageSize,
        lastReportId
      );

      if (response.data && Array.isArray(response.data.results)) {
        const storage = getStorage();
        const rows = await Promise.all(
          response.data.results.map(async (row) => {
            const { csv } = row;
            const reportRef = ref(
              storage,
              `gs://${process.env.REACT_APP_GCP_STORAGE_BUCKET}/${csv}`
            );

            const url = await getDownloadURL(reportRef);

            return {
              ...row,
              csv: url,
            };
          })
        );

        setRows({ results: rows, total: response.data.total });
      }
    } catch {
      openSnackbar('Unable to retrieve Reports History', 'error');
    }
  }, [caseId, page, pageSize]);

  const fetchData = async () => {
    setLoadingOverlay(true);
    try {
      await getReportsHistory();
    } finally {
      setLoadingOverlay(false);
    }
  };

  useEffect(() => {
    function claimReviewStatusSuscription() {
      const docRef = doc(db, `ReportJobs/${caseId}`);

      return onSnapshot(docRef, (doc) => {
        if (doc.data()) {
          setReportInProgress({
            fileName: doc.data()?.fileName,
            status: doc.data()?.status,
          });
        }
      });
    }
    const unsubscribe = claimReviewStatusSuscription();

    fetchData();
    return unsubscribe;
  }, [getReportsHistory, page, pageSize]);
  const previousReportInProgress = usePrevious(reportInProgress);

  useEffect(() => {
    if (
      previousReportInProgress?.status === ReportJobStatus.Processing &&
      reportInProgress?.status === ReportJobStatus.Success
    ) {
      getReportsHistory();
    }
  }, [reportInProgress]);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <LoadingOverlay open={loadingOverlay.showLoadingOverlay} />
      <DeleteModal
        open={showDeleteModal}
        reports={selectedRows}
        handleClose={() => {
          setShowDeleteModal(false);
        }}
        onFinish={getReportsHistory}
      />
      <ReportFilter
        isOpen={showCreateModal}
        handleClose={() => {
          setShowCreateModal(false);
        }}
        caseID={caseId}
      />
      <Container maxWidth="lg">
        <Grid
          container
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1,
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography sx={{ mx: 1, mb: { xs: 3, md: 0 } }} variant="h4">
              Reports
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              sx={{
                background: 'primary.light',
                width: { md: '170px' },
                float: { md: 'right' },
              }}
              variant="outlined"
              component="label"
              fullWidth
              onClick={() => {
                setShowCreateModal(true);
              }}
            >
              Create New Report
            </Button>
          </Grid>
        </Grid>
        {reportInProgress?.status === ReportJobStatus.Processing ? (
          <ProgressBanner
            title="Generating Report"
            fileName={reportInProgress.fileName}
            description="Your report request is being processed."
          />
        ) : null}
        <Card sx={{ mt: 3 }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ px: 3, py: 2 }} variant="h5">
              Report History
            </Typography>
            {/* <IconButton sx={{ mx: 3 }} aria-label="delete" disabled={selectedRows.length === 0} onClick={handleDelete}>
              <DeleteIcon />
            </IconButton> */}
          </Box>
          {/* <Grid container alignItems="center" sx={{ p: 3, pt: 1 }} spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                label="Search in Reports"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="report-type-select-label">Report Type</InputLabel>
                <Select
                  value={reportTypeFilter}
                  labelId="report-type-select-label"
                  id="report-type-select"
                  label="Report Type"
                  name="report-type"
                  onChange={(event: SelectChangeEvent<string>) => {
                    setReportTypeFilter(event.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="summary">Summary</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <MobileDatePicker
                  componentsProps={{ actionBar: { actions: ['clear', 'cancel', 'accept'] } }}
                  label="Range Start"
                  inputFormat="MM/dd/yyyy"
                  value={dateFilters.rangeStart ? parseISO(dateFilters.rangeStart) : null}
                  onChange={handleFilterChange('rangeStart')}
                  renderInput={(params) => <TextField {...params} fullWidth variant="outlined" name="rangeStart" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <MobileDatePicker
                  componentsProps={{ actionBar: { actions: ['clear', 'cancel', 'accept'] } }}
                  label="Range End"
                  inputFormat="MM/dd/yyyy"
                  value={dateFilters.rangeEnd ? parseISO(dateFilters.rangeEnd) : null}
                  onChange={handleFilterChange('rangeEnd')}
                  renderInput={(params) => <TextField {...params} fullWidth name="rangeEnd" variant="outlined" />}
                />
              </FormControl>
            </Grid>
          </Grid> */}
          <StyledDataGrid
            loading={false}
            disableColumnMenu
            checkboxSelection
            onSelectionModelChange={(rowIds) => {
              const rowsFromIds = rows?.results.filter((report) =>
                rowIds.includes(report.id)
              );
              setSelectedRows(rowsFromIds ?? []);
            }}
            autoHeight
            sortingMode="server"
            paginationMode="server"
            rows={rows?.results || []}
            rowCount={rows?.total || 0}
            columns={columns}
            getRowId={({ id }) => id}
            page={page}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            onPageSizeChange={handleLimitChange}
            rowsPerPageOptions={[5, 10, 25]}
            components={{
              LoadingOverlay: () => null,
            }}
          />
        </Card>
      </Container>
    </Box>
  );
};

export default CaseReports;

import React, { useState } from 'react';
import { Card, Collapse, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ref, getBlob } from 'firebase/storage';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { PoPListTable } from './pop-list-table';
import {
  CollapsibleContainer,
  CollapsibleHeader,
  CollapsibleTitle,
  ExpandMore,
} from './pop.styles';
import { PoPRow } from './pop.types';
import { storage } from '../../gcp/config';
import { useLoadingOverlay, useSnackbar } from '../../store';
import { LoadingOverlay } from '../loading-overlay/loading-overlay';

function ProofOfPurchaseClaims() {
  const [isCompletedExpanded, setCompletedExpanded] = useState(false);
  const [selectedRows, setSelectedRows] = useState<PoPRow[]>([]);
  const [loadingOverlay, setLoadingOverlay] = useLoadingOverlay();
  const { openSnackbar } = useSnackbar();

  const onDownloadFiles = async () => {
    try {
      setLoadingOverlay(true);
      const blobPromises: Promise<Blob>[] = [];
      for (let row of selectedRows) {
        for (let image of row.images) {
          const fileReference = ref(storage, image);
          blobPromises.push(getBlob(fileReference));
        }
      }
      const blobs = await Promise.all(blobPromises);
      const zip = new JSZip();
      blobs.forEach((blob, i) => {
        zip.file(`file-${i}.jpg`, blob);
      });
      zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        const currentDate = new Date().getTime();
        const fileName = `combined-${currentDate}.zip`;
        return FileSaver.saveAs(zipFile, fileName);
      });
    } catch (error) {
      openSnackbar(
        'Something went wrong downloading images form PoP claims',
        'error'
      );
    } finally {
      setLoadingOverlay(false);
    }
  };

  return (
    <>
      <LoadingOverlay open={loadingOverlay.showLoadingOverlay} />
      <Box
        sx={{
          m: -1,
        }}
      >
        <Typography sx={{ mb: 6 }} variant="h4">
          Proof Of Purchase Claims
        </Typography>
        <Card sx={{ mb: 3 }}>
          <CollapsibleHeader>
            <CollapsibleTitle>Pending PoP Claims</CollapsibleTitle>
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={onDownloadFiles}
              disabled={selectedRows?.length === 0}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </CollapsibleHeader>
          <PoPListTable
            type="pending"
            selectableRow
            onSelectRow={(rows) => setSelectedRows(rows)}
          />
        </Card>

        <Card>
          <CollapsibleHeader>
            <CollapsibleTitle>Completed PoP Claims</CollapsibleTitle>
            <ExpandMore
              expand={isCompletedExpanded}
              onClick={() => {
                setCompletedExpanded(!isCompletedExpanded);
              }}
              aria-expanded={isCompletedExpanded}
              aria-label="show more"
            >
              <GridExpandMoreIcon />
            </ExpandMore>
          </CollapsibleHeader>
          <Collapse in={isCompletedExpanded} timeout="auto">
            <CollapsibleContainer>
              <PoPListTable type="completed" />
            </CollapsibleContainer>
          </Collapse>
        </Card>
      </Box>
    </>
  );
}

export default ProofOfPurchaseClaims;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { DeleteUsersModal } from './delete-modal';
import { RoleDropdown } from './role-dropdown';
import { Role, UserRole } from './types';
import InvitationModal from './invite-modal';
import { LoadingOverlay } from '../../components/loading-overlay/loading-overlay';
import { StyledDataGrid } from '../../components/proof-of-purchase/pop.styles';
import { PaginatedResult } from '../../services/types';
import { useSnackbar, useLoadingOverlay } from '../../store';
import { getCaseUserPermissions } from '../../services/cases.service';

const columns: GridColumns = [
  {
    field: 'userEmail',
    flex: 1,
    minWidth: 190,
    filterable: false,
    headerName: 'EMAIL',
  },
  {
    field: 'userStatus',
    flex: 1,
    filterable: false,
    headerName: 'STATUS',
    minWidth: 190,
    renderCell: ({ value }) => (
      <Typography
        sx={
          value === 'Approved'
            ? { color: 'primary.main' }
            : { color: 'error.light' }
        }
      >
        {value}
      </Typography>
    ),
  },
  {
    filterable: false,
    sortable: false,
    flex: 1,
    field: 'userRole',
    headerName: 'ROLE ',
    minWidth: 190,
    renderCell: (params: GridRenderCellParams) => <RoleDropdown {...params} />,
  },
];

export const CasePermissions = () => {
  const { caseId } = useParams();
  const { openSnackbar } = useSnackbar();
  const [loadingOverlay, setLoadingOverlay] = useLoadingOverlay();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const [rows, setRows] = useState<PaginatedResult<UserRole>>();

  const [selectedRows, setSelectedRows] = useState<UserRole[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const handleLimitChange = (size: number) => {
    setPageSize(size);
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleInvite = () => {
    setShowInviteModal(true);
  };

  const handlePageChange = (number: number) => {
    setPage(number);
  };

  const getUserPermissions = useCallback(async () => {
    if (!caseId) return;
    setLoadingOverlay(true);
    try {
      const cursor =
        page === 0 ? '' : rows?.results[rows?.results.length - 1].userEmail;
      const response = await getCaseUserPermissions(
        caseId,
        cursor as string,
        pageSize
      );
      setRows(response.data);
    } catch {
      openSnackbar('Unable to retrieve Accounts List', 'error');
    } finally {
      setLoadingOverlay(false);
    }
  }, [caseId, page, pageSize]);

  useEffect(() => {
    getUserPermissions();
  }, [getUserPermissions]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <LoadingOverlay open={loadingOverlay.showLoadingOverlay} />
          <Grid item lg={8} md={6} xs={12}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ mb: 6 }} variant="h4">
                Case Permissions
              </Typography>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleInvite}
              >
                Invite New User
              </Button>
            </Box>
            <Card sx={{ mb: 3 }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{ p: 3 }} variant="h5">
                  Case Permissions
                </Typography>
                <IconButton
                  sx={{ m: 3 }}
                  aria-label="delete"
                  disabled={selectedRows.length === 0}
                  onClick={handleDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <StyledDataGrid
                loading={false}
                disableColumnMenu
                checkboxSelection
                onSelectionModelChange={(rowIds) => {
                  const rowsFromIds = rows?.results.filter((user) =>
                    rowIds.includes(user.userEmail)
                  );
                  setSelectedRows(rowsFromIds ?? []);
                }}
                isRowSelectable={({ row }) => row.userRole !== Role.Owner}
                autoHeight
                sortingMode="server"
                paginationMode="server"
                rows={rows?.results || []}
                rowCount={rows?.total || 0}
                columns={columns}
                getRowId={({ userEmail }) => userEmail}
                rowsPerPageOptions={[5, 10, 25]}
                page={page}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onPageSizeChange={handleLimitChange}
                components={{
                  LoadingOverlay: () => null,
                }}
              />
            </Card>
          </Grid>
        </Container>
      </Box>
      <DeleteUsersModal
        open={showDeleteModal}
        users={selectedRows}
        handleClose={() => {
          setShowDeleteModal(false);
        }}
        onFinish={getUserPermissions}
      />
      <InvitationModal
        open={showInviteModal}
        handleClose={() => {
          setShowInviteModal(false);
        }}
        onFinish={getUserPermissions}
      />
    </>
  );
};

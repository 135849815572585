import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const Csv = createSvgIcon(
  <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 18.8999V4.8999H0V18.8999C0 19.9999 0.9 20.8999 2 20.8999H16V18.8999H2ZM18.61 0.899902H6.61C4.9 0.899902 4 1.7999 4 2.8999V14.8999C4 15.9999 4.9 16.8999 6 16.8999H18C19.1 16.8999 20 15.9999 20 14.8999V2.8999C20 1.7999 19.1 0.899902 18.61 0.899902ZM8.71 7.3999H7V10.3999H8.71V11.8999H7C6.17 11.8999 5.5 11.2299 5.5 10.3999V7.3999C5.5 6.5699 6.17 5.8999 7 5.8999H8.71V7.3999ZM13.82 10.3999C13.82 11.2299 13.15 11.8999 12.32 11.8999H9.85V10.5699H12.32V9.4699H11.37C10.54 9.4699 9.87 8.9799 9.87 8.1499V7.3999C9.87 6.5699 10.54 5.8999 11.37 5.8999H13.21V7.2699H11.37V8.2799H12.32C13.15 8.2799 13.82 8.8199 13.82 9.6499V10.3999ZM17.54 11.8999H15.14L14.39 5.8999H15.75L16.34 10.5199L16.92 5.8999H18.29L17.54 11.8999Z"
      fill="currentColor"
    />
  </svg>,
  'Csv'
);

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Chip,
} from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ReportFilter } from './report-filter';
import {
  emptyForm,
  mapFormToExportRequest,
  formatClaimantsRequestUnits,
} from './report-filter/utils';
import { mapExportValuesToFilterTags, getEmptyFormPieceByLabel } from './utils';
import { PATHS } from '../../utils/routes/paths';
import { createReport } from '../../services/reports.service';
import {
  useSelectedCase,
  useReportFilters,
  useSnackbar,
  useLoadingOverlay,
  usePlaintiffID,
} from '../../store';

type ExportValues = typeof emptyForm;

export function CaseListFilters() {
  const [selectedCase] = useSelectedCase();
  const [, setReportFilters] = useReportFilters();
  const [isModalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(emptyForm);
  const { openSnackbar } = useSnackbar();
  const [, setLoadingOverlay] = useLoadingOverlay();
  const [, setPlaintiffIDToSearch] = usePlaintiffID();
  const navigate = useNavigate();

  const validateBody = (body) => {
    let isBodyValid = true;
    let message = '';
    Object.entries(body).forEach((entry) => {
      const { min, max, start, deadline } = entry[1] as any;

      if (min > max && max !== 0) {
        isBodyValid = false;
        message = `${
          entry[0] === 'scoreRange' ? 'Score Range' : 'Eligible Units'
        }, the minimum can't be greater than the maximum.`;
      }
      if (min < 0 || max < 0) {
        isBodyValid = false;
        message = `${
          entry[0] === 'scoreRange' ? 'Score Range' : 'Eligible Units'
        } can't have negative values.`;
      }
      if (start > deadline) {
        isBodyValid = false;
        message = `The Time Frame can't start after the end date.`;
      }
    });

    return {
      isBodyValid,
      message,
    };
  };

  function handleFilterApply(values: ExportValues) {
    const filtersToApply = mapFormToExportRequest(
      values,
      selectedCase.caseID,
      'CSV'
    );

    const { isBodyValid, message } = validateBody(filtersToApply);

    if (isBodyValid) {
      setFilters(values);
      setReportFilters(filtersToApply);
    } else {
      openSnackbar(message, 'warning');
    }
  }

  function handleFilterDelete(label: string) {
    const emptyPiece = getEmptyFormPieceByLabel(filters, label);

    const filterRemoved = mapFormToExportRequest(
      {
        ...filters,
        ...emptyPiece,
      },
      selectedCase.caseID,
      'CSV'
    );

    setFilters({
      ...filters,
      ...emptyPiece,
    });
    setReportFilters(filterRemoved);
  }

  function handleSearchChange(event) {
    setSearch(event.target.value);
  }
  function handleSearchKeyPress(event) {
    if (event.key === 'Enter') {
      setPlaintiffIDToSearch(search);
    }
  }

  useEffect(() => {
    const emptyFilters = mapFormToExportRequest(
      emptyForm,
      selectedCase.caseID,
      ''
    );
    setReportFilters(emptyFilters);
  }, []);

  const requestReport = async () => {
    setLoadingOverlay(true);
    try {
      const filtersAsRequest = mapFormToExportRequest(
        filters,
        selectedCase.caseID
      );
      await createReport({
        ...formatClaimantsRequestUnits(filtersAsRequest),
        caseID: selectedCase.caseID,
      });
      navigate(PATHS.caseReports.replace(':caseId', selectedCase.caseID));
    } catch (error) {
      openSnackbar('Failed to request report', 'error');
    } finally {
      setLoadingOverlay(false);
    }
  };

  return (
    <>
      <Paper sx={{ py: 4, px: 3 }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '18px',
            mb: 2,
          }}
        >
          Search Plaintiffs Data
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={9.78}>
            <TextField
              fullWidth
              label="Plaintiff ID"
              margin="normal"
              name="search"
              inputProps={{ sx: { py: 1.2 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onBlur={handleSearchChange}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyPress}
              type="text"
              value={search}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <Button
              sx={{ mt: 1, mr: 1, background: 'primary.light' }}
              variant="contained"
              fullWidth
              startIcon={<Add />}
              component="label"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Add Filters
            </Button>
          </Grid>
        </Grid>
        <Box display="flex" flexWrap="wrap">
          {filters &&
            Object.entries(mapExportValuesToFilterTags(filters))
              .filter(([, value]) => value)
              .map(([label, value]) => {
                return (
                  <Chip
                    sx={{ mb: 1, mr: 1 }}
                    key={label}
                    label={`${label}: ${value}`}
                    onDelete={() => handleFilterDelete(label)}
                  />
                );
              })}
        </Box>
        <Button
          sx={{ mt: 1, mr: 1, background: 'primary.light' }}
          variant="contained"
          component="label"
          onClick={() => requestReport()}
        >
          Save Report
        </Button>
      </Paper>
      <ReportFilter
        caseID={selectedCase.caseID}
        isOpen={isModalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
        isFilterOnly
        onApply={handleFilterApply}
        controlledValues={filters}
      />
    </>
  );
}

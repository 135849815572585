import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Search as SearchIcon } from '../../../icons/search';
import { Add as AddIcon } from '../../../icons/add';
import { PATHS } from '../../../utils/routes/paths';

const CaseListToolbar = ({ onSearch }) => (
  <Box>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        m: -1,
      }}
    >
      <Typography sx={{ m: 1 }} variant="h4">
        My Cases
      </Typography>
      <Box sx={{ m: 1 }}>
        <Link to={PATHS.caseNew} style={{ textDecoration: 'none' }}>
          <Button color="primary" variant="contained">
            <AddIcon sx={{ marginRight: 1 }} />
            <Typography>Create New Case</Typography>
          </Button>
        </Link>
      </Box>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>
            <TextField
              fullWidth
              onChange={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search case"
              variant="outlined"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
);

export default CaseListToolbar;

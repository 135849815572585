import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TableContent from '../privacy-policy-frames/table-content-pp';
import FrameBody from '../privacy-policy-frames/frame-body';
import FrameItems from '../privacy-policy-frames/frame-items';
import { FramesTypes } from '../../views/privacy-policy/types';
import Markdown from '../markdown';

const PrivacyPolicyFrames = (props: FramesTypes) => {
  const {
    number,
    title,
    items,
    paragraph,
    body,
    tables,
    secondBody,
    lastBody,
    markdown,
    markdownList,
  } = props;

  return (
    <>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 700,
            color: '#3E8682',
            mr: '32px',
          }}
        >
          {number}
        </Typography>

        <Typography
          sx={{ fontSize: '32px', fontWeight: 700, color: '#221F20' }}
        >
          {title}
        </Typography>
      </Box>
      {paragraph && (
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              color: '#221F20',
              lineHeight: '150%',
            }}
          >
            {paragraph}
          </Typography>
        </Box>
      )}
      {markdown && (
        <Box sx={{ mb: 3 }}>
          <Markdown source={markdown} />
        </Box>
      )}
      {tables && (
        <Box sx={{ mb: 6 }}>
          {tables.map((tables, index: number) => (
            <Grid sx={{ display: 'flex' }} key={index}>
              <TableContent
                title={tables.title}
                content={tables.content}
                left
              />
              <TableContent
                title={tables.secondTitle}
                paragraph={tables.paragraph}
                left
              />
            </Grid>
          ))}
        </Box>
      )}
      {items &&
        items.map((item, index: number) => (
          <FrameItems
            boldTitle={item.boldTitle}
            paragraph={item.paragraph}
            key={index}
          />
        ))}
      {markdownList &&
        markdownList.map((item, index: number) => (
          <Box sx={{ mb: 3 }} key={index}>
            <Markdown source={item} />
          </Box>
        ))}
      {body &&
        body.map((bodies, index: number) => (
          <FrameBody
            key={index}
            italicTitle={bodies.italicTitle}
            bigTitle={bodies.bigTitle}
            paragraph={bodies.paragraph}
            boldTitle={bodies.boldTitle}
            items={bodies.items}
            intro={bodies.intro}
            sameLine={bodies.sameLine}
            markdownList={bodies.markdownList}
          />
        ))}
      {secondBody &&
        secondBody.map((bodies, index: number) => (
          <FrameBody
            italicTitle={bodies.italicTitle}
            items={bodies.items}
            key={index}
          />
        ))}
      {lastBody &&
        lastBody.map((frameBody, index: number) => (
          <Box key={index}>{frameBody}</Box>
        ))}
    </>
  );
};

export default PrivacyPolicyFrames;

import React, { useState, useMemo } from 'react';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuid } from 'uuid';

import { reorder } from '../../utils/reorder';
import { duplicate } from '../case-notice-form/utils';
import DraggableList from '../draggable-list';
import DraggableListItem from '../draggable-list/draggable-list-item';
import TitleWControls from '../title-w-controls';
import GroupingTypeSelect from '../grouping-type-select';

function SelectDragList({
  bigItems,
  title,
  list,
  onChange,
  onAdd,
  buttonLabel,
  components = {},
  children,
  selectableRow = true,
}) {
  const droppableId = useMemo(() => uuid(), []);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const reorderDraggableList =
    (list, setList) =>
    ({ destination, source }) => {
      if (!destination) return;
      const newItems = reorder(list, source.index, destination.index);
      setList(newItems);
    };

  if (bigItems) {
    return (
      <DraggableList
        droppableId={droppableId}
        onDragEnd={reorderDraggableList(list, (value) => {
          onChange(value);
        })}
      >
        {list.map((item, index, list) => (
          <DraggableListItem key={item.id} id={item.id} index={index}>
            {(provided) => (
              <Card sx={{ mt: 3 }}>
                <Grid container sx={{ p: 3 }} spacing={2}>
                  <Stack
                    spacing={1}
                    alignItems="center"
                    direction={{ md: 'row', xs: 'column' }}
                  >
                    <div {...provided.dragHandleProps}>
                      <IconButton aria-label="drag">
                        <DragIndicatorIcon color="action" />
                      </IconButton>
                    </div>
                    {!item.permanent && (
                      <Checkbox
                        checked={index === selectedIndex}
                        onChange={({ target }) => {
                          if (target.checked) {
                            setSelectedIndex(index);
                          } else if (index === selectedIndex) {
                            setSelectedIndex(null);
                          }
                        }}
                      />
                    )}
                  </Stack>
                  <Grid item xs={10}>
                    {item.permanent ? (
                      <Grid container>
                        <Grid item xs={item.group ? 9 : 12}>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: '24px' }}
                          >
                            {item.title}
                          </Typography>
                        </Grid>
                        {item.group && (
                          <Grid item xs={3}>
                            <GroupingTypeSelect
                              value={item.group}
                              onChange={({ target }) => {
                                const result = [...list];
                                result[index].group = target.value;
                                onChange(result);
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <TitleWControls
                        disabled={selectedIndex !== index}
                        titleStyles={{
                          fontWeight: 600,
                          fontSize: '24px',
                          mb: 1.5,
                        }}
                        title={item.title}
                        onCopy={() => {
                          if (selectedIndex == null) return;
                          const newValue = duplicate(list[selectedIndex]);
                          const newList = [...list, newValue];
                          onChange(newList);
                          setSelectedIndex(null);
                        }}
                        onDelete={() => {
                          if (selectedIndex == null) return;
                          const result = Array.from(list);
                          result.splice(selectedIndex, 1);
                          onChange(result);
                          setSelectedIndex(null);
                        }}
                        withTypeSelect={item.group}
                        typeSelectProps={{
                          value: item.group,
                          onChange: ({ target }) => {
                            const result = [...list];
                            result[index].group = target.value;
                            onChange(result);
                          },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider />
                {item.type === 'divider' ? null : (
                  <CardContent>{children(item, index, list)}</CardContent>
                )}
              </Card>
            )}
          </DraggableListItem>
        ))}
      </DraggableList>
    );
  }

  return (
    <>
      {title && (
        <TitleWControls
          disabled={selectedIndex == null}
          title={title}
          onCopy={() => {
            if (selectedIndex == null) return;
            const newValue = duplicate(list[selectedIndex]);
            const newList = [...list, newValue];
            onChange(newList);
            setSelectedIndex(null);
          }}
          onDelete={() => {
            if (selectedIndex == null) return;
            const result = Array.from(list);
            result.splice(selectedIndex, 1);
            onChange(result);
            setSelectedIndex(null);
          }}
        />
      )}
      <DraggableList
        droppableId={droppableId}
        onDragEnd={reorderDraggableList(list, (value) => {
          onChange(value);
        })}
      >
        {list.map((item, index, list) => (
          <DraggableListItem key={item.id} id={item.id} index={index}>
            {(provided) => (
              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={1}>
                  <Stack
                    spacing={1}
                    alignItems="center"
                    sx={{ height: '100%' }}
                  >
                    {selectableRow && (
                      <Checkbox
                        checked={index === selectedIndex}
                        onChange={({ target }) => {
                          if (target.checked) {
                            setSelectedIndex(index);
                          } else if (index === selectedIndex) {
                            setSelectedIndex(null);
                          }
                        }}
                      />
                    )}
                    <div {...provided.dragHandleProps}>
                      <DragIndicatorIcon color="action" />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        flex: 1,
                        width: '1px',
                        margin: 'auto !important',
                        background: '#D1D5DB',
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={11}>
                  {children(item, index, list)}
                </Grid>
              </Grid>
            )}
          </DraggableListItem>
        ))}
      </DraggableList>
      <Stack justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
        {components.Button ? (
          components.Button()
        ) : (
          <Button onClick={onAdd} startIcon={<AddIcon />}>
            {buttonLabel ? buttonLabel : 'Add Another Field'}
          </Button>
        )}
      </Stack>
    </>
  );
}

export default SelectDragList;

import React from 'react';
import { TextField } from '@mui/material';

export const PhoneInput = React.forwardRef<HTMLElement, any>(
  function PhoneInput(props, ref) {
    const { onChange, ...other } = props;

    const formatPhoneNumber = (input: string) => {
      if (input.length > 14) {
        return input.substring(0, 14);
      }
      let phoneNumber = input.replace(/\D/g, '');

      const formattedPhoneNumber = phoneNumber.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '($1) $2-$3'
      );
      return formattedPhoneNumber;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const formattedPhoneNumber = formatPhoneNumber(event.target.value);
      event.target.value = formattedPhoneNumber;
      onChange(event);
    };

    return (
      <TextField
        inputProps={{ itemRef: ref }}
        {...other}
        onChange={handleChange}
      />
    );
  }
);

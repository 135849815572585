import { Box, Typography } from '@mui/material';
import React from 'react';
import { ItemsPrivacyPolicy } from '../../../views/privacy-policy/types';

const FrameItems = (props: ItemsPrivacyPolicy) => {
  const { boldTitle, paragraph } = props;

  return (
    <Box sx={{ mb: 3, display: 'flex', pl: 3 }}>
      <ul>
        <li>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 400, color: '#221F20' }}
          >
            <strong>{boldTitle}</strong>
            {paragraph}
          </Typography>
        </li>
      </ul>
    </Box>
  );
};

export default FrameItems;

import React, { useState, useEffect } from 'react';
import {
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import ImageCarousel from '../../carousel';
import { ColoredPinFilled, CompareChip, DetailCard } from '../pop.styles';
import { PoPRow, Reason, Ruling } from '../pop.types';

const storage = getStorage();

const getImageLink = async (imagePath: string) => {
  const imageRef = ref(storage, imagePath);
  return await getDownloadURL(imageRef);
};

type CardTitleProps = {
  id: string;
  color: 'primary' | 'secondary';
  isPinned?: boolean;
};

const CardTitle = ({ isPinned, color, id }: CardTitleProps) => (
  <Box display="flex" alignItems="center">
    {isPinned && <ColoredPinFilled color={color} />}
    <CompareChip color={color}>COMPARE</CompareChip>
    <Typography noWrap display="block" overflow="hidden" variant="h6">
      {id}
    </Typography>
  </Box>
);

interface PoPDetailCardProps {
  color: 'primary' | 'secondary';
  setValues: (values: PoPRow) => void;
  values?: PoPRow;
  isPinned?: boolean;
  readOnly?: boolean;
}

export function PoPDetailCard({
  color,
  isPinned,
  values,
  setValues,
  readOnly,
}: PoPDetailCardProps) {
  const { caseId } = useParams();

  const resetRulingReasons = () => ({
    [Reason.Duplicate]: false,
    [Reason.Illegible]: false,
    [Reason.Manipulated]: false,
    [Reason.Mismatch]: false,
  });
  const handleChange = (event: SelectChangeEvent<Ruling>) => {
    const hasToResetReason =
      event.target.name === 'ruling' && event.target.value === Ruling.Approved;
    if (values) {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
        reason: hasToResetReason ? resetRulingReasons() : values.reason,
      });
    }
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (values) {
      const valuesCopy = { ...values };
      const reasonName = event.target.name as Reason;
      valuesCopy.reason[reasonName] = !values.reason?.[reasonName];
      setValues({
        ...valuesCopy,
      });
    }
  };

  const [imageLinks, setImageLinks] = useState<string[]>([]);
  useEffect(() => {
    if (values) {
      const getImageLinks = async () => {
        const requestedImageLinks = await Promise.all(
          values.images.map((image) =>
            getImageLink(`/pop/processed/${caseId}/${values.id}/${image}`)
          )
        );
        setImageLinks(requestedImageLinks);
      };
      getImageLinks();
    }
  }, [values?.images]);

  if (!values) return null;
  return (
    <DetailCard>
      <CardHeader
        title={
          readOnly ? (
            <Typography noWrap display="block" overflow="hidden" variant="h6">
              {values.id}
            </Typography>
          ) : (
            <CardTitle id={values.id} color={color} isPinned={isPinned} />
          )
        }
      />
      <CardContent>
        <ImageCarousel images={imageLinks} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Name"
              disabled
              name="name"
              value={values.name}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              label="Purchase Date"
              name="purchaseDate"
              value={values.purchaseDate || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="QTY"
              name="qty"
              disabled
              value={values.quantity}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Payout"
              name="payout"
              disabled
              value={values.payout || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Score"
              name="score"
              disabled
              value={values.score}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="pop-ruling">Ruling</InputLabel>
              <Select
                labelId="pop-ruling"
                variant="outlined"
                name="ruling"
                value={values.ruling}
                label="Ruling"
                onChange={handleChange}
                disabled={readOnly}
              >
                <MenuItem value={Ruling.Pending}>Pending</MenuItem>
                <MenuItem value={Ruling.Rejected}>Rejected</MenuItem>
                <MenuItem value={Ruling.Approved}>Approved</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              disabled={values.ruling !== Ruling.Rejected || readOnly}
              component="fieldset"
              sx={{ width: '100%' }}
              variant="standard"
            >
              <FormLabel component="legend">Jurisdiction</FormLabel>
              <FormGroup
                sx={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <FormControlLabel
                  sx={{ width: { xs: '100%', md: 'auto' } }}
                  control={
                    <Checkbox
                      checked={values.reason?.duplicate}
                      onChange={handleCheckBoxChange}
                      name="duplicate"
                    />
                  }
                  label="Duplicate"
                />
                <FormControlLabel
                  sx={{ width: { xs: '100%', md: 'auto' } }}
                  control={
                    <Checkbox
                      checked={values.reason?.manipulated}
                      onChange={handleCheckBoxChange}
                      name="manipulated"
                    />
                  }
                  label="Manipulated"
                />
                <FormControlLabel
                  sx={{ width: { xs: '100%', md: 'auto' } }}
                  control={
                    <Checkbox
                      checked={values.reason?.mismatch}
                      onChange={handleCheckBoxChange}
                      name="mismatch"
                    />
                  }
                  label="Mismatch"
                />
                <FormControlLabel
                  sx={{ width: { xs: '100%', md: 'auto' } }}
                  control={
                    <Checkbox
                      checked={values.reason?.illegible}
                      onChange={handleCheckBoxChange}
                      name="illegible"
                    />
                  }
                  label="Illegible"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </DetailCard>
  );
}

import React from 'react';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { Tag as TagIcon } from '../../../icons/tag';
import {
  useLoadingOverlay,
  useSelectedCaseAggregatedData,
} from '../../../store';
import { numberFormatter } from '../../../utils/msw/number-formatter';

export const TotalUnits = (props) => {
  const [selectedCaseAggregatedData] = useSelectedCaseAggregatedData();
  const [loadingOverlay] = useLoadingOverlay();

  return (
    <Card sx={{ height: '100%' }} {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">
              TOTAL ELIGIBLE UNITS
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {loadingOverlay.showLoadingOverlay ? (
                <Skeleton />
              ) : (
                numberFormatter.format(
                  selectedCaseAggregatedData.eligibleUnits || 0
                ) || '-'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: 'warning.light',
                height: 56,
                width: 56,
              }}
            >
              <TagIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

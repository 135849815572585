import { v4 as uuid } from 'uuid';

const defaultLegalAction = {
  title: '',
  description: '',
};

export const getNewLegalAction = () => ({
  ...defaultLegalAction,
  id: uuid(),
});

export const defaultDocument = { name: '', url: '', file: { name: '' } };

export const getNewDocument = () => ({
  ...defaultDocument,
  id: uuid(),
});

export const defaultDate = {
  deadline: null,
  detail: '',
};

export const getNewDate = () => ({
  ...defaultDate,
  id: uuid(),
});

export const defaultFAQ = {
  question: '',
  response: '',
};

export const getNewFAQ = () => ({
  ...defaultFAQ,
  id: uuid(),
});

export const defaultForm = {
  updateNotice: '',
  headline: '',
  description: '',
  legalActions: [getNewLegalAction()],
  disclaimer: '',
  documents: [getNewDocument()],
  importantDates: [getNewDate()],
  contactInformation: {
    email: '',
    phone: '',
    claimAdmin: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
  },
  faqs: [getNewFAQ()],
  footerDisclaimer: '',
  privacyPolicyUrl: '',
};

export const duplicate = (toCopy) => {
  const newId = uuid();
  return { ...toCopy, id: newId };
};

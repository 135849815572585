import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
interface LoadingFullPageProps {
  message?: string;
}

export const LoadingFullPage = ({ message }: LoadingFullPageProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Stack alignItems="center">
        {/* //TODO: place animated SVG of the logo */}
        <Typography sx={{ margin: 3 }} color="text.secondary">
          {message || 'Doing Stuff...'}
        </Typography>
      </Stack>
    </Box>
  );
};

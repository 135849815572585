export interface PoPRow {
  id: string;
  name: string;
  images: string[];
  purchaseDate: string;
  quantity: number;
  payout: number;
  score: number;
  rejectionCodes: string[];
  ruling: Ruling;
  reason: {
    duplicate: boolean;
    manipulated: boolean;
    mismatch: boolean;
    illegible: boolean;
  };
  status: string;
}

export enum Reason {
  Duplicate = 'duplicate',
  Manipulated = 'manipulated',
  Mismatch = 'mismatch',
  Illegible = 'illegible',
}

export enum Ruling {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
export interface PoPCardControl extends PoPRow {
  isPinned?: boolean;
  color?: string;
  readOnly?: boolean;
}

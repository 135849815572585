import Axios from 'axios';
import { authenticateInterceptor } from './interceptors';
import { getAuthToken } from '../gcp/auth';

const jobOrchestratorClient = Axios.create({
  baseURL: process.env.REACT_APP_JOB_ORCHESTRATOR_SERVICE_URL,
});

export const createDataTransfer = (requestBody) =>
  jobOrchestratorClient.post('/claims', requestBody, {
    headers: { JSON: true },
  });

// INTERCEPTORS
jobOrchestratorClient.interceptors.request.use(authenticateInterceptor);

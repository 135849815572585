import React from 'react';
import styled from '@emotion/styled';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { ClaimScore } from '../../icons/claim-score';

const PublicLayoutRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

export const PublicLayout = (props) => {
  const { onSidebarOpen, withSidebar, ...other } = props;
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false,
  });

  return (
    <>
      <PublicLayoutRoot
        sx={{
          position: 'relative',
          left: {
            lg: withSidebar ? 280 : 0,
          },
          width: {
            lg: withSidebar ? 'calc(100% - 280px)' : '100%',
          },
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
            boxShadow:
              ' 0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
          }}
        >
          <Box sx={{ height: '23px', ml: 2 }}>
            <ClaimScore />
          </Box>
        </Toolbar>
      </PublicLayoutRoot>
      {props.children}
    </>
  );
};

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PATHS } from './utils/routes/paths';
import Layout from './components/Layout';
import AuthWall from './components/auth-wall';
import CaseDashboard from './views/case-dashboard';
import Login from './views/log-in';
import SignUp from './views/sign-up';
import PasswordRecovery from './views/password-recovery';
import MyCases from './views/my-cases';
import CaseDetails from './views/case-details';
import CaseNotice from './views/case-notice';
import CaseInstructions from './views/case-instructions';
import CaseForm from './views/case-form';
import ProofOfPurchase from './views/proof-of-purchase';
import ProofOfPurchaseDetails from './views/proof-of-purchase-details';
import Entryway from './views/entryway';
import '../src/theme/globals.css';
import AuthManagement from './views/_auth';
import EmailVerification from './views/email-verification';
import PasswordReset from './views/password-reset';
import { SnackBar } from './components/snackbar';
import TermsOfUse from './views/terms-of-use';
import { PublicLayout } from './components/Layout/public-layout';
import PrivacyPolicy from './views/privacy-policy';
import { SuperAdminAccounts } from './views/super-admin-accounts';
import { SuperAdminCases } from './views/super-admin-cases';
import CaseReports from './views/case-reports';
import { CasePermissions } from './views/case-permissions';
import Error404 from './components/error404';
import { ROLES } from '../src/utils/roles.js';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SnackBar>
        <BrowserRouter>
          <Routes>
            <Route
              path={PATHS.terms}
              element={
                <PublicLayout>
                  <TermsOfUse />
                </PublicLayout>
              }
            />
            <Route
              path={PATHS.privacyPolicy}
              element={
                <PublicLayout>
                  <PrivacyPolicy />
                </PublicLayout>
              }
            />
            <Route path={PATHS.login} element={<Login />} />
            <Route path={PATHS.signUp} element={<SignUp />} />
            <Route path={PATHS.pageNotFound} element={<Error404 />} />
            <Route path={PATHS.auth} element={<AuthManagement />} />
            <Route path={PATHS.passwordReset} element={<PasswordReset />} />
            <Route
              path={PATHS.emailVerification}
              element={<EmailVerification />}
            />
            <Route path={PATHS.forgotPassword} element={<PasswordRecovery />} />
            <Route
              path={PATHS.entryway}
              element={
                <AuthWall skipRedirect>
                  <Entryway />
                </AuthWall>
              }
            />
            <Route
              path={PATHS.superAdminAccounts}
              element={
                <AuthWall minimumRole={ROLES.SuperAdmin}>
                  <Layout>
                    <SuperAdminAccounts />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.superAdminCases}
              element={
                <AuthWall minimumRole={ROLES.SuperAdmin}>
                  <Layout>
                    <SuperAdminCases />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.myCases}
              element={
                <AuthWall>
                  <Layout>
                    <MyCases />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.casePermissions}
              element={
                <AuthWall minimumRole={ROLES.Admin}>
                  <Layout withSidebar>
                    <CasePermissions />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.caseDashboard}
              element={
                <AuthWall minimumRole={ROLES.Viewer}>
                  <Layout withSidebar>
                    <CaseDashboard />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.caseDetails}
              element={
                <AuthWall minimumRole={ROLES.Viewer}>
                  <Layout withSidebar>
                    <CaseDetails />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.caseNew}
              element={
                <AuthWall>
                  <Layout>
                    <CaseDetails newCase />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.caseNotice}
              element={
                <AuthWall minimumRole={ROLES.Admin}>
                  <Layout withSidebar>
                    <CaseNotice />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.caseInstructions}
              element={
                <AuthWall minimumRole={ROLES.Admin}>
                  <Layout withSidebar>
                    <CaseInstructions />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.caseForm}
              element={
                <AuthWall minimumRole={ROLES.Admin}>
                  <Layout withSidebar>
                    <CaseForm />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.caseReports}
              element={
                <AuthWall minimumRole={ROLES.Viewer}>
                  <Layout withSidebar>
                    <CaseReports />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.proofOfPurchase}
              element={
                <AuthWall minimumRole={ROLES.Reviewer}>
                  <Layout withSidebar>
                    <ProofOfPurchase />
                  </Layout>
                </AuthWall>
              }
            />
            <Route
              path={PATHS.proofOfPurchaseDetails}
              element={
                <AuthWall minimumRole={ROLES.Reviewer}>
                  <Layout withSidebar>
                    <ProofOfPurchaseDetails />
                  </Layout>
                </AuthWall>
              }
            />
          </Routes>
        </BrowserRouter>
      </SnackBar>
    </LocalizationProvider>
  );
}

export default App;

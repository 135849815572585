import React from 'react';
import { Grid, IconButton, TextField } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { getNewOption } from '../../utils/generate-element';
import SelectDragList from '../select-drag-list';

const RowWrapper = ({ deletable, onDelete, children }) => (
  <Grid container spacing={2}>
    <Grid item xs={deletable ? 10 : 12} md={deletable ? 11 : 12}>
      {children}
    </Grid>
    {deletable && (
      <Grid item xs={2} md={1} display="flex" alignItems="middle">
        <IconButton aria-label="delete" onClick={onDelete}>
          <DeleteOutlineIcon />
        </IconButton>
      </Grid>
    )}
  </Grid>
);

export default function SelectionGroup({
  groupingType,
  options,
  handleChange,
}) {
  const onAddOption = () => {
    const newOptions = [...options];
    newOptions.push(getNewOption());
    handleChange(newOptions);
  };

  const onDelete = (index) => () => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    handleChange(newOptions);
  };

  const onChangeOption =
    (index) =>
    ({ target }) => {
      const newOptions = [...options];
      options[index][target.name] = target.value;
      handleChange(newOptions);
    };

  const renderOptions = () => {
    switch (groupingType) {
      case 'checkbox':
        return function Checkbox(item, index) {
          return (
            <RowWrapper deletable={!item.permanent} onDelete={onDelete(index)}>
              <TextField
                label={`Option ${index + 1}`}
                fullWidth
                value={item.description}
                name="description"
                onChange={onChangeOption(index)}
              />
            </RowWrapper>
          );
        };
      case 'radio':
        return function Radio(item, index) {
          return (
            <RowWrapper deletable={!item.permanent} onDelete={onDelete(index)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <TextField
                    label={`Option ${index + 1}`}
                    fullWidth
                    value={item.description}
                    name="description"
                    onChange={onChangeOption(index)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    value={item.value}
                    label={`Associated Unit #`}
                    fullWidth
                    name="value"
                    onChange={onChangeOption(index)}
                  />
                </Grid>
              </Grid>
            </RowWrapper>
          );
        };
      case 'number':
        return function Number(item, index) {
          return (
            <RowWrapper deletable={!item.permanent} onDelete={onDelete(index)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <TextField
                    label={`Option ${index + 1}`}
                    fullWidth
                    value={item.description}
                    name="description"
                    onChange={onChangeOption(index)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    value={item.value}
                    label={`Max Units`}
                    fullWidth
                    name="value"
                    onChange={onChangeOption(index)}
                  />
                </Grid>
              </Grid>
            </RowWrapper>
          );
        };
      case 'dropdown':
        return function Dropdown(item, index) {
          return (
            <RowWrapper deletable={!item.permanent} onDelete={onDelete(index)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <TextField
                    label={`Option ${index + 1}`}
                    fullWidth
                    value={item.description}
                    name="description"
                    onChange={onChangeOption(index)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    value={item.value}
                    label={`Max Units`}
                    fullWidth
                    name="value"
                    onChange={onChangeOption(index)}
                  />
                </Grid>
              </Grid>
            </RowWrapper>
          );
        };
      default:
        throw new Error(
          `Invalid options type on claim-form generator. ${groupingType}`
        );
    }
  };

  return (
    <SelectDragList
      list={options}
      selectableRow={false}
      onAdd={onAddOption}
      onChange={handleChange}
    >
      {renderOptions()}
    </SelectDragList>
  );
}

import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { generateElement } from '../../utils/generate-element';
import SelectDragList from '../select-drag-list';
import { useLoadingOverlay, useSelectedCase, useSnackbar } from '../../store';
import { LoadingOverlay } from '../loading-overlay/loading-overlay';
import AddElementModal from '../add-element-modal';
import ElementItem from '../element-item';
import { db } from '../../gcp/config';
import { useCaseId } from '../../hooks/useCaseId';
import { defaultForm } from '../case-notice-form/utils';

function CaseForm() {
  useCaseId();
  const { caseId } = useParams();
  const [selectedCase] = useSelectedCase();
  const [elements, setElements] = useState([generateElement('markdown', [])]);
  const [loadingOverlay, setLoading] = useLoadingOverlay();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    const getCaseInstructionsValue = async () => {
      setLoading(true);
      const docRef = doc(db, 'Templates', selectedCase.caseID || caseId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setElements(data.instructions || [generateElement('markdown', [])]);
      }
      setLoading(false);
    };
    getCaseInstructionsValue();
  }, [selectedCase.caseID, setLoading]);

  const saveForm = async () => {
    const caseRef = doc(db, 'Templates', selectedCase.caseID);
    setLoading(true);
    try {
      const docSnap = await getDoc(caseRef);
      if (docSnap.exists()) {
        await updateDoc(caseRef, {
          instructions: elements,
        });
      } else {
        await setDoc(caseRef, {
          caseID: selectedCase.caseID,
          caseName: selectedCase.caseName,
          caseNumber: selectedCase.caseNumber,
          stateId: selectedCase.stateID,
          home: defaultForm,
          instructions: elements,
        });
      }
      openSnackbar('Yay! Everything worked!', 'success');
    } catch (error) {
      openSnackbar('Failed to upload Instructions Page Data', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingOverlay open={loadingOverlay.showLoadingOverlay} />
      <AddElementModal
        open={isModalOpen}
        onSelect={(type) => {
          setElements((prev) => [...prev, generateElement(type, elements)]);
        }}
        onClose={() => setIsModalOpen(false)}
        ignoreComponent={['selection']}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
        }}
      >
        <Typography sx={{ m: 1 }} variant="h4">
          Instructions Page
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ m: 1 }}>
            <Button
              sx={{ mr: 1, background: 'primary.light' }}
              variant="contained"
              component="label"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Add Element
            </Button>
          </Box>
          <Box sx={{ m: 1 }}>
            <Button
              sx={{ mr: 1, background: 'primary.light' }}
              variant="outlined"
              component="label"
              onClick={saveForm}
            >
              Save w/o Publishing
            </Button>
          </Box>
        </Box>
      </Box>
      <SelectDragList bigItems list={elements} onChange={setElements}>
        {(item, index, list) => (
          <ElementItem
            key={item.id}
            index={index}
            list={list}
            {...item}
            onChange={setElements}
          />
        )}
      </SelectDragList>
    </>
  );
}

export default CaseForm;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { v4 as uuid } from 'uuid';
import { Grid, Stack } from '@mui/material';
import { ReportFilter } from './report-filter';
import { onSnapshotChange, onUploadComplete, onUploadFail } from './utils';
import { useLoadingOverlay, useSelectedCase } from '../../store';
import { LoadingOverlay } from '../loading-overlay/loading-overlay';
import { uploadFileToStorage } from '../../gcp/storage';
import useRole from '../../hooks/useRole';
import { Role } from '../../views/case-permissions/types';

export const CaseListToolbar = (props) => {
  const [selectedCase] = useSelectedCase();
  const [loadingOverlay, setLoadingOverlay] = useLoadingOverlay();
  const [file, setFile] = useState(null);
  const { loadingRole, userRole } = useRole();

  function onFileChangeHandler(event) {
    setFile(event.target.files[0]);
  }

  function onDeleteHandler() {
    setFile(null);
  }

  function onUploadHandler() {
    setLoadingOverlay(true, 'Uploading file ✈');
    const fileRoute = `csvs/${selectedCase.caseID}/${uuid()}.csv`;
    const uploadTask = uploadFileToStorage(file, fileRoute);
    uploadTask.on(
      'state_changed',
      onSnapshotChange(),
      onUploadFail(),
      onUploadComplete(fileRoute, selectedCase.caseID)
    );
    setFile(null);
  }

  const showImportClaimCSVButton = () => {
    if (userRole !== Role.Viewer && !loadingRole) {
      return (
        <Grid item xs={12} md={5} sx={{ m: 1 }}>
          <Button
            sx={{ mr: 1, background: 'primary.light' }}
            variant="outlined"
            component="label"
            fullWidth
          >
            Import Claim CSV
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={onFileChangeHandler}
            />
          </Button>
        </Grid>
      );
    }

    return null;
  };
  return (
    <>
      <LoadingOverlay
        open={loadingOverlay.showLoadingOverlay}
        message={loadingOverlay.loadingMessage}
      />
      <Box {...props}>
        <Grid
          container
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1,
          }}
        >
          <Grid item xs={12} md={6}>
            <Stack direction="column">
              <Typography sx={{ mx: 1 }} variant="h4">
                {selectedCase.caseName}
              </Typography>
              <Typography sx={{ mx: 1 }} variant="subtitle1">
                {`Case No. ${selectedCase.caseNumber} | ${selectedCase.stateID}`}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {file == null ? (
                showImportClaimCSVButton()
              ) : (
                <Modal
                  open
                  sx={{
                    display: 'flex',
                    p: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      p: 4,
                      borderRadius: '12px',
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Upload {file.name} ?
                    </Typography>
                    <Button
                      onClick={onUploadHandler}
                      sx={{ background: 'primary.light', mb: 1 }}
                      variant="contained"
                      fullWidth
                    >
                      Yes, upload.
                    </Button>
                    <Button
                      fullWidth
                      onClick={onDeleteHandler}
                      color="error"
                      variant="outlined"
                    >
                      No, cancel.
                    </Button>
                  </Box>
                </Modal>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

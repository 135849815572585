import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { isClaimScoreApproved, isEmailVerified, signIn } from '../../gcp/auth';
import Alert from '../../components/alert';
import { PATHS } from '../../utils/routes/paths';
import { useCurrentUser } from '../../store';
import { getUserInfo } from '../../services/users.service';

const Login = () => {
  const navigate = useNavigate();

  const [, setCurrentUser] = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),

    onSubmit: async () => {
      setLoading(true);
      try {
        await signIn(formik.values.email, formik.values.password);
        const { data } = await getUserInfo();
        setCurrentUser(data);
        const emailConfirmed = await isEmailVerified();
        const adminApproved = await isClaimScoreApproved();
        if (emailConfirmed && adminApproved) {
          navigate(PATHS.myCases);
        } else {
          navigate(PATHS.entryway);
        }
      } catch (e) {
        setShowAlert(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="sm">
        <Paper sx={{ padding: '32px 24px' }}>
          <form onSubmit={formik.handleSubmit}>
            <Alert
              message="Invalid email or password!"
              type="error"
              setOpen={setShowAlert}
              open={showAlert}
            />
            <Box sx={{ my: 0 }}>
              <Typography color="textPrimary" variant="h4">
                Sign in
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              InputLabelProps={{
                shrink: true,
              }}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting || loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign In
              </Button>
            </Box>
            <Link to={PATHS.forgotPassword} style={{ textDecoration: 'none' }}>
              <Typography
                color="textSecondary"
                variant="body2"
                sx={{
                  fontWeight: 'fontWeightBold',
                  color: 'primary.main',
                }}
              >
                Forgot your password?
              </Typography>
            </Link>
            <Box
              sx={{
                py: 2,
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Typography
                color="textSecondary"
                sx={{
                  fontWeight: 'fontWeightBold',
                  color: 'primary.text',
                }}
              >
                {"Don't have an account?"}
              </Typography>
              <Link to={PATHS.signUp} style={{ textDecoration: 'none' }}>
                <Typography
                  color="textSecondary"
                  sx={{
                    ml: 1,
                    fontWeight: 'fontWeightBold',
                    color: 'primary.main',
                  }}
                >
                  Sign up
                </Typography>
              </Link>
            </Box>
            {loading && (
              <Box
                sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

function GroupingTypeSelect({ value, onChange }) {
  return (
    <FormControl>
      <InputLabel id="select-label">Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={value}
        label="Type"
        onChange={onChange}
      >
        <MenuItem value={'checkbox'}>Checkbox</MenuItem>
        <MenuItem value={'radio'}>Radio</MenuItem>
        <MenuItem value={'number'}>Number</MenuItem>
        <MenuItem value={'dropdown'}>Dropdown</MenuItem>
      </Select>
    </FormControl>
  );
}

export default GroupingTypeSelect;

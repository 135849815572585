import React from 'react';
import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Backdrop from '@mui/material/Backdrop';
import { Box } from '@mui/system';
import { useErrorOverlay } from '../../store';

export const DashboardError = () => {
  const [errorOverlay, setErrorOverlay] = useErrorOverlay();

  function handleClose() {
    setErrorOverlay(false, null);
  }

  return (
    <Backdrop
      sx={{
        color: 'background.default',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={errorOverlay.showErrorOverlay}
      onClick={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '40%',
        }}
      >
        <ErrorOutlineIcon fontSize="large" />
        <Typography sx={{ margin: 3 }} color="white">
          {errorOverlay.errorMessage}
        </Typography>
      </Box>
    </Backdrop>
  );
};

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as signOutFirebase,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
} from 'firebase/auth';
import { auth } from './config';

export const signIn = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signUp = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const signOut = () => {
  return signOutFirebase(auth);
};

export const getAuthToken = () => {
  return auth.currentUser?.getIdToken();
};

export const getUserId = () => {
  return auth.currentUser?.uid as string;
};

export const isEmailVerified = async () => {
  await auth.currentUser?.reload();
  return Boolean(auth.currentUser?.emailVerified);
};

export const isClaimScoreApproved = async () => {
  if (!auth.currentUser) return false;
  const { claims } = await auth.currentUser.getIdTokenResult(true);
  return Boolean(claims.approved);
};

export const isSuperAdmin = async () => {
  if (!auth.currentUser) return false;
  const { claims } = await auth.currentUser.getIdTokenResult(true);
  return Boolean(claims.superAdmin);
};

export const verifyPasswordReset = (actionCode: string) => {
  return verifyPasswordResetCode(auth, actionCode);
};

export const resetPassword = (actionCode: string, password: string) => {
  return confirmPasswordReset(auth, actionCode, password);
};

export const verifyEmail = (actionCode: string) => {
  return applyActionCode(auth, actionCode);
};

import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { privacyPolicy } from './constants';
import TableContent from '../../components/privacy-policy-frames/table-content-pp';
import PrivacyPolicyFrames from '../../components/privacy-policy-frames';

const StyledBox = styled(Box)`
  display: flex;
  margin-bottom: 3;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        margin: 6,
      }}
    >
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
          {privacyPolicy.title}
        </Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="overline"
          sx={{
            lineHeight: '250%',
            fontWeight: 500,
            fontSize: '12px',
            color: '#3E8682',
          }}
        >
          {privacyPolicy.update}
        </Typography>
      </Box>
      {privacyPolicy.body.map((value, index: number) => (
        <Box sx={{ mb: 3 }} key={index}>
          <Typography
            sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '150%' }}
            key={index}
          >
            {value}
          </Typography>
        </Box>
      ))}
      <TableContent
        title={privacyPolicy.mainTable.title}
        markdown={privacyPolicy.mainTable.markdown}
      />
      <StyledBox sx={{ mb: 5 }}>
        {privacyPolicy.secondaryTables.map((value, index: number) => (
          <TableContent
            key={index}
            body={value.body}
            items={value.items}
            subtitle={value.subtitle}
            additionalPurposes={value.additionalPurposes}
            secondBody={value.secondBody}
          />
        ))}
      </StyledBox>
      {privacyPolicy.frames.map((frames, index: number) => (
        <PrivacyPolicyFrames
          key={index}
          number={frames.number}
          title={frames.title}
          paragraph={frames.paragraph}
          tables={frames.tables}
          body={frames.body}
          secondBody={frames.secondBody}
          lastBody={frames.lastBody}
          items={frames.items}
          markdown={frames.markdown}
          markdownList={frames.markdownList}
        />
      ))}
    </Box>
  );
};

export default PrivacyPolicy;

export const privacyPolicy = {
  title: 'ClaimScore Privacy Policy',
  update: 'LAST UPDATED: MAY 10, 2023 ',
  body: [
    'This Privacy Policy (this “Policy”) applies to our collection and use of your Personal Information on the websites where this Policy is posted (each, a “Site”), including your use of, and our development and offering of, class action settlement administration services (collectively, the “Services”). In this Policy, “we,” “us,” or “our” are references to ClaimScore LLC.',
    'Please read this Policy carefully to learn how we collect, use, share, and otherwise process your Personal Information, and to learn about your rights and choices regarding such Personal Information. By visiting or using the Services, and by providing Personal Information to us by any means, whether on a Site or in any type of correspondence, you consent to our use of such information in accordance with this Policy. If you do not agree with this Policy or our practices, please do not use the Services.',
  ],
  mainTable: {
    title: 'Notice at Collection for California Residents',
    markdown: `<p>We collect your Personal Information as detailed here and in our <a href="privacy" target="_blank">Privacy Policy</a>. In accordance with applicable California law, this serves as our Notice at Collection of such Personal Information. As defined by applicable law, we may “sell” or “share” certain Personal Information. If you would like to opt-out of the sale or sharing of your Personal Information, you may do so by emailing us at <a href="mailto:privacy@claimscore.ai" target="_blank">privacy@claimscore.ai</a>. You may also choose to enable online, where available, a universal tool that automatically communicates your opt-out preferences, such as the Global Privacy Control (“GPC”). We will process the GPC signal as a request to opt-out.&nbsp;We will only retain Personal Information for as long as reasonably necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements to which we are subject, as well as to resolve disputes and enforce our agreements. We also keep Personal Information as otherwise required by law.</p>`,
  },
  secondaryTables: [
    {
      subtitle: 'What categories of Personal Information do we collect?',
      items: [
        {
          boldTitle: 'Identifiers:',
          paragraph:
            ' This includes your name, postal address, email address, mobile or other telephone number. This data will be sold or shared in accordance with our Privacy Policy.',
        },
        {
          boldTitle:
            'Any Personal Information Described in Subdivision (e) of Cal. Civil Code Section 1798.80.',
          paragraph:
            ' This includes name and employment. This data will be sold or shared in accordance with our Privacy Policy.',
        },
        {
          boldTitle: 'Commercial Information:',
          paragraph:
            ' This includes records of services purchased or considered. This data will be sold or shared in accordance with our Privacy Policy.',
        },
        {
          boldTitle:
            'Internet or other Electronic Network Activity Information:',
          paragraph:
            ' This includes information about your use of the Services, including your Internet Protocol (“IP”) address, unique device identifier, device characteristics, system settings and preferences, search queries, referring URLs, and the time and date of your usage. We also collect and store information about how you interact with a Site and how you interact with emails that we may send you. We may link certain data elements we have collected, such as your browser information, with other information we have obtained about you. This data will be sold or shared in accordance with our Privacy Policy.',
        },
        {
          boldTitle: 'Geolocation Data:',
          paragraph:
            ' This includes information about the location of the devices used to access a Site, including the IP address of the device or internet service used to access a Site',
        },
        {
          boldTitle: 'Professional or Employment-related Information:',
          paragraph:
            ' This includes information you provide regarding your company name.',
        },
        {
          boldTitle: 'Inferences Drawn from the Above:',
          paragraph:
            ' We draw inferences from certain of the categories of Personal Information above to create a personalized profile so that we can identify and offer predictive services that may be of interest to you. This data will be sold or shared in accordance with our Privacy Policy.',
        },
      ],
    },
    {
      subtitle:
        'What are the purposes for which we collect and use Personal Information?',
      body: [
        "To fulfill our legal obligation to provide class action settlement administration services to the settlement administrator, class counsel, defendant's counsel, and the court, including the ability to log-in to the Services on a Site;",
        'To detect and prevent fraud and other unlawful activity;',
        'To respond to your questions related to the claims administration process;',
        'To use third-party analytics and tracking tools to better understand who is using the Services and how they are using them, as well as how to improve the effectiveness of the Services;',
        'To identify, offer, and contact you with personalized content and advertising related to the Services; ',
        'To develop new Services;',
        'For marketing and promotional purposes;',
        'To perform our legal, regulatory, and contractual obligations, and enforce our rights;',
        'To notify you of changes to a Site, its policies, or the Services.',
      ],
      secondBody: [
        'We may use Personal Information in anonymous, de-identified, or aggregate form, e.g., to improve the Services or to provide Services to our customers.',
        'We may use Personal Information as otherwise permitted by law or for other purposes with your consent.',
      ],
      additionalPurposes: 'Additional Purposes:',
    },
  ],
  frames: [
    {
      number: '1.',
      title: 'TYPES OF PERSONAL INFORMATION WE COLLECT',
      paragraph:
        'When information identifies or is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with you or your household, we refer to it as “Personal Information,” which we collect from you and about you. This may include:',
      tables: [
        {
          title: 'Personal Information Categories',
          secondTitle: 'Specific Personal Information',
        },
        {
          content: 'Identifiers',
          paragraph:
            'This includes your name, postal address, email address, mobile or other telephone number.',
        },
        {
          content:
            'Any Personal Information Described in Subdivision (e) of Cal. Civil Code Section 1798.80',
          paragraph: 'This includes name and employment',
        },
        {
          content: 'Commercial Information',
          paragraph:
            'This includes records of services purchased or considered.',
        },
        {
          content: 'Internet or other Electronic Network Activity Information',
          paragraph:
            'This includes information about your use of the Services, including your Internet Protocol (“IP”) address, unique device identifier, device characteristics, system settings and preferences, search queries, referring URLs, and the time and date of your usage. We also collect and store information, about how you interact with a Site, and how you interact with emails that we may send you. We may link certain data elements we have collected, such as your browser information, with other information we have obtained about you.',
        },
        {
          content: 'Geolocation Data',
          paragraph:
            'This includes information about the location of the devicesused to access a Site, including the IP address of the device or internet service used to access a Site.',
        },
        {
          content: 'Professional or Employment-related Information',
          paragraph:
            'This includes information you provide regarding your company name',
        },
        {
          content: 'Inferences Drawn from the Above:',
          paragraph:
            'We draw inferences from certain of the categories of Personal Information above to create a personalized profile so that we can identify and offer services that may interest you.',
        },
      ],
    },
    {
      number: '2.',
      title: 'HOW WE COLLECT YOUR PERSONAL INFORMATION',
      paragraph:
        'There are several ways we may obtain Personal Information, including through information you provide to us directly through online interactions and information that we automatically collect when you use the Services.',
      body: [
        {
          bigTitle: 'Information You Provide',
          paragraph:
            'We collect information from you when you choose to disclose it to us, for example when you create an account and use your credentials to log-in to the Services on a Site, or when you sign-up to receive email marketing from us.',
        },
        {
          bigTitle: 'Information Automatically Collected',
          paragraph:
            'When you visit or interact with the Services, we, as well as third-party advertisers and our service providers, may use cookies, web beacons, pixel tags, or other technologies to automatically or passively collect information about your online activity. As you interact with the Services, we may automatically collect technical information about your device as well as usage information through these and other similar technologies. We may also collect certain information through these technologies when you visit other websites.',
        },
      ],
    },
    {
      number: '3.',
      title: 'HOW WE USE THE PERSONAL INFORMATION WE COLLECT',
      body: [
        {
          intro:
            'We may use the Personal Information that we collect for the following business purposes:',
          items: [
            "To fulfill our legal obligation to provide class action settlement administration services to the settlement administrator, class counsel, defendant's counsel, and the court, including the ability to log-in to the Services on a Site;",
            'To detect and prevent fraud and other unlawful activity;',
            'To respond to your questions related to the claims administration process;',
            'To use third-party analytics and tracking tools to better understand who is using the Services and how they are using them, as well as how to improve the effectiveness of the Services;',
            'To perform our legal, regulatory, and contractual obligations, and enforce our rights;',
            'To notify you of changes to a Site, its policies, or the Services.',
          ],
        },
        {
          paragraph:
            'We may use the Personal Information we collect for the following commercial purposes:',
          items: [
            'To identify, offer, and contact you with personalized content and advertising related to the Services; ',
            'To develop new Services;',
            'For marketing and promotional purposes;',
          ],
        },
      ],
      secondBody: [
        {
          italicTitle: 'Additional Purposes:',
          items: [
            'We may use Personal Information in anonymous, de-identified, or aggregate form, e.g., to improve the Services.',
            'We may use Personal Information as otherwise permitted by law or for other purposes with your consent.',
          ],
        },
        {
          italicTitle: 'Use and Disclosure of Sensitive Personal Information:',
          items: [
            'To the extent that we collect, use, or share “sensitive personal information” as that term is defined under California law, we limit our use or disclosure of the sensitive personal information to permitted business purposes.',
          ],
        },
      ],
    },
    {
      number: '4.',
      title: 'HOW WE DISCLOSE THE PERSONAL INFORMATION WE COLLECT',
      body: [
        {
          boldTitle: 'Our Service Providers.',
          sameLine:
            ' We may disclose Personal Information to our service providers (such as vendors and consultants) that perform certain functions or services on our behalf to perform the Services. These include companies that host our Services, manage databases, perform analyses, send communications for us, process payments to you, or provide certain other components of the Services. Our service providers are prohibited from using or disclosing Personal Information we share with them for any purpose other than performing the Services on our behalf.',
        },
        {
          boldTitle: 'Third Parties for Commercial Purposes.',
          sameLine:
            ' We may share Personal Information with others as listed below, consistent with this Policy and including within the last 12 months. We have “sold” or “shared” (as those terms are defined under California law) the categories of your information identified in Section 1 above with our advertising and analytics partners, our family of companies and related brands, and where we have co-branded relationships',
        },
        {
          boldTitle: 'Third Parties Pursuant to Legal Process.',
          sameLine:
            ' We may share Personal Information to the extent necessary to respond to subpoenas, court orders, or other legal process; in response to a request for cooperation from law enforcement or a government agency; or to otherwise comply with our other legal and regulatory obligations.',
        },
        {
          boldTitle: 'Relevant Third Parties as Part of a Transaction.',
          sameLine:
            ' To advance our lawful commercial interests, we may share Personal Information with certain third parties (including our professional advisers) in connection with a corporate transaction, such as a sale, assignment, divestiture, merger, bankruptcy, consolidation, reorganization, liquidation, or other transfer of the business or its assets. When we do this, we will incorporate reasonable security and other measures to protect Personal Information.',
        },
        {
          boldTitle: 'Aggregated or de-identified information.',
          sameLine:
            ' We may use and disclose your Personal Information to third parties in anonymous, de-identified, or aggregate form, e.g., to provide industry metrics.',
        },
      ],
    },
    {
      number: '5.',
      title: 'MARKETING CHOICES',
      markdownList: [
        `<p>To take advantage of your rights, please contact us by email at <a href="mailto:privacy@claimscore.ai" target="_blank">privacy@claimscore.ai</a> or by phone at <a href=" (973) 259-6120" target="_blank">(973) 259-6120</a> to submit your request. We may request certain information about your interactions with us to verify your identity before we can respond to your requests. Where applicable, we will confirm receipt of your request within 10 business days, and we will respond to your request within 45 calendar days, after proper verification, unless we need additional time, in which case we will let you know.</p>`,
        `<p>To take advantage of your right to opt-out of the sale or sharing of Personal Information or to opt-out of targeted advertising, please email us at <a href="mailto:privacy@claimscore.ai" target="_blank">privacy@claimscore.ai</a>. We will work to comply with your opt-out request within 15 business days. You may also use an authorized agent to submit a request to opt-out on your behalf if you provide the authorized agent signed written permission to do so.</p>`,
        `<p>We value your privacy and will not discriminate in response to your exercise of your privacy rights.&nbsp;</p>`,
      ],
    },
    {
      number: '6.',
      title: 'SECURITY OF PERSONAL INFORMATION',
      paragraph:
        'We maintain reasonable security procedures to help protect against loss, misuse, or unauthorized access, disclosure, alteration, or destruction of Personal Information and Scores. Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. As a result, while we strive to protect Personal Information, we cannot guarantee or warrant the security of any information you disclose to us. If you prefer not to transmit Personal Information over the Internet, we make other methods (e.g., telephone and postal mail) available in the claims process. If you have any questions regarding security, you can contact us through the means set forth in the “How to Contact Us” section.',
    },
    {
      number: '7.',
      title: "CHILDREN'S PRIVACY",
      paragraph:
        'We are committed to protecting the privacy of children. The Services are not directed to children and we do not knowingly collect any Personal Information from children. If a child under the age of 16 has provided us with Personal Information online, we ask that a parent or guardian please contact us, and we will take reasonable steps to delete Personal Information provided by the child. We do not knowingly collect, sell, or share the information of anyone under 16 years of age.',
    },
    {
      number: '8.',
      title: 'RETENTION OF PERSONAL INFORMATION',
      paragraph:
        'We will only retain Personal Information for as long as reasonably necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements to which we are subject, as well as to resolve disputes and enforce our agreements. We also keep Personal Information as otherwise required by law.',
    },
    {
      number: '9.',
      title: 'YOUR STATE-SPECIFIC PRIVACY RIGHTS',
      paragraph:
        'If you are a California or Virginia resident, you may take advantage of the following privacy rights:',
      items: [
        {
          boldTitle: 'Right to Know:',
          paragraph:
            ' You have the right to know and access what Personal Information we have collected about you, including the categories of Personal Information, the categories of sources from which the Personal Information is collected, the business or commercial purpose for collecting, selling, or sharing Personal Information, the categories of third parties to whom we disclose Personal Information, and the specific pieces of Personal Information we have collected about you.',
        },
        {
          boldTitle: 'Right to Portability:',
          paragraph:
            ' You have the right to obtain a portable copy of the Personal Information that you provided to us.',
        },
        {
          boldTitle: 'Right to Delete:',
          paragraph:
            ' You have the right to delete Personal Information that we have collected from you. Note that there are some reasons we will not be able to fully address your request, such as to detect and protect against fraudulent and illegal activity, to exercise our rights, for our legitimate internal purposes, or to comply with a legal obligation.',
        },
        {
          boldTitle: 'Right to Correct:',
          paragraph:
            ' You have the right to correct inaccurate Personal Information that we may maintain about you, subject to appropriate verification.',
        },
        {
          boldTitle:
            'Right to Opt-Out of the Sale or Sharing of Personal Information:',
          paragraph:
            ' You have the right to opt-out of the “sale” or “sharing” of your Personal Information, as such terms are defined under state privacy law. This means that, if you opt-out, going forward we will not sell or share your Personal Information with such third parties to use for their purposes, including cross-context behavioral advertising, unless you later direct us to do so.',
        },
        {
          boldTitle: 'Right to Opt-Out of Targeted Advertising:',
          paragraph:
            ' You have the right to opt-out of targeted advertising. Opting-out does not necessarily mean that you will stop receiving all ads, it just means that those ads will not be targeted based on your Personal Information.',
        },
        {
          boldTitle: 'Right to Appeal:',
          paragraph:
            ' If we deny your request, and you wish to appeal, you may appeal our decision.',
        },
      ],
      body: [
        {
          bigTitle: 'How to Submit a Request',
          markdownList: [
            `<p>To take advantage of your rights, please contact us by email at <a href="mailto:privacy@claimscore.ai" target="_blank">privacy@claimscore.ai</a> or by phone at <a href="(973) 259-6120" target="_blank">(973) 259-6120</a> to submit your request. We may request certain information about your interactions with us to verify your identity before we can respond to your requests. Where applicable, we will confirm receipt of your request within 10 business days, and we will respond to your request within 45 calendar days, after proper verification, unless we need additional time, in which case we will let you know.</p>`,
            `<p>To take advantage of your right to opt-out of the sale or sharing of Personal Information or to opt-out of targeted advertising, please email us at <a href="mailto:privacy@claimscore.ai" target="_blank">privacy@claimscore.ai</a>. We will work to comply with your opt-out request within 15 business days. You may also use an authorized agent to submit a request to opt-out on your behalf if you provide the authorized agent signed written permission to do so.</p>`,
            `<p>We value your privacy and will not discriminate in response to your exercise of your privacy rights.</p>`,
          ],
        },
        {
          bigTitle: 'Agent Requests',
          paragraph:
            "Subject to applicable law, you may authorize someone to make a privacy rights request on your behalf (an authorized agent). Authorized agents will need to demonstrate that you've authorized them to act on your behalf or must demonstrate they have power of attorney pursuant to applicable probate law. We retain the right to request confirmation directly from you confirming that the agent is authorized to make such a request, or to request additional information to confirm the agent's identity. An authorized agent is prohibited from using Personal Information, or any information collected from or about the consumer, for any purpose other than to fulfill the consumer's requests, for verification, or for fraud prevention.",
        },
      ],
    },
    {
      number: '10.',
      title: 'LOCATIONS',
      paragraph:
        'ClaimScore is based in the United States. The Sites are intended for use only by persons located in the United States. We make no claims that the Sites or any of their content is accessible or appropriate outside of the United States. Access to the Sites may not be legal by certain persons or in certain countries. If you access a Site from outside the United States, you do so on your own initiative, at your sole risk, and you are responsible for compliance with all applicable laws.',
    },
    {
      number: '11.',
      title: 'MODIFICATIONS TO THIS POLICY',
      paragraph:
        'Any changes we may make to this Policy will be posted on this page. Please check back frequently to see any updates or changes to the Policy. If you do not agree or consent to these updates or changes, do not continue to use the Site. If we make a material change to this Policy, we will provide notice to you as required by law.',
    },
    {
      number: '12.',
      title: 'HOW TO CONTACT US',
      markdown: `<p>If you have any questions or concerns about this Policy or the practices described herein, please contact us by email at <a href="mailto:privacy@claimscore.ai" target="_blank">privacy@claimscore.ai</a> or send mail to:</p>`,
      lastBody: [
        'ClaimScore LLC',
        '108 Wanaque Avenue',
        'Pompton Lakes, NJ 07442',
      ],
    },
  ],
};

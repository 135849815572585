import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // componentDidCatch(error, errorInfo) {
    // TODO: add reporting service
  }

  render() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
      return <Navigate to="/" />;
    }
    return this.props.children;
  }
}

export default function WrappedErrorBoundary(props) {
  const navigate = useNavigate();
  return <ErrorBoundary navigate={navigate} {...props} />;
}

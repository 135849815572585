import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { UserInfo } from './types';
import { PATHS } from '../../utils/routes/paths';
import { useCurrentUser, useSnackbar } from '../../store';
import { PhoneInput } from '../../components/phone-input';
import { signUp } from '../../gcp/auth';
import {
  createUser,
  sendEmailVerification,
} from '../../services/users.service';

const validationSchema = Yup.object({
  firstName: Yup.string().max(255).required('First Name is required'),
  lastName: Yup.string().max(255).required('Last Name is required'),
  phone: Yup.string()
    .min(14, 'Must be exactly 10 digits')
    .max(14, 'Must be exactly 10 digits')
    .matches(/\(\d{3}\) \d{3}-\d{4}/, 'Invalid phone number')
    .required('Phone Number is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  companyName: Yup.string().max(255).required('Company Name is required'),
  companyType: Yup.string().max(255).required('Company Type is required'),
  companyDescription: Yup.string()
    .max(255)
    .when('companyType', {
      is: 'Other',
      then: Yup.string()
        .max(255)
        .required('Company Description is required when company type is other'),
    }),
  password: Yup.string().max(255).required('Password is required'),
  acceptedTerms: Yup.boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});

const SignUp = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const [, setCurrentUser] = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleAuthenticationError = (errorMessage: string) => {
    switch (errorMessage) {
      case 'Firebase: Error (auth/email-already-in-use).':
        return 'Email already in use!';
      case 'Firebase: Error (auth/internal-error)':
        return 'An internal error has occurred!';
      case 'Firebase: Error (auth/invalid-email)':
        return 'The email address is badly formatted!';

      default:
        return 'Failed to sign up!';
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      companyType: '',
      companyDescription: '',
      password: '',
      acceptedTerms: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { user } = await signUp(
          formik.values.email,
          formik.values.password
        );
        const userInfo: UserInfo = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          companyName: values.companyName,
          companyType: values.companyType,
          companyTypeDescription: values.companyDescription,
          userId: user.uid,
        };
        await createUser(userInfo);
        await sendEmailVerification(user.uid);
        setCurrentUser(userInfo);
        navigate(PATHS.entryway);
      } catch (e: any) {
        openSnackbar(handleAuthenticationError(e.message), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const disableButton = !(
    formik.values.acceptedTerms &&
    formik.values.firstName &&
    formik.values.lastName &&
    formik.values.email &&
    formik.values.phone &&
    formik.values.companyName &&
    formik.values.companyType &&
    formik.values.companyDescription &&
    formik.values.password &&
    formik.values.acceptedTerms
  );

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="sm">
        <Paper sx={{ padding: '32px 24px' }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 0 }}>
              <Typography color="textPrimary" variant="h4">
                Sign Up
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(
                    formik.touched.firstName && formik.errors.firstName
                  )}
                  fullWidth
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  label="First Name"
                  margin="normal"
                  name="firstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(
                    formik.touched.lastName && formik.errors.lastName
                  )}
                  fullWidth
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  label="Last Name"
                  margin="normal"
                  name="lastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.lastName}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />

            <PhoneInput
              error={Boolean(formik.touched.phone && formik.errors.phone)}
              fullWidth
              helperText={formik.touched.phone && formik.errors.phone}
              label="Phone Number"
              margin="normal"
              name="phone"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.phone}
              variant="outlined"
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(
                    formik.touched.companyName && formik.errors.companyName
                  )}
                  fullWidth
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                  label="Company Name"
                  margin="normal"
                  name="companyName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.companyName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  sx={{ mt: 2 }}
                  error={Boolean(
                    formik.touched.companyType && formik.errors.companyType
                  )}
                >
                  <InputLabel id="company-type">Company Type</InputLabel>
                  <Select
                    labelId="company-type"
                    id="company-type-select"
                    name="companyType"
                    value={formik.values.companyType}
                    label="Company Type"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="Defendant">Defendant</MenuItem>
                    <MenuItem value="Defense Lawyer">Defense Lawyer</MenuItem>
                    <MenuItem value="Admin Company">Admin Company</MenuItem>
                    <MenuItem value="Plaintiff Lawyer">
                      Plaintiff Lawyer
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {Boolean(
                    formik.touched.companyType && formik.errors.companyType
                  ) && (
                    <FormHelperText>
                      {formik.touched.companyType && formik.errors.companyType}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              error={Boolean(
                formik.touched.companyDescription &&
                  formik.errors.companyDescription
              )}
              fullWidth
              helperText={
                formik.touched.companyDescription &&
                formik.errors.companyDescription
              }
              label="Company Description"
              margin="normal"
              name="companyDescription"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.companyDescription}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControl
              fullWidth
              sx={{ mt: 2, ml: 1 }}
              error={Boolean(
                formik.touched.acceptedTerms && formik.errors.acceptedTerms
              )}
            >
              <FormControlLabel
                label={
                  <Typography>
                    I accept the <Link to="/terms">Terms of Use</Link> and{' '}
                    <Link to="/privacy">Privacy Policy</Link>
                  </Typography>
                }
                control={
                  <Checkbox
                    name="acceptedTerms"
                    checked={formik.values.acceptedTerms}
                    onChange={formik.handleChange}
                  />
                }
              />

              {Boolean(
                formik.touched.acceptedTerms && formik.errors.acceptedTerms
              ) && (
                <FormHelperText>
                  {formik.touched.acceptedTerms && formik.errors.acceptedTerms}
                </FormHelperText>
              )}
            </FormControl>

            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={disableButton || loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign Up
              </Button>
            </Box>
            <Box
              sx={{
                py: 2,
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Typography
                color="textSecondary"
                sx={{
                  fontWeight: 'fontWeightBold',
                  color: 'primary.text',
                }}
              >
                Already have an account?
              </Typography>
              <Link to={PATHS.login} style={{ textDecoration: 'none' }}>
                <Typography
                  color="textSecondary"
                  sx={{
                    ml: 1,
                    fontWeight: 'fontWeightBold',
                    color: 'primary.main',
                  }}
                >
                  Sign in
                </Typography>
              </Link>
            </Box>
            {loading && (
              <Box
                sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default SignUp;

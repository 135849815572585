export const ROLES = {
  SuperAdmin: 'SuperAdmin',
  Owner: 'Owner',
  Admin: 'Admin',
  Reviewer: 'Reviewer',
  Viewer: 'Viewer',
};

export const ROLES_VALUES = {
  SuperAdmin: 5,
  Owner: 4,
  Admin: 3,
  Reviewer: 2,
  Viewer: 1,
  None: 0,
};

import React from 'react';
import { Grid, TextField } from '@mui/material';
import TextEditor from '../../text-editor';

export const MarkdownText = ({ list, text, index, onChange, headline }) => {
  return (
    <Grid container spacing={3}>
      {headline && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Section Title (Not Required)"
            name="headline"
            variant="outlined"
            onChange={(value) => {
              const result = [...list];
              result[index].text = value;
              onChange(result);
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sx={{ minHeight: '200px' }}>
        <TextEditor
          value={text}
          setValue={(value) => {
            const result = [...list];
            result[index].text = value;
            onChange(result);
          }}
        />
      </Grid>
    </Grid>
  );
};

import React, { useState, useEffect } from 'react';
import { useCaseId } from './useCaseId';
import { auth } from '../gcp/config';
import { useSelectedCase } from '../store';
import { getUserCasePermission } from '../services/cases.service';
import { isSuperAdmin } from '../gcp/auth';
import { PATHS } from '../utils/routes/paths';

export default function useRole() {
  useCaseId();
  const [loadingRole, setLoadingRole] = useState(true);
  const [userSuperAdmin, setUserSuperAdmin] = useState(false);
  const [userRole, setUserRole] = useState();
  const [error, setError] = useState('');
  const [selectedCase] = useSelectedCase();

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      setLoadingRole(true);
      try {
        if (user?.uid) {
          const userIsSuperAdmin = await isSuperAdmin();
          setUserSuperAdmin(userIsSuperAdmin);
          if (!userIsSuperAdmin && selectedCase.caseID) {
            const { data } = await getUserCasePermission(
              selectedCase.caseID,
              user?.uid
            );

            setUserRole(data.role);
          }
        }
      } catch {
        setError('error');
      } finally {
        setLoadingRole(false);
      }
    });
  }, [selectedCase.caseID]);

  useEffect(() => {
    if (window.location.pathname === PATHS.myCases) {
      setUserRole('None');
    }
  }, [window.location.pathname]);

  return {
    loadingRole,
    userSuperAdmin,
    userRole,
    error,
  };
}

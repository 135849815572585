import useStore from './root';

export const useSelectedCase = () => {
  return [
    useStore((store) => store.selectedCase),
    useStore((store) => store.setSelectedCase),
  ];
};

export const useSelectedCaseAggregatedData = () => [
  useStore((store) => store.selectedCaseAggregatedData),
  useStore((store) => store.setSelectedCaseAggregatedData),
];

export const useSelectedCaseTableRows = () => [
  useStore((store) => store.selectedCaseTableRows),
  useStore((store) => store.setSelectedCaseTableRows),
];

export const useReportFilters = () => [
  useStore((store) => store.reportFilters),
  useStore((store) => store.setReportFilters),
];

export const useFileUploadProgress = () => [
  useStore((store) => store.fileUploadProgress),
  useStore((store) => store.setFileUploadProgress),
];

export const useCaseList = () => [
  useStore((store) => store.caseList),
  useStore((store) => store.setCaseList),
];

export const useTotalCases = () => [
  useStore((store) => store.totalCases),
  useStore((store) => store.setTotalCases),
];

export const useLoadingOverlay = () => [
  {
    showLoadingOverlay: useStore((store) => store.showLoadingOverlay),
    loadingMessage: useStore((store) => store.loadingMessage),
  },
  useStore((store) => store.setLoadingOverlay),
];

export const useSnackbar = () => ({
  openSnackbar: useStore((store) => store.openSnackbar),
  closeSnackbar: useStore((store) => store.closeSnackbar),
  showSnackbar: useStore((store) => store.showSnackbar),
  message: useStore((store) => store.message),
  severity: useStore((store) => store.severity),
});

export const useErrorOverlay = () => [
  {
    showErrorOverlay: useStore((store) => store.showErrorOverlay),
    errorMessage: useStore((store) => store.errorMessage),
  },
  useStore((store) => store.setErrorOverlay),
];

export const usePageSize = () => [
  useStore((store) => store.pageSize),
  useStore((store) => store.setPageSize),
];

export const usePageNumber = () => [
  useStore((store) => store.pageNumber),
  useStore((store) => store.setPageNumber),
];

export const useTotalTableEntriesCount = () => [
  useStore((store) => store.selectedCaseTotalTableEntriesCount),
  useStore((store) => store.setSelectedCaseTotalTableEntriesCounts),
];

export const useCurrentUser = () => [
  useStore((store) => store.currentUser),
  useStore((store) => store.setCurrentUser),
];

export const usePlaintiffID = () => {
  return [
    useStore((store) => store.plaintiffIDToSearch),
    useStore((store) => store.setPlaintiffIDToSearch),
  ];
};

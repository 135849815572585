import { Box, Typography } from '@mui/material';
import React from 'react';
import { FramesBodyTypes } from '../../../views/privacy-policy/types';
import Markdown from '../../markdown';
const FrameBody = (props: FramesBodyTypes) => {
  const {
    bigTitle,
    items,
    boldTitle,
    intro,
    paragraph,
    italicTitle,
    sameLine,
    markdownList,
  } = props;

  return (
    <>
      {bigTitle && (
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '24px',
              fontFamily: 'DM Sans',
              color: '#221F20',
              lineHeight: '100%',
              fontStyle: 'normal',
            }}
          >
            {bigTitle}
          </Typography>
        </Box>
      )}
      {intro && (
        <Box sx={{ mb: 2, display: 'flex' }}>
          <Typography
            sx={{ fontWeight: 400, fontSize: '16px', color: '#221F20' }}
          >
            {intro}
          </Typography>
        </Box>
      )}
      {boldTitle && (
        <Box sx={{ mb: 2, display: 'flex' }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '150%',
              color: '#221F20',
            }}
          >
            <strong>{boldTitle}</strong>
            {sameLine}
          </Typography>
        </Box>
      )}
      {paragraph && (
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{ fontWeight: 400, fontSize: '16px', color: '#221F20' }}
          >
            {paragraph}
          </Typography>
        </Box>
      )}
      {markdownList &&
        markdownList.map((item, index: number) => (
          <Box sx={{ mb: 3 }} key={index}>
            <Markdown source={item} />
          </Box>
        ))}
      {italicTitle && (
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              fontStyle: 'italic',
              lineHeight: '150%',
              color: '#221F20',
            }}
          >
            {italicTitle}
          </Typography>
        </Box>
      )}
      {items && (
        <Box sx={{ mb: 3 }}>
          {items.map((itemBody, index: number) => (
            <Typography sx={{ pl: 3 }} key={index}>
              <ul>
                <li>
                  <Typography
                    sx={{ fontWeight: 400, fontSize: '16px', color: '#221F20' }}
                  >
                    {itemBody}
                  </Typography>
                </li>
              </ul>
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
};

export default FrameBody;

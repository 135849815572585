import { useParams } from 'react-router-dom';
import { useSelectedCase } from '../store';
import * as casesClient from '../services/cases.service';

export function useCaseId() {
  const { caseId } = useParams();
  const [selectedCase, setSelectedCase] = useSelectedCase();

  const getCaseId = async () => {
    if (!caseId) return;
    const { data } = await casesClient.getCase(caseId);
    setSelectedCase(data);
  };

  if (!selectedCase.caseID) getCaseId();
}

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Box, Button, ListItem } from '@mui/material';
import styled from '@emotion/styled';
import { Badge } from '../badge';

export const NavItem = (props) => {
  const { href, icon, title, closeDrawer, inbox, ...others } = props;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
      {...others}
    >
      <NavLink
        to={href}
        style={{ textDecoration: 'none', width: '100%' }}
        onClick={closeDrawer}
      >
        {({ isActive }) => (
          <Button
            startIcon={icon}
            disableRipple
            sx={{
              backgroundColor: isActive && 'rgba(255,255,255, 0.08)',
              color: isActive ? 'primary.light' : 'grey.500',
              fontWeight: isActive && 'fontWeightBold',
              borderRadius: 1,
              justifyContent: 'flex-start',
              px: 3,
              textAlign: 'left',
              textTransform: 'none',
              width: '100%',
              '& .MuiButton-startIcon': {
                color: isActive ? 'primary.light' : 'grey.500',
              },
              '&:hover': {
                backgroundColor: 'rgba(255,255,255, 0.08)',
              },
              '& a': {
                textDecoration: 'none',
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            <Badge count={inbox} />
          </Button>
        )}
      </NavLink>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
};

import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Divider,
  Drawer,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LanguageIcon from '@mui/icons-material/Language';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { doc, onSnapshot } from 'firebase/firestore';
import { NavItem } from './nav-item';
import { Search as SearchIcon } from '../../icons/search';
import { Add as AddIcon } from '../../icons/add';
import { ArrowDown as ArrowDownICon } from '../../icons/arrow-down';
import {
  useCaseList,
  useCurrentUser,
  useSelectedCase,
  useTotalCases,
} from '../../store/selectors';
import { PATHS } from '../../utils/routes/paths';
import { getCasePage } from '../../services/cases.service';
import { getUserCaseRoutes } from '../../services/users.service';
import { StreetView as StreetViewIcon } from '../../icons/street-view';
import { ClipboardList as ClipboardListIcon } from '../../icons/clipboard-list';
import { db } from '../../gcp/config';
import { useCaseId } from '../../hooks/useCaseId';

const items = [
  {
    href: PATHS.caseDashboard,
    icon: <StreetViewIcon fontSize="small" />,
    title: 'Dashboard',
  },
  {
    href: PATHS.caseReports,
    icon: <FolderOpenOutlinedIcon fontSize="small" />,
    title: 'Reports',
  },
  {
    href: PATHS.caseDetails,
    icon: <TextSnippetIcon fontSize="small" />,
    title: 'Case Details',
  },
  {
    href: PATHS.caseNotice,
    icon: <LanguageIcon fontSize="small" />,
    title: 'Notice Website',
  },
  {
    href: PATHS.caseInstructions,
    icon: <ListAltIcon fontSize="small" />,
    title: 'Claim Instructions',
  },
  {
    href: PATHS.caseForm,
    icon: <ClipboardListIcon fontSize="small" />,
    title: 'Claim Form',
  },
  {
    href: PATHS.proofOfPurchase,
    icon: <ClipboardListIcon fontSize="small" />,
    title: 'Pending PoPs',
  },
  {
    href: PATHS.casePermissions,
    icon: <LockOpenIcon fontSize="small" />,
    title: 'Permissions',
  },
];

const SecondarySearchBox = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.08);',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  });

  useCaseId();
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [selectedCase, setSelectedCase] = useSelectedCase();
  const [currentUser] = useCurrentUser();
  const [caseList, setCaseList] = useCaseList();
  const [, setTotalCases] = useTotalCases();
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCaseList, setShowCaseList] = useState(false);
  const [pendingPoPCounter, setPendingPoPCounter] = useState(0);

  const [userCaseRoutes, setUserCaseRoutes] = useState([]);

  const getUserCaseRoutesHandler = useCallback(async () => {
    const currentCaseId = selectedCase.caseID || caseId;
    if (currentUser.userId && currentCaseId) {
      try {
        const { data } = await getUserCaseRoutes(
          currentUser.userId,
          currentCaseId
        );
        // TODO: remove when route added to endpoint
        setUserCaseRoutes([...data.userCasePaths, '/case/:caseId/reports']);
      } catch (error) {
        // do nothing
      }
    }
  }, [selectedCase.caseID, currentUser, caseId]);

  const getPageHandler = useCallback(
    debounce(async (currentPage, filter) => {
      setLoading(true);
      const { data } = await getCasePage(currentPage, filter);
      setTotalCases(data.total);
      setCaseList(data.results);
      setLoading(false);
    }, 500),
    []
  );

  useEffect(() => {
    getUserCaseRoutesHandler();
  }, []);

  useEffect(() => {
    getPageHandler(0, filter);
  }, [filter, getPageHandler]);

  const pendingPoPSubscription = useCallback(() => {
    if (!selectedCase?.caseID) return;
    const docRef = doc(db, 'Inbox', selectedCase?.caseID);
    return onSnapshot(docRef, (doc) => {
      if (doc.data()) {
        setPendingPoPCounter(doc.data().pendingPoP);
      }
    });
  }, [selectedCase?.caseID]);

  useEffect(() => {
    const unsubscribe = pendingPoPSubscription();
    return unsubscribe;
  }, [pendingPoPSubscription]);

  const onSearch = (event) => {
    setFilter(event.target.value);
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div>
        <Box sx={{ px: 2 }}>
          <Button
            sx={{ p: 0, width: '100%' }}
            onClick={() => setShowCaseList(!showCaseList)}
          >
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 2,
                py: '11px',
                width: '100%',
                marginTop: '20px',
                borderRadius: 1,
              }}
            >
              <div>
                <Typography
                  color="white"
                  variant="body"
                  noWrap
                  display="block"
                  overflow="hidden"
                  sx={{
                    fontWeight: '100',
                    width: '130px',
                  }}
                >
                  {selectedCase.caseName}
                </Typography>
                <Typography color="grey.400" variant="body2">
                  Deadline:{' '}
                  {new Date(selectedCase.deadline).toLocaleDateString('en-US')}
                </Typography>
              </div>
              <ArrowDownICon
                sx={{
                  color: 'white',
                  transform: `rotate(${showCaseList ? '-180deg' : 0})`,
                  transition: 'transform 0.5s ease-out',
                }}
              />
            </Box>
          </Button>
        </Box>
      </div>
      {showCaseList && (
        <>
          <Divider
            sx={{
              borderColor: '#2D3748',
              my: 3,
            }}
          />
          <Box sx={{ maxWidth: 300, padding: 2 }}>
            <SecondarySearchBox
              fullWidth
              onChange={onSearch}
              value={filter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              label="Search Case"
              variant="outlined"
              sx={{
                marginBottom: 2,
              }}
            />
            {loading ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              caseList.map((caseDetail) => (
                <Button
                  key={caseDetail.caseID}
                  onClick={() => {
                    onClose();
                    setShowCaseList(false);
                    setSelectedCase(caseDetail);
                    navigate(
                      PATHS.caseDashboard.replace(':caseId', caseDetail.caseID)
                    );
                  }}
                  style={{ textDecoration: 'none' }}
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                    cursor: 'pointer',
                    px: 3,
                    py: '11px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                    borderRadius: 0,
                    ':nth-of-type(1)': {
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px',
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    },
                    ':last-of-type': {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: '12px',
                      borderBottomLeftRadius: '12px',
                    },
                  }}
                >
                  <Typography
                    color="white"
                    variant="subtitle1"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {caseDetail.caseName}
                  </Typography>
                  <Typography color="grey.400" variant="body2">
                    Deadline:{' '}
                    {new Date(caseDetail.deadline).toLocaleDateString('en-US')}
                  </Typography>
                </Button>
              ))
            )}
          </Box>
        </>
      )}
      <Box sx={{ px: 2, marginTop: showCaseList ? 0 : 3 }}>
        <Link to={PATHS.caseNew} style={{ textDecoration: 'none' }}>
          <Button color="primary" variant="contained" sx={{ width: '100%' }}>
            <AddIcon sx={{ marginRight: 1 }} />
            <Typography>Create New Case</Typography>
          </Button>
        </Link>
      </Box>
      <Divider
        sx={{
          borderColor: '#2D3748',
          my: 3,
        }}
      />

      <Box sx={{ flexGrow: 1 }}>
        {items
          .filter((item) => userCaseRoutes.includes(item.href))
          .map((item) => (
            <NavItem
              closeDrawer={onClose}
              key={item.title}
              icon={item.icon}
              href={item.href.replace(':caseId', selectedCase.caseID || caseId)}
              title={item.title}
              inbox={
                item.href === PATHS.proofOfPurchase ? pendingPoPCounter : null
              }
            />
          ))}
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'grey.900',
            color: '#FFFFFF',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'grey.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const TEN_DAYS = 10 * 24 * 60 * 60 * 1000;

export const getDeadlineColor = (deadline) => {
  const date = new Date(deadline).getTime();
  const today = Date.now();

  if (today > date) {
    return 'error';
  }
  if (today > date - TEN_DAYS) {
    return 'warning';
  }
  return null;
};

import React, { useEffect, useState } from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { parseISO } from 'date-fns';
import {
  checkboxes,
  emptyForm,
  handleCurrencyChange,
  mapFormToExportRequest,
  formatClaimantsRequestUnits,
  // rejectionCodesAllSelected,
  rejectionCodesDeselected,
} from './utils';
import { currencyFormatter } from '../../../utils/msw/number-formatter';
import { useLoadingOverlay, useSnackbar } from '../../../store';
import { createReport } from '../../../services/reports.service';
import { Close as CloseIcon } from '../../../icons/close';
const ModalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 'auto', md: '1151px' },
  minWidth: '333px',
  maxHeight: '600px',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px',
  p: 4,
};

export function ReportFilter({
  isOpen,
  handleClose,
  caseID,
  isFilterOnly,
  onApply,
  controlledValues,
}) {
  const [values, setValues] = useState(emptyForm);

  useEffect(() => {
    setValues(controlledValues || emptyForm);
  }, [controlledValues]);

  const [, setLoadingOverlay] = useLoadingOverlay();
  const { openSnackbar } = useSnackbar();

  const [showCategories, setShowCategories] = useState(true);

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false,
  });

  const formatEmptyPayoutAmount = (_values) => {
    return {
      ..._values,
      payout: {
        ..._values.payout,
        min: {
          ..._values.payout.min,
          value: _values.payout.min.value
            ? currencyFormatter.format(_values.payout.min.raw)
            : '',
        },
        max: {
          ..._values.payout.max,
          value: _values.payout.max.value
            ? currencyFormatter.format(_values.payout.max.raw)
            : '',
        },
      },
    };
  };

  const handleCheckBoxChange = (event) => {
    const defaults = { ...checkboxes };
    defaults[event.target.name] = !values.determination[event.target.name];
    setValues({
      ...values,
      determination: defaults,
    });
  };

  const handleCodeSelect = (name) => {
    const currentSelectedCodeValue = values.rejectionCodes[name];
    const currentRejectionValues = { ...rejectionCodesDeselected };
    currentRejectionValues[name] = !currentSelectedCodeValue;
    setValues({
      ...values,
      rejectionCodes: currentRejectionValues,
    });
  };

  const handleNameChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleChange = (event) => {
    const [name, type] = event.target.name.split('.');

    setValues({
      ...values,
      [name]: {
        ...values[name],
        [type]: event.target.value,
      },
    });
  };

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setValues({
      ...values,
      type,
    });
  };

  const handleDateChange = (time, property) => {
    setValues({
      ...values,
      timeFrame: {
        ...values.timeFrame,
        [property]: time ? time.toISOString('en-US') : null,
      },
    });
  };

  const validateBody = (body) => {
    let isBodyValid = true;
    let message = '';
    Object.entries(body).forEach((entry) => {
      const { min, max, start, deadline } = entry[1];

      if (min > max && max !== 0) {
        isBodyValid = false;
        message = `${
          entry[0] === 'scoreRange' ? 'Score Range' : 'Eligible Units'
        }, the minimum can't be greater than the maximum.`;
      }
      if (min < 0 || max < 0) {
        isBodyValid = false;
        message = `${
          entry[0] === 'scoreRange' ? 'Score Range' : 'Eligible Units'
        } can't have negative values.`;
      }
      if (start > deadline) {
        isBodyValid = false;
        message = `The Time Frame can't start after the end date.`;
      }
    });

    return {
      isBodyValid,
      message,
    };
  };

  const requestReport = async (download) => {
    setLoadingOverlay(true);
    try {
      const requestBody = mapFormToExportRequest(values, caseID);

      const { isBodyValid, message } = validateBody(requestBody);

      if (isBodyValid) {
        const { data, headers } = await createReport({
          ...formatClaimantsRequestUnits(requestBody),
          caseID,
        });
        const fileType = headers['content-type'].split(';')[0].split('/')[1];

        handleClose();
      } else {
        openSnackbar(message, 'warning');
      }
    } catch (error) {
      openSnackbar('Failed to request report', 'error');
    } finally {
      setLoadingOverlay(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-export-report"
    >
      <Box sx={ModalBoxStyle}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{ mb: 3, fontSize: { xs: '18px', md: '24px' } }}
            variant="h4"
          >
            {isFilterOnly ? 'Filter Plaintiffs Data' : 'Export Claim Report'}
          </Typography>
          <Button onClick={handleClose} sx={{ mb: 3 }}>
            <CloseIcon />
          </Button>
        </Box>
        {!isFilterOnly && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                type="text"
                label="Report Name"
                name="name"
                onChange={handleNameChange}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Report type
                </InputLabel>
                <Select
                  value={values.type}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Report type"
                  name="type"
                  onChange={handleTypeChange}
                >
                  <MenuItem value={'summary'}>Insight Report</MenuItem>
                  <MenuItem value={'full'}>Raw CSV Data</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Divider sx={{ my: 2 }} />
        <Grid item md={9} xs={12}>
          <FormControl
            component="fieldset"
            sx={{ width: '100%', mb: 1 }}
            variant="standard"
          >
            <FormLabel component="legend">Determination</FormLabel>
            <FormGroup
              sx={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap' }}
            >
              <FormControlLabel
                sx={!isMdUp ? { width: '100%', mb: 1 } : {}}
                control={
                  <Checkbox
                    checked={values.determination.approved}
                    onChange={handleCheckBoxChange}
                    name="approved"
                  />
                }
                label="Approved"
              />
              <FormControlLabel
                sx={!isMdUp ? { width: '100%', mb: 1 } : {}}
                control={
                  <Checkbox
                    checked={values.determination.rejected}
                    onChange={handleCheckBoxChange}
                    name="rejected"
                  />
                }
                label="Rejected"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item md={9} xs={12} sx={{ mb: 3 }}>
          <FormControl
            component="fieldset"
            sx={{ width: '100%' }}
            variant="standard"
          >
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel component="legend">Deduction Codes</FormLabel>
              <Button
                onClick={() => {
                  setShowCategories(!showCategories);
                }}
              >
                {showCategories ? 'Hide' : 'Show'}
              </Button>
            </Box>
            <FormGroup sx={{ display: 'block', marginLeft: -0.4 }}>
              {showCategories &&
                Object.keys(values.rejectionCodes).map((key) => (
                  <Button
                    fullWidth={!isMdUp}
                    key={key}
                    sx={{ margin: 0.5, border: '1px solid #3E8682' }}
                    onClick={() => {
                      handleCodeSelect(key);
                    }}
                    variant={
                      values.rejectionCodes[key] ? 'contained' : 'outlined'
                    }
                  >
                    {key}
                  </Button>
                ))}
            </FormGroup>
          </FormControl>
        </Grid>
        {/* {showCategories && (
          <>
            <Box sx={{ mb: 1, display: "flex", flexWrap: "wrap" }}>
              <Button
                sx={!isMdUp ? { width: "50%" } : {}}
                variant="text"
                onClick={() => {
                  setValues({
                    ...values,
                    rejectionCodes: rejectionCodesAllSelected,
                  });
                }}
              >
                Select All
              </Button>
              <Button
                sx={!isMdUp ? { width: "50%" } : {}}
                variant="text"
                onClick={() => {
                  setValues({
                    ...values,
                    rejectionCodes: rejectionCodesDeselected,
                  });
                }}
              >
                Deselect All
              </Button>
            </Box>
          </>
        )} */}
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={6}>
            <FormLabel component="legend" sx={{ fontSize: { xs: 14, md: 16 } }}>
              Score Range
            </FormLabel>
            <Grid
              container
              sx={{ my: 2 }}
              justifyContent="space-between"
              columnSpacing={2}
            >
              <Grid item sx={{ mb: 2 }} xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Minimum Score"
                  name="scoreRange.min"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 1000,
                    },
                  }}
                  onChange={handleChange}
                  value={values.scoreRange.min}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Maximum Score"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 1000,
                    },
                  }}
                  name="scoreRange.max"
                  onChange={handleChange}
                  value={values.scoreRange.max}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend" sx={{ fontSize: { xs: 14, md: 16 } }}>
              Eligible Units
            </FormLabel>
            <Grid
              container
              sx={{ my: 2 }}
              justifyContent="space-between"
              columnSpacing={2}
            >
              <Grid item sx={{ mb: 2 }} xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Minimum Units"
                  name="eligibleUnits.min"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  onChange={handleChange}
                  value={values.eligibleUnits.min}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Maximum Units"
                  name="eligibleUnits.max"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  onChange={handleChange}
                  value={values.eligibleUnits.max}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend" sx={{ fontSize: { xs: 14, md: 16 } }}>
              Time Frame
            </FormLabel>
            <Grid
              container
              sx={{ my: 2 }}
              justifyContent="space-between"
              columnSpacing={2}
            >
              <Grid item sx={{ mb: 2 }} xs={12} md={6}>
                <MobileDatePicker
                  label="After"
                  name="timeFrame.startDate"
                  inputFormat="MM/dd/yyyy"
                  componentsProps={{
                    actionBar: {
                      actions: ['clear', 'cancel', 'accept'],
                    },
                  }}
                  value={
                    values.timeFrame.start
                      ? parseISO(values.timeFrame.start)
                      : null
                  }
                  onChange={(time) => handleDateChange(time, 'start')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MobileDatePicker
                  label="Before"
                  name="timeFrame.deadline"
                  inputFormat="MM/dd/yyyy"
                  componentsProps={{
                    actionBar: {
                      actions: ['clear', 'cancel', 'accept'],
                    },
                  }}
                  value={
                    values.timeFrame.deadline
                      ? parseISO(values.timeFrame.deadline)
                      : null
                  }
                  onChange={(time) => handleDateChange(time, 'deadline')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend" sx={{ fontSize: { xs: 14, md: 16 } }}>
              Payout Amount
            </FormLabel>
            <Grid
              container
              sx={{ my: 2 }}
              justifyContent="space-between"
              columnSpacing={2}
            >
              <Grid item sx={{ mb: 2 }} xs={12} md={6}>
                <TextField
                  label="Minimum Amount"
                  fullWidth
                  id="payout.min"
                  name="payout.min"
                  onChange={(event) =>
                    handleCurrencyChange(event, 'min', values, setValues)
                  }
                  type="text"
                  value={values.payout.min.value}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Maximum Amount"
                  fullWidth
                  id="payout.max"
                  name="payout.max"
                  type="text"
                  value={values.payout.max.value}
                  onChange={(event) =>
                    handleCurrencyChange(event, 'max', values, setValues)
                  }
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
            flexWrap: 'wrap',
          }}
        >
          {isFilterOnly ? (
            <>
              <Button
                sx={isMdUp ? { marginRight: 3 } : { marginRight: 3, flex: 1 }}
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={isMdUp ? {} : { flex: 1 }}
                variant="contained"
                onClick={() => {
                  const formattedValues = formatEmptyPayoutAmount(values);
                  onApply(formattedValues);
                  handleClose();
                }}
              >
                Apply Filters
              </Button>
            </>
          ) : (
            <>
              <Button
                fullWidth={!isMdUp}
                sx={isMdUp ? { marginRight: 3 } : { marginBottom: 3 }}
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                fullWidth={!isMdUp}
                sx={isMdUp ? { marginRight: 3 } : { marginBottom: 3 }}
                variant="contained"
                onClick={() => {
                  requestReport(false);
                }}
              >
                Save Report
              </Button>
              {/* <Button
                fullWidth={!isMdUp}
                variant="contained"
                onClick={() => {
                  requestReport("PDF", true);
                }}
              >
                Save & Export PDF
              </Button> */}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

import styled from '@emotion/styled';
import { Modal } from '@mui/material';

export const RelativeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 516px;
  height: 420px;
  border-radius: 8px;
  background: #d9d9d9;
`;

export const MainImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

export const NavigationArrow = styled.button`
  position: absolute;
  border: none;
  height: 100%;
  border-radius: 8px;
  width: 120px;
  background: transparent;
  cursor: pointer;
  color: ${(props) => props.theme.palette.background.paper};
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }

  &.left:hover {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  &.right:hover {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const FullScreenModal = styled(Modal)`
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullScreenImage = styled.img`
  max-width: 50%;
  margin: 0 auto;
`;

export const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type NavigationDotsProps = {
  active: boolean;
};

export const NavigationDots = styled.button<NavigationDotsProps>`
  width: 10px;
  height: 10px;
  border: none;
  cursor: pointer;
  margin: 16px 4px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.primary.main
      : props.theme.palette.primary.light};
`;

export const ButtonWrapper = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
`;

import {
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { StyledDataGrid } from '../../components/proof-of-purchase/pop.styles';
import { getUsersList, updateUserStatus } from '../../services/users.service';
import { PaginatedResult } from '../../services/types';
import { Ruling } from '../../components/proof-of-purchase/pop.types';
import { useSnackbar } from '../../store';
import { UserInfo } from '../sign-up/types';

const getTextColor = (determination: Ruling) => {
  if (determination === Ruling.Approved) {
    return 'primary.main';
  }
  if (determination === Ruling.Pending) {
    return 'warning.dark';
  }
  if (determination === Ruling.Rejected) {
    return 'error.light';
  }
};

const AccountsDropdownCell = (props: GridRenderCellParams) => {
  const [previousDetermination, setPreviousDetermination] = useState<Ruling>(
    props.row.determination
  );
  const [determination, setDetermination] = useState<Ruling>();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    if (determination) {
      try {
        updateUserStatus(
          props.row.userID,
          determination,
          previousDetermination
        );
        openSnackbar('User status updated successfully!', 'success');
      } catch (error) {
        openSnackbar('Woops! Something wrong happened', 'error');
      }
    }
  }, [determination]);

  return (
    <Select
      fullWidth
      value={determination || props.row.determination}
      inputProps={{
        sx: {
          p: '8px 12px',
          color: getTextColor(determination || props.row.determination),
        },
      }}
      onChange={(event: SelectChangeEvent<Ruling>) => {
        setPreviousDetermination(determination || props.row.determination);
        setDetermination(event.target.value as Ruling);
      }}
    >
      <MenuItem value={Ruling.Approved} sx={{ color: 'primary.main' }}>
        Approved
      </MenuItem>
      <MenuItem value={Ruling.Pending} sx={{ color: 'warning.dark' }}>
        Pending
      </MenuItem>
      <MenuItem value={Ruling.Rejected} sx={{ color: 'error.light' }}>
        Rejected
      </MenuItem>
    </Select>
  );
};

const columns = [
  {
    field: 'fullName',
    flex: 1,
    filterable: false,
    headerName: 'NAME',
    minWidth: 190,
  },
  {
    field: 'companyName',
    flex: 1,
    filterable: false,
    headerName: 'COMPANY NAME',
    minWidth: 190,
  },

  {
    field: 'companyType',
    flex: 1,
    minWidth: 190,
    filterable: false,
    headerName: 'BUSINESS TYPE',
  },
  {
    field: 'email',
    flex: 1,
    minWidth: 190,
    filterable: false,
    headerName: 'EMAIL',
  },
  {
    field: 'status',
    flex: 1,
    filterable: false,
    headerName: 'STATUS',
    minWidth: 190,
  },
  {
    filterable: false,
    sortable: false,
    flex: 1,
    field: 'determination',
    headerName: 'DETERMINATION ',
    minWidth: 190,
    renderCell: (params: GridRenderCellParams) => (
      <AccountsDropdownCell {...params} />
    ),
  },
];

export interface UserRow {
  userId: string;
  fullName: string;
  firstName: string;
  lastName: string;
  phone: string;
  companyName: string;
  companyType: string;
  email: string;
  companyTypeDescription?: string;
}

export const SuperAdminAccounts = () => {
  const { openSnackbar } = useSnackbar();

  const [rows, setRows] = useState<PaginatedResult<UserInfo | UserRow>>();

  const [statusFilter, setStatusFilter] = useState('all');
  const [determinationFilter, setDeterminationFilter] = useState('all');

  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const handleLimitChange = (size: number) => {
    setPageSize(size);
  };

  const handlePageChange = (number: number) => {
    setPage(number);
  };

  useEffect(() => {
    const determination =
      determinationFilter !== 'all' ? determinationFilter : undefined;
    const status = statusFilter !== 'all' ? statusFilter : undefined;
    getUsersList(page, pageSize, search, determination as Ruling, status)
      .then((response) => {
        const parsedRows: PaginatedResult<UserRow> = {
          results: response.data.results.map((_user) => ({
            ..._user,
            fullName: `${_user.firstName} ${_user.lastName}`,
          })),
          total: response.data.total,
        };
        setRows(parsedRows);
      })
      .catch(() => {
        openSnackbar('Unable to retrieve Accounts List', 'error');
      });
  }, [statusFilter, determinationFilter, search, pageSize, page]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Grid item lg={8} md={6} xs={12}>
          <Typography sx={{ mb: 6 }} variant="h4">
            Accounts
          </Typography>
          <Card sx={{ mb: 3 }}>
            <Stack>
              <Typography sx={{ px: 3, pt: 3 }} variant="h5">
                Accounts Table
              </Typography>
              <Grid container alignItems="center" sx={{ p: 3 }} spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                    label="Search in Accounts"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      value={statusFilter}
                      labelId="status-select-label"
                      id="status-select"
                      label="Status"
                      name="status"
                      onChange={(event: SelectChangeEvent<string>) => {
                        setStatusFilter(event.target.value);
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="pending-approval">
                        Pending Approval
                      </MenuItem>
                      <MenuItem value="pending-email">Pending Email</MenuItem>
                      <MenuItem value="rejected">Rejected</MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="determination-select-label">
                      Determination
                    </InputLabel>
                    <Select
                      value={determinationFilter}
                      labelId="determination-select-label"
                      id="determination-select"
                      label="Determination"
                      name="determination"
                      onChange={(event: SelectChangeEvent<string>) => {
                        setDeterminationFilter(event.target.value);
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value={Ruling.Approved}>Approved</MenuItem>
                      <MenuItem value={Ruling.Pending}>Pending</MenuItem>
                      <MenuItem value={Ruling.Rejected}>Rejected</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Stack>
            <StyledDataGrid
              loading={false}
              disableColumnMenu
              checkboxSelection={false}
              disableSelectionOnClick
              autoHeight
              sortingMode="server"
              paginationMode="server"
              rows={rows?.results || []}
              rowCount={rows?.total || 0}
              columns={columns}
              getRowId={({ userID }) => userID}
              rowsPerPageOptions={[5, 10, 25]}
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              onPageSizeChange={handleLimitChange}
              components={{
                LoadingOverlay: () => null,
              }}
            />
          </Card>
        </Grid>
      </Container>
    </Box>
  );
};

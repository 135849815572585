import React from 'react';
import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { useFileUploadProgress } from '../../store';

export const LoadingOverlay = ({ message, open }) => {
  const [fileProgress] = useFileUploadProgress();
  return (
    <Backdrop
      sx={{
        color: 'background.default',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '40%',
        }}
      >
        {fileProgress ? (
          <CircularProgress
            color="inherit"
            variant="determinate"
            value={fileProgress}
          />
        ) : (
          <CircularProgress color="inherit" />
        )}
        <Typography sx={{ margin: 3 }} color="white">
          {message}
        </Typography>
      </Box>
    </Backdrop>
  );
};

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { inviteNewUser, getCase } from '../../../services/cases.service';
import { useSnackbar, useCurrentUser, useSelectedCase } from '../../../store';
import { Role } from '../types';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
    width: '600px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function StyledDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '24px',
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface InvitationModalProps {
  open: boolean;
  handleClose: () => void;
  onFinish: () => void;
}

export default function InvitationModal({
  open,
  handleClose,
  onFinish,
}: InvitationModalProps) {
  const { caseId } = useParams();
  const [currentUser] = useCurrentUser();
  const [selectedCase, setSelectedCase] = useSelectedCase();
  const { openSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      role: Role.Viewer,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      role: Yup.mixed<Role>()
        .oneOf(Object.values(Role))
        .required('Role is required'),
    }),
    onSubmit: async () => {
      setLoading(true);
      let currentCase = selectedCase;
      if (!selectedCase.caseID && caseId) {
        const { data } = await getCase(caseId);
        setSelectedCase(data);
        currentCase = data;
      }
      try {
        await inviteNewUser({
          caseId: currentCase.caseID,
          caseName: currentCase.caseName,
          senderName: currentUser.email,
          guestEmail: formik.values.email,
          role: formik.values.role as Role,
        });
        openSnackbar('Invitation email sent!', 'success');
        handleClose();
        onFinish();
      } catch (e: any) {
        const errorMessage = e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Failed to send invitation email!';
        openSnackbar(errorMessage, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Invite New
      </StyledDialogTitle>
      <DialogContent dividers>
        <TextField
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="Email"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
          variant="outlined"
          sx={{ mb: 3 }}
        />

        <FormControl fullWidth>
          <InputLabel id="role-select">Role</InputLabel>
          <Select
            labelId="role-select"
            variant="outlined"
            name="role"
            value={formik.values.role}
            label="Role"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <MenuItem value={Role.Admin}>Admin</MenuItem>
            <MenuItem value={Role.Reviewer}>Reviewer</MenuItem>
            <MenuItem value={Role.Viewer}>Viewer</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      {loading ? (
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={formik.submitForm} variant="contained">
            Invite
          </Button>
        </DialogActions>
      )}
    </StyledDialog>
  );
}

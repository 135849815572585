import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './text-editor.scss';
/*
 * See https://zenoamaro.github.io/react-quill/ for playground
 * See https://quilljs.com/docs/ for documentation
 */
const modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    [{ color: [] }, { background: [] }],
    ['link', 'image', 'video'],
  ],
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'background',
  'color',
];

function TextEditor({ value, setValue, label }) {
  return (
    <div className="text-editor-wrapper">
      <span className="text-editor-label">{label}</span>
      <ReactQuill
        modules={modules}
        formats={formats}
        theme="snow"
        value={value}
        onChange={setValue}
      />
    </div>
  );
}

export default TextEditor;

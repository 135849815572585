import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import GroupingTypeSelect from '../grouping-type-select';

export default function TitleWControls({
  title,
  onCopy,
  onDelete,
  titleStyles,
  disabled,
  withTypeSelect,
  typeSelectProps,
}) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} md={6}>
        <Typography
          sx={titleStyles ? titleStyles : { fontWeight: 500, fontSize: '18px' }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack direction="row" spacing={2} justifyContent={{ md: 'flex-end' }}>
          {withTypeSelect && <GroupingTypeSelect {...typeSelectProps} />}
          <IconButton aria-label="delete" disabled={disabled} onClick={onCopy}>
            <ContentCopyIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            disabled={disabled}
            onClick={onDelete}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
}

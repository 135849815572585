export enum PaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Overdue = 'Overdue',
}

export enum CaseStatus {
  None = 'None',
  Revoked = 'Revoked',
  Archived = 'Archived',
  Active = 'Active',
}

export type BillingCase = {
  caseID: string;
  caseName: string;
  caseType: string;
  ownerID: string;
  paymentStatus: PaymentStatus;
  caseStatus: CaseStatus;
  ownerEmail: string;
  ownerName: string;
};

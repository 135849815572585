import {
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { BillingCase, CaseStatus } from './types';
import { StyledDataGrid } from '../../components/proof-of-purchase/pop.styles';
import IntentConfirmationDialog from '../../components/intent-confirmation-dialog';
import {
  getAllBillingCases,
  updateCaseStatus,
} from '../../services/cases.service';
import { PaginatedResult } from '../../services/types';
import { useSnackbar } from '../../store';

const getTextColor = (caseStatus: CaseStatus) => {
  if (caseStatus === CaseStatus.None) {
    return 'text.secondary';
  }
  if (caseStatus === CaseStatus.Archived) {
    return 'compare.primary';
  }
  if (caseStatus === CaseStatus.Revoked) {
    return 'error.light';
  }
  if (caseStatus === CaseStatus.Active) {
    return 'primary.main';
  }
};

const CasesDropdownCell = (props: GridRenderCellParams) => {
  const [caseStatus, setCaseStatus] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { openSnackbar } = useSnackbar();

  const [confirmationText, setConfirmationText] = useState('');

  const _updateCaseStatus = useCallback(async () => {
    try {
      if (caseStatus) {
        updateCaseStatus(props.row.caseID, caseStatus);
        openSnackbar('Case status updated successfully!', 'success');
      }
    } catch (error) {
      openSnackbar('Woops! Something wrong happened', 'error');
    }
  }, [caseStatus, props.row.id]);

  useEffect(() => {
    _updateCaseStatus();
  }, [caseStatus]);

  return (
    <>
      <IntentConfirmationDialog
        open={isModalOpen}
        title="Archive Case Confirmation"
        handleClose={() => setIsModalOpen(false)}
        confirmButtonProps={{
          title: 'Archive',
          disabled: confirmationText !== props.row.caseName,
        }}
        onConfirm={() => {
          setCaseStatus(CaseStatus.Archived);
          _updateCaseStatus();
          setIsModalOpen(false);
        }}
        cancelButtonProps={{ title: 'Cancel' }}
        onCancel={() => setIsModalOpen(false)}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '24px',
          }}
          gutterBottom
        >
          Are you sure you want to archive this case?
        </Typography>
        <Typography gutterBottom>
          Performing this action will take down the notice website, stop the
          ability to file new claims.
        </Typography>
        <Typography gutterBottom>
          {"Type in the case name and press the 'Archive' button to confirm:"}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '24px',
          }}
          gutterBottom
        >
          {props.row.caseName}
        </Typography>
        <TextField
          fullWidth
          placeholder={`Type ${props.row.caseName} in to delete`}
          onChange={(event) => {
            setConfirmationText(event.target.value);
          }}
        />
      </IntentConfirmationDialog>
      <Select
        fullWidth
        value={caseStatus || props.row.caseStatus}
        inputProps={{
          sx: {
            p: '8px 12px',
            color: getTextColor(caseStatus || props.row.caseStatus),
          },
        }}
        onChange={(event: SelectChangeEvent<string>) => {
          if (event.target.value === CaseStatus.Archived) {
            setIsModalOpen(true);
          } else {
            setCaseStatus(event.target.value);
          }
        }}
      >
        <MenuItem value={CaseStatus.None} sx={{ color: 'text.secondary' }}>
          None
        </MenuItem>
        <MenuItem value={CaseStatus.Archived} sx={{ color: 'compare.primary' }}>
          Archived
        </MenuItem>
        <MenuItem value={CaseStatus.Revoked} sx={{ color: 'error.light' }}>
          Revoked
        </MenuItem>
        <MenuItem value={CaseStatus.Active} sx={{ color: 'primary.main' }}>
          Active
        </MenuItem>
      </Select>
    </>
  );
};

const columns = [
  {
    field: 'caseName',
    flex: 1,
    filterable: false,
    headerName: 'CASE',
    minWidth: 190,
  },
  {
    field: 'ownerName',
    flex: 1,
    filterable: false,
    headerName: 'OWNER',
    minWidth: 190,
  },

  {
    field: 'ownerEmail',
    flex: 1,
    minWidth: 190,
    filterable: false,
    headerName: 'EMAIL',
  },
  {
    field: 'caseType',
    flex: 1,
    minWidth: 190,
    filterable: false,
    headerName: 'CASE TYPE',
  },
  {
    field: 'paymentStatus',
    flex: 1,
    filterable: false,
    headerName: 'PAYMENT STATUS',
    minWidth: 190,
  },
  {
    filterable: false,
    sortable: false,
    flex: 1,
    field: 'caseStatus',
    headerName: 'CASE STATUS ',
    minWidth: 190,
    renderCell: (params: GridRenderCellParams) => (
      <CasesDropdownCell {...params} />
    ),
  },
];

export const SuperAdminCases = () => {
  const { openSnackbar } = useSnackbar();
  const [rows, setRows] = useState<PaginatedResult<BillingCase>>();

  const [caseTypeFilter, setCaseTypeFilter] = useState('all');
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('all');

  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const handleLimitChange = (size: number) => {
    setPageSize(size);
  };

  const handlePageChange = (number: number) => {
    setPage(number);
  };

  useEffect(() => {
    const caseType = caseTypeFilter !== 'all' ? caseTypeFilter : undefined;
    const paymentStatus =
      paymentStatusFilter !== 'all' ? paymentStatusFilter : undefined;

    getAllBillingCases(page, pageSize, search, caseType, paymentStatus)
      .then((response) => {
        setRows(response.data);
      })
      .catch(() => {
        openSnackbar('Unable to retrieve Cases List', 'error');
      });
  }, [caseTypeFilter, paymentStatusFilter, search, page, pageSize]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Grid item lg={8} md={6} xs={12}>
          <Typography sx={{ mb: 6 }} variant="h4">
            Cases
          </Typography>
          <Card sx={{ mb: 3 }}>
            <Stack>
              <Typography sx={{ px: 3, pt: 3 }} variant="h5">
                Cases Table
              </Typography>
              <Grid container alignItems="center" sx={{ p: 3 }} spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                    label="Search in Cases"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="case-type-select-label">
                      Case Type
                    </InputLabel>
                    <Select
                      value={caseTypeFilter}
                      labelId="case-type-select-label"
                      id="case-type-select"
                      label="Case Type"
                      name="case-type"
                      onChange={(event: SelectChangeEvent<string>) => {
                        setCaseTypeFilter(event.target.value);
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="consumer-fraud">Consumer Fraud</MenuItem>
                      <MenuItem value="privacy-violation">
                        Privacy Violation
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="payment-status-select-label">
                      Payment Status
                    </InputLabel>
                    <Select
                      value={paymentStatusFilter}
                      labelId="payment-status-select-label"
                      id="payment-status-select"
                      label="Payment Status"
                      name="payment-status"
                      onChange={(event: SelectChangeEvent<string>) => {
                        setPaymentStatusFilter(event.target.value);
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="archived">Archived</MenuItem>
                      <MenuItem value="revoked">Revoked</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Stack>
            <StyledDataGrid
              loading={false}
              disableColumnMenu
              checkboxSelection={false}
              disableSelectionOnClick
              autoHeight
              sortingMode="server"
              paginationMode="server"
              rows={rows?.results || []}
              rowCount={rows?.total || 0}
              columns={columns}
              getRowId={({ caseID }) => caseID}
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              onPageSizeChange={handleLimitChange}
              rowsPerPageOptions={[5, 10, 25]}
              components={{
                LoadingOverlay: () => null,
              }}
            />
          </Card>
        </Grid>
      </Container>
    </Box>
  );
};

import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function StyledDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '24px',
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface ButtonInterface {
  title: string;
  disabled?: boolean;
}

interface IntentConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  onConfirm: () => void;
  confirmButtonProps: ButtonInterface;
  onCancel: () => void;
  cancelButtonProps: ButtonInterface;
  children?: React.ReactNode;
}

export default function IntentConfirmationDialog({
  open,
  handleClose,
  title,
  children,
  onConfirm,
  confirmButtonProps,
  onCancel,
  cancelButtonProps,
}: IntentConfirmationDialogProps) {
  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </StyledDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={onConfirm}
          disabled={confirmButtonProps.disabled}
          variant="contained"
          color="error"
        >
          {confirmButtonProps.title}
        </Button>
        <Button fullWidth onClick={onCancel} variant="outlined">
          {cancelButtonProps.title}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

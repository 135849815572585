import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';

interface ProgressBannerProps {
  title: string;
  fileName: string;
  description: string;
  progress?: number;
}

export const ProgressBanner = ({
  title,
  fileName,
  description,
}: ProgressBannerProps) => {
  return (
    <Card sx={{ width: '100%', mt: 3 }}>
      <CardHeader title={title} />
      <Divider />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          In Progress: {fileName}
        </Typography>
        <Typography gutterBottom>{description}</Typography>
        {/* <Typography variant="h5" gutterBottom>
          {progress.toFixed(0)}% Completed
        </Typography>
        <LinearProgress variant="determinate" value={progress} /> */}
      </CardContent>
    </Card>
  );
};

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import SelectionGroup from '../selection-group';
import TextEditor from '../text-editor';
import { MarkdownText } from '../markdown-text/markdown-text';

const mainForm = [
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Middle Initial',
    value: 'middleInitial',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Country',
    value: 'country',
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'Address 2',
    value: 'address2',
  },
  {
    label: 'City',
    value: 'city',
  },
  {
    label: 'State',
    value: 'state',
  },
  {
    label: 'Zip Code',
    value: 'zipCode',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Email',
    value: 'email',
  },
];

const popForm = [
  {
    label: 'File Upload',
    value: 'fileUpload',
  },
];

const uidForm = [
  {
    label: '10-Digit Alphanumeric Code',
    value: 'uniqueID',
  },
];

function ElementItem({
  type,
  text,
  to,
  group,
  options,
  list,
  index,
  onChange,
}) {
  switch (type) {
    case 'form':
      return (
        <Grid container>
          {mainForm.map((input) => (
            <Grid item xs={6} md={2} key={input.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={list[index].inputs.includes(input.value)}
                    onChange={({ target }) => {
                      const result = [...list];
                      const inputsCopy = [...list[index].inputs];
                      if (target.checked) {
                        inputsCopy.push(input.value);
                      } else {
                        inputsCopy.splice(inputsCopy?.indexOf(input.value), 1);
                      }
                      result[index].inputs = inputsCopy;
                      onChange(result);
                    }}
                  />
                }
                label={input.label}
              />
            </Grid>
          ))}
        </Grid>
      );
    case 'fileUpload':
      return (
        <Grid container>
          {popForm.map((input) => (
            <Grid item xs={4} key={input.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={list[index].inputs.includes(input.value)}
                    onChange={({ target }) => {
                      const result = [...list];
                      const inputsCopy = [...list[index].inputs];
                      if (target.checked) {
                        inputsCopy.push(input.value);
                      } else {
                        inputsCopy.splice(inputsCopy?.indexOf(input.value), 1);
                      }
                      result[index].inputs = inputsCopy;
                      onChange(result);
                    }}
                  />
                }
                label={input.label}
              />
            </Grid>
          ))}
        </Grid>
      );
    case 'uniqueID':
      return (
        <Grid container>
          {uidForm.map((input) => (
            <Grid item xs={4} key={input.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={list[index].inputs.includes(input.value)}
                    onChange={({ target }) => {
                      const result = [...list];
                      const inputsCopy = [...list[index].inputs];
                      if (target.checked) {
                        inputsCopy.push(input.value);
                      } else {
                        inputsCopy.splice(inputsCopy?.indexOf(input.value), 1);
                      }
                      result[index].inputs = inputsCopy;
                      onChange(result);
                    }}
                  />
                }
                label={input.label}
              />
            </Grid>
          ))}
        </Grid>
      );
    case 'selection':
      return (
        <>
          <MarkdownText
            list={list}
            text={text}
            index={index}
            onChange={onChange}
            headline
          />
          <SelectionGroup
            groupingType={group}
            options={options}
            handleChange={(newOptions) => {
              const result = [...list];
              result[index].options = newOptions;
              onChange(result);
            }}
          />
        </>
      );
    case 'markdown':
      return (
        <MarkdownText
          list={list}
          text={text}
          index={index}
          onChange={onChange}
          headline
        />
      );
    case 'reminder':
      return (
        <MarkdownText
          list={list}
          text={text}
          index={index}
          onChange={onChange}
        />
      );
    case 'button':
      return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              type="text"
              label={`Button Label`}
              fullWidth
              value={text}
              onChange={({ target }) => {
                const result = [...list];
                result[index].text = target.value;
                onChange(result);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Button Link</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={to}
                label="Button Link"
                onChange={({ target }) => {
                  const result = [...list];
                  result[index].to = target.value;
                  onChange(result);
                }}
              >
                <MenuItem value={'/'}>Claim Home</MenuItem>
                <MenuItem value={'/claim-instructions'}>
                  Claim Instructions
                </MenuItem>
                <MenuItem value={'/claim-form'}>Claim Form</MenuItem>
                <MenuItem value={'/contact'}>Contact</MenuItem>
                <MenuItem value={'/faqs'}>Frequently Asked Questions</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      );
    case 'divider':
      return null;
    default:
      throw new Error(`Unexpected Element: ${type}`);
  }
}

export default ElementItem;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid } from '@mui/material';
import CaseDetailsForm from '../../components/case-details/case-details-form';

const CaseDetails = ({ newCase = false }) => (
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >
    <Container maxWidth="lg">
      <Grid item lg={8} md={6} xs={12}>
        <CaseDetailsForm newCase={newCase} />
      </Grid>
    </Container>
  </Box>
);

CaseDetails.propTypes = {
  newCase: PropTypes.bool,
};

export default CaseDetails;

import React from 'react';
import styled from '@emotion/styled';

const StyledBadge = styled('div')`
  background-color: #dd5c4f;
  border-radius: 100%;
  color: #ffffff;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex: 1;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  line-height: 24px;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  animation: bounce 1s ease 2;
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20%);
    }
    60% {
      transform: translateY(-10%);
    }
  }
`;

interface BadgeProps {
  count: number;
  leftMargin?: string;
  rightMargin?: string;
}

export const Badge = ({
  count,
  leftMargin = '0px',
  rightMargin = '0px',
}: BadgeProps) => {
  if (!count) {
    return null;
  }
  return (
    <StyledBadge style={{ marginLeft: leftMargin, marginRight: rightMargin }}>
      {count >= 9 ? '9+' : count}
    </StyledBadge>
  );
};

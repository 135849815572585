import Axios from 'axios';
import { authenticateInterceptor } from './interceptors';
import { getAuthToken } from '../gcp/auth';

const pipelineClient = Axios.create({
  baseURL: `${process.env.REACT_APP_PIPELINE_SERVICE_URL}/pipeline`,
});

export const initializeTemplateDeployment = (requestBody) =>
  pipelineClient.post('', requestBody);

pipelineClient.interceptors.request.use(authenticateInterceptor);

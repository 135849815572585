import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import { TotalUnits } from './insights/total-units';
import { ApprovedPlaintiffs } from './insights/approved-plaintiffs';
import { TotalProfit } from './insights/total-profit';

export const CaseListResults = () => (
  <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xl={4} lg={4} sm={4} xs={12}>
            <ApprovedPlaintiffs />
          </Grid>
          <Grid item xl={4} lg={4} sm={4} xs={12}>
            <TotalUnits />
          </Grid>
          <Grid item xl={4} lg={4} sm={4} xs={12}>
            <TotalProfit sx={{ height: '100%' }} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

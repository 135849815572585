import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/system';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { LoadingFullPage } from '../../components/loading-fullpage';
import { isClaimScoreApproved, verifyEmail } from '../../gcp/auth';
import { PATHS } from '../../utils/routes/paths';
import { useSnackbar } from '../../store';
import { updateUserEmailVerifiedStatus } from '../../services/users.service';

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [startTimer, setStartTimer] = useState(false);
  const [seconds, setSeconds] = useState(6);
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    if (!location.state.actionCode) {
      openSnackbar('No verification code provided', 'error');
      navigate(PATHS.login);
      return;
    }
    verifyEmail(location.state.actionCode)
      .then(async () => {
        // Email address has been verified.
        await updateUserEmailVerifiedStatus(true, location.state.userId);
        const adminApproved = await isClaimScoreApproved();
        if (!adminApproved) {
          navigate(PATHS.entryway);
        } else {
          setStartTimer(true);
          setLoading(false);
        }
      })
      .catch(() => {
        openSnackbar('Invalid or expired code', 'error');
        navigate(PATHS.entryway);
      });
  }, []);

  useEffect(() => {
    if (!startTimer) return;
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        return prevSeconds - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [startTimer]);

  useEffect(() => {
    if (seconds <= 0) {
      navigate(PATHS.myCases);
    }
  }, [seconds]);

  return loading ? (
    <LoadingFullPage />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Stack alignItems="center" sx={{ maxWidth: '600px' }}>
        <GridCheckCircleIcon color="primary" sx={{ fontSize: 48, mr: 2 }} />

        <Typography sx={{ mt: 3 }} variant="h1" textAlign="center">
          Email Verification Successful!
        </Typography>
        <Typography sx={{ mt: 3 }} variant="body2" textAlign="center">
          {`You'll be automatically redirected to your ClaimScore dashboard, if that doesn't happen you may click the link
          to `}
          <Link to={PATHS.myCases}>go to your dashboard.</Link>
        </Typography>
        <Typography
          sx={{ mt: 3, color: 'text.secondary' }}
          variant="body2"
          textAlign="center"
        >
          Redirected in {seconds} seconds...
        </Typography>
      </Stack>
    </Box>
  );
};

export default EmailVerification;

import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Alert from '../../components/alert';
import { PATHS } from '../../utils/routes/paths';
import { sendPasswordResetEmail } from '../../services/users.service';

const PasswordRecovery = () => {
  const [loading, setLoading] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [isEmailSent, setEmailSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    }),
    onSubmit: async () => {
      setLoading(true);
      try {
        await sendPasswordResetEmail(formik.values.email);
        setEmailSent(true);
      } catch (e) {
        setShowAlert(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="sm">
        <Paper sx={{ padding: '32px 24px' }}>
          <form onSubmit={formik.handleSubmit}>
            <Alert
              message="Invalid email!"
              type="error"
              setOpen={setShowAlert}
              open={showAlert}
            />
            <Box sx={{ my: 3 }}>
              <Typography color="textPrimary" variant="h4">
                Password Recovery
              </Typography>
            </Box>
            {isEmailSent ? (
              <Typography>
                Check your inbox for a password recovery mail!
              </Typography>
            ) : (
              <>
                <TextField
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={formik.isSubmitting || loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Send Email
                  </Button>
                </Box>
              </>
            )}
            <Link to={PATHS.login} style={{ textDecoration: 'none' }}>
              <Typography
                color="textSecondary"
                variant="body2"
                sx={{
                  fontWeight: 'fontWeightBold',
                  color: 'primary.main',
                }}
              >
                Back to sign in
              </Typography>
            </Link>
            {loading && (
              <Box
                sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
              >
                <CircularProgress />
              </Box>
            )}
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default PasswordRecovery;

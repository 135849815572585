export const PATHS = {
  myCases: '/',
  terms: '/terms',
  privacyPolicy: '/privacy',
  login: '/login',
  pageNotFound: '*',
  signUp: '/sign-up',
  auth: '/_auth',
  entryway: '/entryway',
  emailVerification: '/email-verification',
  forgotPassword: '/forgot-password',
  passwordReset: '/password-reset',

  caseDashboard: '/case/:caseId/dashboard',
  caseDetails: '/case/:caseId/details',
  caseNew: '/case/new',
  casePermissions: '/case/:caseId/permissions',
  caseReports: '/case/:caseId/reports',

  caseNotice: '/case/:caseId/notice',
  caseForm: '/case/:caseId/form',
  caseInstructions: '/case/:caseId/instructions',

  proofOfPurchase: '/case/:caseId/proof-of-purchase-claims',
  proofOfPurchaseDetails: '/case/:caseId/proof-of-purchase-claims/:claimId',

  superAdminAccounts: '/admin/accounts',
  superAdminCases: '/admin/users',
};

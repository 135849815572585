import React from 'react';
import { Box, Container } from '@mui/material';
import { CaseListTable } from '../../components/case-dashboard/case-list-table';
import { CaseListToolbar } from '../../components/case-dashboard/case-list-toolbar';
import { CaseListResults } from '../../components/case-dashboard/case-list-results';
import { CaseListFilters } from '../../components/case-dashboard/case-list-filters';
import { useSelectedCaseTableRows, useLoadingOverlay } from '../../store';
import { LoadingOverlay } from '../../components/loading-overlay/loading-overlay';
// import { ProgressBanner } from '../../components/progress-banner';

function CaseDashboard() {
  const [selectedCaseTableRows] = useSelectedCaseTableRows();
  const [loadingOverlay] = useLoadingOverlay();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <LoadingOverlay open={loadingOverlay.showLoadingOverlay} />
      <Container maxWidth={false}>
        <CaseListToolbar />
        {/* // TODO: Integrate with uploader */}
        {/* <ProgressBanner
          title="Importing Claim CSV"
          fileName={'test.csv'}
          description="Your file has been received and the claims are currently being reviewed, you will also receive an email notification once the claim review process for this file has been completed."
          progress={69}
        /> */}
        <CaseListResults />
        <CaseListFilters />
        <Box sx={{ mt: 3 }}>
          <CaseListTable rows={selectedCaseTableRows} />
        </Box>
      </Container>
    </Box>
  );
}

export default CaseDashboard;

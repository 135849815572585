import React, { useState, useEffect } from 'react';
import { auth } from '../gcp/config';
import { useCurrentUser } from '../store';
import { getUserInfo } from '../services/users.service';
import { isEmailVerified, isClaimScoreApproved } from '../gcp/auth';

export default function useAuth() {
  const [loadingAuthentication, setLoadingAuthentication] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useCurrentUser();
  const [userApproved, setUserApproved] = useState(false);
  const [userEmailVerified, setUserEmailVerified] = useState(false);

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      setAuthenticated(Boolean(user?.uid));
      if (Boolean(user?.uid) && !currentUser?.email) {
        const { data } = await getUserInfo();
        const userEmailVerifiedStatusData = await isEmailVerified();
        const userApprovedStatusData = await isClaimScoreApproved();

        setUserEmailVerified(userEmailVerifiedStatusData);
        setUserApproved(userApprovedStatusData);
        setCurrentUser(data.user);
      }
      setLoadingAuthentication(false);
    });
  }, [currentUser.email]);

  return {
    loadingAuthentication,
    authenticated,
    userApproved,
    userEmailVerified,
  };
}

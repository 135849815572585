import { checkboxes, rejectionCodesDeselected } from './report-filter/utils';
import useStore from '../../store/root';
import * as jobOrchestratorClient from '../../services/job-orchestrator.service';
import * as casesClient from '../../services/cases.service';
const {
  selectedCase,
  pageSize,
  pageNumber,
  setFileUploadProgress,
  setErrorOverlay,
  setLoadingOverlay,
  setSelectedCaseAggregatedData,
  setSelectedCaseTotalTableEntriesCounts,
  setSelectedCaseTableRows,
} = useStore.getState();

export const onSnapshotChange = () => (snapshot) => {
  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  setFileUploadProgress(progress);
};

export const onUploadFail = () => () => {
  setErrorOverlay(
    true,
    'Oops. There was a technical error while uploading the file. Please contact tech support.'
  );
  setFileUploadProgress(null);
  setLoadingOverlay(false);
};

const dataFetch = async () => {
  if (!selectedCase.caseID) return;
  const requests = [
    casesClient.getAllPlaintiffs(selectedCase.caseID, pageNumber, pageSize),
    casesClient.getCaseAggregatedDetails(selectedCase.caseID),
  ];
  const [{ data: plaintiffsData }, { data: aggregatedDetails }] =
    await Promise.all(requests);
  setSelectedCaseAggregatedData(aggregatedDetails.results);
  setSelectedCaseTableRows(plaintiffsData.results);
  setSelectedCaseTotalTableEntriesCounts(plaintiffsData.total || 0);
};

const requestProcessedData = async () => {
  try {
    setLoadingOverlay(true, 'Almost done!');
    await dataFetch();
    setLoadingOverlay(false);
  } catch (error) {
    setErrorOverlay(
      true,
      'There was a technical error while requesting the data. Please contact tech support.'
    );
    setLoadingOverlay(false);
  }
};

const createDataTransferJob = async (file, caseID) => {
  try {
    setLoadingOverlay(true, 'Transferring file data 💻🚀');
    await jobOrchestratorClient.createDataTransfer({
      file,
      bucket: `gs://${process.env.REACT_APP_GCP_STORAGE_BUCKET}`,
      caseID,
    });
    requestProcessedData();
  } catch (error) {
    setErrorOverlay(
      true,
      'There was a technical error while transferring the data. Please contact tech support.'
    );
    setLoadingOverlay(false);
  }
};

export const onUploadComplete = (fileRoute, caseID) => async () => {
  setFileUploadProgress(null);
  createDataTransferJob(fileRoute, caseID);
};

function getDetermination(determinationList) {
  if (Object.values(determinationList).every(Boolean)) {
    return null;
  }
  if (Object.values(determinationList).every((value) => !value)) {
    return null;
  }
  if (determinationList.approved) return 'Approved';
  if (determinationList.rejected) return 'Rejected';
}

export const mapExportValuesToFilterTags = (exportValues) => {
  const dateStart = exportValues.timeFrame.start
    ? new Date(exportValues.timeFrame.start)
    : null;
  const dateEnd = exportValues.timeFrame.deadline
    ? new Date(exportValues.timeFrame.deadline)
    : null;

  return {
    Determination: getDetermination(exportValues.determination),
    'Rejection Codes': Object.entries(exportValues.rejectionCodes)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .join(' - '),
    'Min Score': exportValues.scoreRange.min,
    'Max Score': exportValues.scoreRange.max,
    'Min Units': exportValues.eligibleUnits.min,
    'Max Units': exportValues.eligibleUnits.max,
    'Date Range': `${dateStart?.toLocaleDateString() || ''}${
      dateStart && dateEnd ? ' - ' : ''
    }${dateEnd?.toLocaleDateString() || ''}`,
    'Min Payout': exportValues.payout.min.value,
    'Max Payout': exportValues.payout.max.value,
  };
};

export const getEmptyFormPieceByLabel = (prevState, label) => {
  const emptyFormPieceByLabel = {
    Determination: {
      determination: checkboxes,
    },
    'Date Range': {
      timeFrame: {
        start: null,
        deadline: null,
      },
    },
    'Rejection Codes': {
      rejectionCodes: rejectionCodesDeselected,
    },
    'Min Score': {
      scoreRange: {
        ...prevState.scoreRange,
        min: '',
      },
    },
    'Max Score': {
      scoreRange: {
        ...prevState.scoreRange,
        max: '',
      },
    },
    'Min Units': {
      eligibleUnits: {
        ...prevState.eligibleUnits,
        min: '',
      },
    },
    'Max Units': {
      eligibleUnits: {
        ...prevState.eligibleUnits,
        max: '',
      },
    },
    'Min Payout': {
      payout: {
        ...prevState.payout,
        min: {
          value: '',
          raw: '',
        },
      },
    },
    'Max Payout': {
      payout: {
        ...prevState.payout,
        max: {
          value: '',
          raw: '',
        },
      },
    },
  };
  return emptyFormPieceByLabel[label];
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { theme } from './theme';
import App from './App';
import 'react-loading-skeleton/dist/skeleton.css';

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./utils/msw/browser');
//   worker.start();
// }

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

import * as React from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

const DraggableList = React.memo(function DraggableList({
  children,
  onDragEnd,
  droppableId,
}) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;

import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import { Divider } from '@mui/material';
import './markdown.css';
import { MarkdownTypes } from './types';

function Markdown(props: MarkdownTypes) {
  const { source, style, components } = props;

  return (
    <div className="markdown-container">
      <MDEditor.Markdown
        style={{
          whiteSpace: 'pre-wrap',
          background: 'transparent',
          color: '#221F20',
          ...style,
        }}
        components={{
          a: (props) => (
            <a
              style={{ color: '#3E8682', textDecoration: 'underline' }}
              href={props.href}
            >
              {props.children}
            </a>
          ),
          hr: () => <Divider />,
          ...components,
        }}
        source={source}
      />
    </div>
  );
}

export default Markdown;

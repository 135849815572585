import React, { useEffect, useState } from 'react';
import { GridColumns, GridSortItem, GridValidRowModel } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { Paper } from '@mui/material';
import { StyledDataGrid } from './pop.styles';
import { PoPRow } from './pop.types';
import {
  currencyFormatter,
  numberFormatter,
} from '../../utils/msw/number-formatter';
import { useLoadingOverlay, useSelectedCase } from '../../store';
import { getPoPClaimsList } from '../../services/pop.service';

const columns: GridColumns<GridValidRowModel> = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    maxWidth: 330,
    minWidth: 160,
    filterable: false,
  },
  {
    field: 'name',
    flex: 1,
    filterable: false,
    headerName: 'NAME',
    maxWidth: 145,
    minWidth: 145,
  },
  {
    field: 'purchaseDate',
    flex: 1,
    filterable: false,
    headerName: 'PURCHASE DATE',
    maxWidth: 145,
    minWidth: 145,
  },

  {
    field: 'quantity',
    flex: 1,
    maxWidth: 140,
    minWidth: 130,
    filterable: false,
    headerName: 'QTY',
  },
  {
    field: 'payout',
    flex: 1,
    maxWidth: 140,
    minWidth: 130,
    filterable: false,
    headerName: 'PAYOUT',
    valueGetter: ({ row }) =>
      currencyFormatter.format(Number(row.payout) / 100 || 0) || '-',
  },
  {
    field: 'score',
    flex: 1,
    filterable: false,
    headerName: 'SCORE',
    valueGetter: ({ row }) => numberFormatter.format(row.score),
    maxWidth: 90,
    minWidth: 90,
  },
  {
    filterable: false,
    sortable: false,
    flex: 1,
    field: 'rejectionCodes',
    headerName: 'CODES',
    valueGetter: ({ row }) =>
      row.rejectionCodes?.length > 0 ? row.rejectionCodes.join(', ') : '-',
    minWidth: 200,
  },
];

type PoPListTableProps = {
  type: 'completed' | 'pending';
  selectableRow?: boolean;
  onSelectRow?: (rows: PoPRow[]) => void;
};

export const PoPListTable = ({
  type,
  selectableRow,
  onSelectRow,
}: PoPListTableProps) => {
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [selectedCase] = useSelectedCase();
  const [rows, setRows] = useState<PoPRow[]>([]);
  const [totalTableEntriesCount, setTotalTableEntriesCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState<GridSortItem>({ field: '', sort: null });
  const [loadingOverlay, setLoadingOverlay] = useLoadingOverlay();

  const handleLimitChange = (size: number) => {
    setPageSize(size);
  };

  const handlePageChange = (number: number) => {
    setPage(number);
  };

  useEffect(() => {
    const getPoPPage = async () => {
      setLoadingOverlay(true);
      let caseID: string = selectedCase.caseID || caseId;

      const { data: popData } = await getPoPClaimsList(
        type,
        caseID,
        page,
        pageSize,
        sort?.field,
        sort.sort
      );
      setRows(popData.results || []);
      setTotalTableEntriesCount(popData.total || 0);

      setLoadingOverlay(false);
    };
    getPoPPage();
  }, [page, pageSize, selectedCase.caseID, sort?.field, sort?.sort, type]);

  return (
    <Paper
      sx={{
        overflow: 'auto',
        minHeight: loadingOverlay.showLoadingOverlay ? '600px' : 0,
      }}
    >
      <StyledDataGrid
        loading={loadingOverlay.showLoadingOverlay}
        disableColumnMenu
        checkboxSelection={selectableRow}
        disableSelectionOnClick
        onRowClick={(context) => {
          navigate(
            `/case/${selectedCase.caseID}/proof-of-purchase-claims/${context.row.id}`
          );
        }}
        onSelectionModelChange={(rowIds) => {
          if (onSelectRow) {
            onSelectRow(rows.filter((row) => rowIds.includes(row.id)));
          }
        }}
        autoHeight
        sortingMode="server"
        paginationMode="server"
        rows={!loadingOverlay.showLoadingOverlay ? rows : []}
        rowCount={totalTableEntriesCount}
        columns={columns}
        getRowId={({ id }) => id}
        pageSize={pageSize}
        onSortModelChange={([sort]) => {
          if (sort) {
            setSort({ field: sort.field, sort: sort.sort });
          } else {
            setSort({ field: '', sort: null });
          }
        }}
        sortModel={[{ field: sort.field ? sort.field : '', sort: sort.sort }]}
        rowsPerPageOptions={[5, 10, 25]}
        onPageChange={handlePageChange}
        page={page}
        onPageSizeChange={handleLimitChange}
        components={{
          LoadingOverlay: () => <div></div>,
        }}
      />
    </Paper>
  );
};

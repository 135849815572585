import { GridSortDirection } from '@mui/x-data-grid';
import Axios from 'axios';
import { authenticateInterceptor } from './interceptors';
import { PoPRow } from '../components/proof-of-purchase/pop.types';
import toQueryParams from '../utils/to-query-params';

const popClient = Axios.create({
  baseURL: `${process.env.REACT_APP_POP_SERVICE_URL}`,
});

export const getPoPClaimsList = (
  type: 'completed' | 'pending',
  caseID: string,
  page: number,
  pageSize: number,
  sortBy: string,
  order: GridSortDirection
) =>
  popClient.get(
    `/pop/${caseID}/claims?${toQueryParams({
      page,
      pageSize,
      sortBy,
      order,
      type,
    })}`
  );

export const getPoPClaim = (caseId: string, claimId: string) =>
  popClient.get(`/pop/${caseId}/claims/${claimId}`);

export const updatePoPClaim = (
  caseId: string,
  claimId: string,
  payload: PoPRow
) => popClient.put(`/pop/${caseId}/claims/${claimId}`, payload);

// INTERCEPTORS
popClient.interceptors.request.use(authenticateInterceptor);

import { TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteCaseReport } from '../../../services/reports.service';
import IntentConfirmationDialog from '../../../components/intent-confirmation-dialog';
import { useSnackbar } from '../../../store';

interface DeleteModalProps {
  open: boolean;
  reports: any[];
  handleClose: () => void;
  onFinish: () => void;
}

export const DeleteModal = ({
  open,
  handleClose,
  reports,
  onFinish,
}: DeleteModalProps) => {
  const { caseId } = useParams();
  const { openSnackbar } = useSnackbar();

  const [confirmationMessage, setConfirmationMessage] = useState('');
  const isMultiple = reports.length > 1;
  const expectedConfirmationMessage = isMultiple ? 'Delete' : reports[0]?.name;

  const handleConfirm = async () => {
    if (!caseId) return;
    const promises = reports.map((report) =>
      deleteCaseReport(caseId, report.id)
    );
    try {
      await Promise.all(promises);
      openSnackbar(
        `Report${isMultiple ? 's' : ''} deleted successfully`,
        'success'
      );
      onFinish();
      handleClose();
    } catch (error) {
      openSnackbar(`Failed to delete report${isMultiple ? 's' : ''}`, 'error');
    }
  };

  return (
    <IntentConfirmationDialog
      open={open}
      handleClose={handleClose}
      title={
        isMultiple
          ? 'Report Delete Confirmation'
          : 'Bulk Report Delete Confirmation'
      }
      onCancel={handleClose}
      onConfirm={handleConfirm}
      confirmButtonProps={{
        title: 'Delete',
        disabled: confirmationMessage !== expectedConfirmationMessage,
      }}
      cancelButtonProps={{
        title: 'Cancel',
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '24px',
        }}
        gutterBottom
      >
        {isMultiple
          ? 'Are you sure you want to delete these reports?'
          : 'Are you sure you want to delete this report?'}
      </Typography>
      <Typography gutterBottom>
        {isMultiple
          ? 'Performing this action will remove these reports from the history, if you need them again you must create new ones'
          : `Type in the report's name and press the delete button to confirm:`}
      </Typography>
      {isMultiple ? (
        <Typography gutterBottom>
          {"Type in 'Delete' and click the button to confirm:"}
        </Typography>
      ) : (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '24px',
          }}
          gutterBottom
        >
          {reports[0]?.name}
        </Typography>
      )}

      <TextField
        fullWidth
        placeholder={`Type ${
          isMultiple ? 'Delete' : reports[0]?.name
        } in to delete`}
        onChange={(event) => {
          setConfirmationMessage(event.target.value);
        }}
      />
    </IntentConfirmationDialog>
  );
};

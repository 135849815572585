import React from 'react';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { People as PeopleIcon } from '../../../icons/people';
import {
  useLoadingOverlay,
  useSelectedCaseAggregatedData,
} from '../../../store';
import { numberFormatter } from '../../../utils/msw/number-formatter';

export const ApprovedPlaintiffs = (props) => {
  const [selectedCaseAggregatedData] = useSelectedCaseAggregatedData();
  const [loadingOverlay] = useLoadingOverlay();
  return (
    <Card {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">
              APPROVED PLAINTIFFS
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {loadingOverlay.showLoadingOverlay ? (
                <Skeleton />
              ) : (
                numberFormatter.format(
                  selectedCaseAggregatedData.approvedPlaintiffs || 0
                )
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: 'warning.dark',
                height: 56,
                width: 56,
              }}
            >
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        {/* <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2,
          }}
        >
          <ArrowUpwardIcon color="success" />
          <Typography
            variant="body2"
            sx={{
              mr: 1,
            }}
          >
            16%
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Since last month
          </Typography>
        </Box> */}
      </CardContent>
    </Card>
  );
};

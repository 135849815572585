import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const StyledTypography = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontSize: '96px',
  letterSpacing: '-2px',
  alignSelf: 'stretch',
  width: '552px',
  height: '96px',
}));

const Error404 = () => {
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Stack alignItems="center" sx={{ maxWidth: '600px' }}>
        <StyledTypography>404</StyledTypography>
        <Typography
          sx={{ mt: 3 }}
          color="textPrimary"
          variant="h1"
          textAlign="center"
        >
          Page Not Found.
        </Typography>
        <Typography sx={{ mt: 3 }} variant="body2" textAlign="center">
          {`We couldn’t find the page you requested, or it does not`}
        </Typography>
        <Typography variant="body2" textAlign="center">
          {`exist. Make sure you have the correct URL.`}
        </Typography>
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <Typography
            color="textSecondary"
            variant="body2"
            sx={{
              color: 'primary.main',
              ml: 1,
              mt: 3,
              fontWeight: 700,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Go to the Homepage
              <ArrowForwardIcon sx={{ alignItems: 'center', p: '0px' }} />
            </Box>
          </Typography>
        </Link>
      </Stack>
    </Box>
  );
};

export default Error404;

import Axios from 'axios';
import { authenticateInterceptor } from './interceptors';
import toQueryParams from '../utils/to-query-params';

const reportsClient = Axios.create({
  baseURL: `${process.env.REACT_APP_REPORTS_SERVICE_URL}/reports`,
});

export const createReport = (requestBody: any) =>
  reportsClient.post('', requestBody);

export const getCaseReportsHistory = (
  caseID: string,
  pageSize: number,
  cursor?: string
) => reportsClient.get(`?${toQueryParams({ caseID, pageSize, cursor })}`);

// TODO: integrate
export const deleteCaseReport = (caseId: string, reportId: string) =>
  new Promise((res) => res({ caseId, reportId }));

reportsClient.interceptors.request.use(authenticateInterceptor);

import React, { useState, useEffect } from 'react';
import { Button, Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  GridColumns,
  GridSelectionModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Tooltip from '@mui/material/Tooltip';
import { PoPDetailCard } from './pop-detail-card';
import {
  CollapsibleHeader,
  CollapsibleTitle,
  ColoredPinFilled,
  ColoredPinOutlined,
  FlexBox,
  RulingWrapper,
  SelectedRowDot,
  StyledDataGrid,
} from '../pop.styles';
import * as casesClient from '../../../services/cases.service';
import { useLoadingOverlay, useSelectedCase } from '../../../store';
import { PATHS } from '../../../utils/routes/paths';
import { PoPCardControl, Ruling } from '../pop.types';
import { mockedRows } from '../pop.fixtures';
import { currencyFormatter } from '../../../utils/msw/number-formatter';
import { getPoPClaim, updatePoPClaim } from '../../../services/pop.service';

const getRuling = (ruling: Ruling) => {
  switch (ruling) {
    case Ruling.Pending:
      return (
        <RulingWrapper sx={{ color: 'warning.dark' }}>
          <PendingOutlinedIcon sx={{ mr: 2 }} />
          Pending
        </RulingWrapper>
      );
    case Ruling.Approved:
      return (
        <RulingWrapper sx={{ color: 'primary.main' }}>
          <CheckCircleOutlinedIcon sx={{ mr: 2 }} />
          Approved
        </RulingWrapper>
      );
    case Ruling.Rejected:
      return (
        <RulingWrapper sx={{ color: 'error.main' }}>
          <CancelOutlinedIcon sx={{ mr: 2 }} />
          Rejected
        </RulingWrapper>
      );
  }
};

const columns: GridColumns<GridValidRowModel> = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    maxWidth: 330,
    minWidth: 160,
    filterable: false,
    renderCell: ({ row, value }) => {
      return (
        <Tooltip title="Double click to pin">
          <RulingWrapper>
            {row.isPinned ? (
              <ColoredPinFilled color={row.color} />
            ) : (
              <ColoredPinOutlined color={row.color} />
            )}
            <SelectedRowDot color={row.color} />
            {`${value.substr(0, 10)}...`}
          </RulingWrapper>
        </Tooltip>
      );
    },
  },
  {
    field: 'name',
    flex: 1,
    filterable: false,
    headerName: 'NAME',
    maxWidth: 145,
    minWidth: 145,
  },
  {
    field: 'purchaseDate',
    flex: 1,
    filterable: false,
    headerName: 'PURCHASE DATE',
    maxWidth: 145,
    minWidth: 145,
  },

  {
    field: 'quantity',
    flex: 1,
    maxWidth: 140,
    minWidth: 130,
    filterable: false,
    headerName: 'QTY',
  },
  {
    field: 'payout',
    flex: 1,
    maxWidth: 140,
    minWidth: 130,
    filterable: false,
    headerName: 'PAYOUT',
    valueGetter: ({ row }) =>
      currencyFormatter.format(Number(row.payoutDue) / 100 || 0) || '-',
  },
  {
    field: 'ruling',
    flex: 1,
    maxWidth: 140,
    minWidth: 130,
    filterable: false,
    headerName: 'RULING',
    renderCell: ({ value }) => getRuling(value),
  },
];

function ProofOfPurchaseClaims() {
  const { caseId, claimId } = useParams();
  const navigate = useNavigate();
  const [selectedCase, setSelectedCase] = useSelectedCase();

  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
  const [rows, setRows] = useState<PoPCardControl[]>([]);
  const [loadingOverlay, setLoadingOverlay] = useLoadingOverlay();
  const [pinnedIndex, setPinnedIndex] = useState(0);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);

  useEffect(() => {
    setRows((prevRows) => {
      const rowsCopy = [...prevRows];
      for (let item of rowsCopy) {
        delete item.color;
        delete item.isPinned;
      }

      const firstSelectedItem = rowsCopy.find(
        (row) => row.id === selectedRows[0]
      );
      if (firstSelectedItem) {
        firstSelectedItem.color = 'primary';
      }
      const secondSelectedItem = rowsCopy.find(
        (row) => row.id === selectedRows[1]
      );
      if (secondSelectedItem) {
        secondSelectedItem.color = 'secondary';
      }
      const pinnedElement = rowsCopy[pinnedIndex];
      if (pinnedElement) {
        pinnedElement.isPinned = true;
      }
      return rowsCopy;
    });
  }, [selectedRows, pinnedIndex]);

  useEffect(() => {
    const getPoPClaimDuplicates = async () => {
      setLoadingOverlay(true);
      let caseID: string = selectedCase.caseID;
      if (!caseID && caseId) {
        const { data } = await casesClient.getCase(caseId);
        setSelectedCase(data);
      }
      if (caseId && claimId) {
        const { data } = await getPoPClaim(caseId, claimId);
        const parsedRows = [data, ...data.similarClaims].map((row) => {
          row.readOnly = row.ruling !== Ruling.Pending;
          return row;
        });
        setRows(parsedRows || []);
        setSelectedRows([data.id, data.similarClaims[0]?.id]);
      }
      setLoadingOverlay(false);
    };
    getPoPClaimDuplicates();
    if (mockedRows.length > 1)
      setSelectedRows([mockedRows[0]?.id, mockedRows[1]?.id]);
  }, []);

  const validateRows = (rows: PoPCardControl[]) => {
    const pendingRows = rows.filter((row) => row.ruling === Ruling.Pending);
    if (pendingRows.length > 0) return setIsSubmitDisable(true);
    const rejectedRows = rows.filter((row) => row.ruling === Ruling.Rejected);
    const allHasReason = rejectedRows.every((row) =>
      Object.values(row.reason).some(Boolean)
    );

    if (!allHasReason) return setIsSubmitDisable(true);
    setIsSubmitDisable(false);
  };

  const handleChange = (index: number) => (newValue: PoPCardControl) => {
    setRows((prevRows) => {
      const copyRows = [...prevRows];
      copyRows[index] = newValue;
      validateRows(copyRows);
      return copyRows;
    });
  };

  const handleSubmit = async () => {
    setLoadingOverlay(true);
    if (caseId && claimId) {
      await Promise.all(
        rows.map((row) => {
          return updatePoPClaim(caseId, row.id, row);
        })
      );
      navigate(PATHS.proofOfPurchase.replace(':caseId', caseId));
    }
  };

  return (
    <>
      <Box
        sx={{
          m: -1,
        }}
      >
        <Link
          to={PATHS.proofOfPurchase.replace(':caseId', caseId || '')}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="text" sx={{ p: 0, mb: 3, color: 'grey.500' }}>
            <ArrowBackIcon sx={{ marginRight: 1 }} />
            <Typography>Pending PoPs</Typography>
          </Button>
        </Link>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignContent: 'center',
            mb: 6,
          }}
        >
          <Typography variant="h4">Proof Of Purchase Details</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitDisable}
          >
            <Typography>Submit</Typography>
          </Button>
        </Box>
        {rows.length > 1 && (
          <Card sx={{ mb: 3 }}>
            <CollapsibleHeader>
              <CollapsibleTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <WarningIcon color="warning" sx={{ mr: 2, fontSize: 22 }} />
                Duplicate Warning
              </CollapsibleTitle>
            </CollapsibleHeader>
            <StyledDataGrid
              loading={loadingOverlay.showLoadingOverlay}
              disableColumnMenu
              checkboxSelection={false}
              disableSelectionOnClick
              onRowClick={(context) => {
                setSelectedRows((prevSelected) => {
                  if (prevSelected.includes(context.row.id))
                    return prevSelected;
                  if (pinnedIndex === 0) {
                    return [prevSelected[0], context.row.id];
                  }
                  return [context.row.id, prevSelected[1]];
                });
                navigate(
                  `/case/${selectedCase.caseID}/proof-of-purchase-claims/${context.row.id}`
                );
              }}
              onRowDoubleClick={({ row: selectedRow }) => {
                setPinnedIndex(
                  rows.findIndex((row) => row.id === selectedRow.id)
                );
              }}
              autoHeight
              rows={!loadingOverlay.showLoadingOverlay ? rows : []}
              columns={columns}
              getRowId={({ id }) => id}
              getRowClassName={({ row }) =>
                row.color ? `PoP-Details__row-highlight--${row.color}` : ''
              }
              components={{
                LoadingOverlay: () => <div></div>,
              }}
            />
          </Card>
        )}
        <FlexBox>
          <PoPDetailCard
            isPinned={selectedRows[0] === rows[pinnedIndex]?.id}
            color="primary"
            values={rows.find((row) => row.id === selectedRows[0])}
            setValues={handleChange(
              rows.findIndex((row) => row.id === selectedRows[0])
            )}
            readOnly={rows.find((row) => row.id === selectedRows[0])?.readOnly}
          />
          {rows.length > 1 && (
            <PoPDetailCard
              isPinned={selectedRows[1] === rows[pinnedIndex]?.id}
              color="secondary"
              values={rows.find((row) => row.id === selectedRows[1])}
              setValues={handleChange(
                rows.findIndex((row) => row.id === selectedRows[1])
              )}
              readOnly={
                rows.find((row) => row.id === selectedRows[1])?.readOnly
              }
            />
          )}
        </FlexBox>
      </Box>
    </>
  );
}

export default ProofOfPurchaseClaims;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { SuperAdminMenu } from './superadmin-menu';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { useCurrentUser } from '../../store';
import { signOut, isSuperAdmin as getIsSuperAdmin } from '../../gcp/auth';
import { PATHS } from '../../utils/routes/paths';
import { ClaimScore } from '../../icons/claim-score';
import { db } from '../../gcp/config';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, withSidebar, ...other } = props;

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [accountsInbox, setAccountsInbox] = useState(0);
  const [casesInbox, setCasesInbox] = useState(0);

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false,
  });

  const [currentUser] = useCurrentUser();

  const superAdminSubscription = useCallback(() => {
    if(isSuperAdmin){
      const docRef = doc(db, 'Inbox', 'superAdmin');
      return onSnapshot(docRef, (doc) => {
        if (doc.data()) {
          setAccountsInbox(doc.data().accounts);
          setCasesInbox(doc.data().cases);
        }
      });
    }
  }, [isSuperAdmin]);

  useEffect(() => {
    const unsubscribe = superAdminSubscription();
    return unsubscribe;
  }, [superAdminSubscription]);

  useEffect(() => {
    const getConfirmations = async () => {
      const superAdminResponse = await getIsSuperAdmin();
      setIsSuperAdmin(superAdminResponse);
    };
    getConfirmations();
  }, []);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: withSidebar ? 280 : 0,
          },
          width: {
            lg: withSidebar ? 'calc(100% - 280px)' : '100%',
          },
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
            boxShadow:
              ' 0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
          }}
        >
          {withSidebar && (
            <IconButton
              onClick={onSidebarOpen}
              sx={{
                display: {
                  xs: 'inline-flex',
                  lg: 'none',
                },
              }}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          )}
          {isMdUp && (
            <Box sx={{ height: '23px', ml: 2 }}>
              <ClaimScore />
            </Box>
          )}
          {isMdUp && (
            <Link style={{ textDecoration: 'none' }} to={PATHS.myCases}>
              <Button
                sx={{ marginLeft: 4, display: 'flex', alignItems: 'center' }}
              >
                <HomeOutlinedIcon sx={{ marginRight: 1, color: '#141516' }} />
                <Typography
                  sx={{ color: 'text.primary', fontWeight: 400, fontSize: 16 }}
                >
                  My Cases
                </Typography>
              </Button>
            </Link>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Typography sx={{ color: 'gray' }}>
            {currentUser.companyName}
          </Typography>
          <Box
            sx={{
              color: 'gray',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              outline: 'none',
              border: 'none',
            }}
          >
            {!isMdUp && (
              <Link style={{ textDecoration: 'none' }} to={PATHS.myCases}>
                <Button
                  sx={{ marginLeft: 4, display: 'flex', alignItems: 'center' }}
                >
                  <HomeOutlinedIcon sx={{ marginRight: 1, color: '#141516' }} />
                </Button>
              </Link>
            )}
            <UserCircleIcon
              sx={{ marginLeft: 1 }}
              fontSize="small"
              title={currentUser.email}
            />
            {isMdUp && (
              <Typography sx={{ margin: '0 10px', color: 'gray' }}>
                {currentUser.email}
              </Typography>
            )}
            {isSuperAdmin ? (
              <SuperAdminMenu
                accountsInbox={accountsInbox}
                casesInbox={casesInbox}
              />
            ) : (
              <Button
                onClick={signOut}
                sx={{ margin: '0 10px', color: '#141516' }}
              >
                {isMdUp ? 'Sign out' : <LogoutIcon />}
              </Button>
            )}
          </Box>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

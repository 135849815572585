import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Replay';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { PATHS } from '../../utils/routes/paths';
import { LoadingOverlay } from '../../components/loading-overlay/loading-overlay';
import {
  isEmailVerified,
  isClaimScoreApproved,
  getUserId,
  signOut,
} from '../../gcp/auth';
import { useSnackbar, useLoadingOverlay } from '../../store';
import { sendEmailVerification } from '../../services/users.service';

const Entryway = () => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [adminApproved, setAdminApproved] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useLoadingOverlay();

  const refresh = () => window.location.reload();

  useEffect(() => {
    const getConfirmations = async () => {
      const emailConfirmedResponse = await isEmailVerified();
      const adminApprovedResponse = await isClaimScoreApproved();
      setEmailConfirmed(emailConfirmedResponse);
      setAdminApproved(adminApprovedResponse);
    };
    getConfirmations();
  }, []);

  useEffect(() => {
    // shouldn't go through here if everything is approved
    // This covers and edge case, where the user might be on the entryway when the admin approved them and they already validated their email.
    if (emailConfirmed && adminApproved) {
      navigate(PATHS.myCases);
    }
  }, [emailConfirmed, adminApproved]);

  const handleAuthenticationError = (errorMessage: string) => {
    switch (errorMessage) {
      case 'Firebase: Error (auth/too-many-requests).':
        return 'Too many attempts!';

      default:
        return 'Failed to send the email!';
    }
  };

  const handleResendLink = async () => {
    setLoadingOverlay(true);
    try {
      const userId = getUserId();
      await sendEmailVerification(userId);

      openSnackbar('Email sent', 'success');
    } catch (e: any) {
      openSnackbar(handleAuthenticationError(e.message), 'error');
    } finally {
      setLoadingOverlay(false);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100vh',
      }}
    >
      <LoadingOverlay open={loadingOverlay.showLoadingOverlay} />
      <Stack>
        <Card sx={{ mb: 3, width: '600px' }}>
          <CardHeader
            title="Email Verification"
            sx={{ fontSize: '24px', p: '24px' }}
          />
          <Divider />
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {emailConfirmed ? (
                <>
                  <CheckCircleIcon
                    color="primary"
                    sx={{ fontSize: 48, mr: 2 }}
                  />
                  <Typography variant="h5">Confirmed</Typography>
                </>
              ) : (
                <>
                  <RemoveCircleIcon
                    color="warning"
                    sx={{ fontSize: 48, mr: 2 }}
                  />
                  <Typography variant="h5">Pending Confirmation</Typography>
                </>
              )}
            </Box>
            {!emailConfirmed && (
              <>
                <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
                  <Typography>
                    {"Haven't received our confirmation email?"}
                  </Typography>
                  <Button
                    variant="text"
                    onClick={handleResendLink}
                    sx={{ ml: 1 }}
                  >
                    Resend Link
                  </Button>
                </Box>
                <Typography>
                  If you received the email and you are already approved by
                  ClaimScore, you can close this window and continue the process
                  on the link send to your email.
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
        <Card sx={{ width: '600px' }}>
          <CardHeader
            title="ClaimScore Verification"
            sx={{ fontSize: '24px', p: '24px' }}
          />
          <Divider />
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {adminApproved ? (
                <>
                  <CheckCircleIcon
                    color="primary"
                    sx={{ fontSize: 48, mr: 2 }}
                  />
                  <Typography variant="h5">Approved</Typography>
                </>
              ) : (
                <>
                  <RemoveCircleIcon
                    color="warning"
                    sx={{ fontSize: 48, mr: 2 }}
                  />
                  <Typography variant="h5">Pending Approval</Typography>
                </>
              )}
            </Box>
            {!adminApproved && (
              <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
                <Typography>
                  ClaimScore reviews each sign-up request. You will receive an
                  email notification once we have completed our review. If you
                  have any questions, please <Link to="#">Contact Us</Link>.
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            pt: 3,
          }}
        >
          <Button variant="text" onClick={refresh} sx={{ ml: 1 }}>
            <Typography sx={{ pr: 1 }}>Refresh</Typography>
            <RefreshIcon />
          </Button>
          <Button variant="text" onClick={signOut}>
            <Typography
              sx={{ color: (theme) => theme.palette.error.light, pr: 1 }}
            >
              Sign Out
            </Typography>
            <LogoutIcon sx={{ color: (theme) => theme.palette.error.light }} />
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Entryway;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import millify from 'millify';
import { useNavigate } from 'react-router-dom';
import { getDeadlineColor } from './utils';
import { People as PeopleIcon } from '../../../icons/people';
import { Clock as ClockIcon } from '../../../icons/clock';
import { Tag as TagIcon } from '../../../icons/tag';
import { Currency as CurrencyIcon } from '../../../icons/currency';
import { numberFormatter } from '../../../utils/msw/number-formatter';
import { PATHS } from '../../../utils/routes/paths';
import { useSelectedCase } from '../../../store';

export const CaseCard = ({ caseDetails }) => {
  const [, setSelectedCase] = useSelectedCase();
  const navigate = useNavigate();

  return (
    <Button
      disableElevation
      onClick={() => {
        setSelectedCase(caseDetails);
        navigate(PATHS.caseDashboard.replace(':caseId', caseDetails.caseID));
      }}
      style={{ textDecoration: 'none', padding: 0, width: '100%' }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          cursor: 'pointer',
          boxShadow: '1px 1px 1px 0px rgba(100, 116, 139, 0.12)',
          '&:hover': {
            boxShadow: '4px 4px 4px 0px rgba(100, 116, 139, 0.12)',
          },
          padding: 2,
        }}
      >
        <CardContent sx={{ padding: 0, width: '100%' }}>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {caseDetails.caseName}
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="body1"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            Case No. {caseDetails.caseNumber} | {caseDetails.stateID}
          </Typography>
          <Grid
            container
            spacing={3}
            sx={{
              justifyContent: 'space-around',
              margin: 0,
              width: '100%',
              marginTop: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                width: '110px',
              }}
            >
              <PeopleIcon sx={{ color: 'warning.dark' }} />
              <Typography
                color="textSecondary"
                sx={{ fontWeight: 500, fontSize: '18px' }}
              >
                {numberFormatter.format(caseDetails.approvedPlaintiffs || 0)}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{ fontWeight: 400, fontSize: '12px' }}
              >
                Approved Plaintiffs
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                width: '110px',
              }}
            >
              <TagIcon sx={{ color: 'warning.light' }} />
              <Typography
                color="textSecondary"
                sx={{ fontWeight: 500, fontSize: '18px' }}
              >
                {numberFormatter.format(caseDetails.eligibleUnits || 0)}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{ fontWeight: 400, fontSize: '12px' }}
              >
                Total Eligible Units
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                width: '110px',
              }}
            >
              <CurrencyIcon sx={{ color: 'primary.main' }} />
              <Typography
                color="textSecondary"
                sx={{ fontWeight: 500, fontSize: '18px' }}
              >
                {millify(caseDetails.totalPayout / 100 || 0, {
                  precision: 2,
                })}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{ fontWeight: 400, fontSize: '12px' }}
              >
                Payout Due
              </Typography>
            </Box>
          </Grid>
        </CardContent>

        <Divider sx={{ mt: 2 }} />
        <Box sx={{ p: 2, mt: 1 }}>
          <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
            <ClockIcon
              color={getDeadlineColor(caseDetails.deadline) || 'action'}
            />
            <Typography
              color={
                getDeadlineColor(caseDetails.deadline)
                  ? `${getDeadlineColor(caseDetails.deadline)}.main`
                  : 'textSecondary'
              }
              display="inline"
              sx={{ pl: 1, opacity: 0.7 }}
              variant="body2"
            >
              Deadline:{' '}
              {new Date(caseDetails.deadline).toLocaleDateString('en-US')}
            </Typography>
          </Grid>
        </Box>
      </Card>
    </Button>
  );
};

CaseCard.propTypes = {
  caseDetails: PropTypes.object.isRequired,
};

import React from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { PATHS } from '../../utils/routes/paths';
import useAuth from '../../hooks/useAuth';
import useRole from '../../hooks/useRole';
import Error403 from '../error403';
import { ROLES_VALUES } from '../../utils/roles';

const AuthWall = ({ children, skipRedirect, minimumRole = 'None' }) => {
  const {
    loadingAuthentication,
    authenticated,
    userApproved,
    userEmailVerified,
  } = useAuth();

  const { loadingRole, userSuperAdmin, userRole, error } = useRole();

  if (loadingRole || loadingAuthentication) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!authenticated) {
    return <Navigate to={PATHS.login} />;
  }

  if (!skipRedirect && (!userApproved || !userEmailVerified)) {
    return <Navigate to={PATHS.entryway} />;
  }

  if (
    !userSuperAdmin &&
    ROLES_VALUES[userRole ?? 'None'] < ROLES_VALUES[minimumRole]
  ) {
    return <Error403 />;
  }

  return children;
};

export default AuthWall;

import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const ArrowDown = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="m7 10 5 5 5-5z"></path>
  </svg>,
  'ArrowDown'
);

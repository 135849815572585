export enum ReportType {
  'summary' = 'summary',
}

export type Report = {
  id: string;
  createdOn: string;
  name: string;
  type: ReportType;
  dateStart: string;
  dateEnd: string;
  pdf: string;
  csv: string;
};

export type ReportInProgress = {
  fileName: string;
  status: string;
};

export type ReportFilters = {
  type?: string;
  rangeStart?: string | null;
  rangeEnd?: string | null;
};

export enum ReportJobStatus {
  Processing = 'Processing',
  Success = 'Success',
  Error = 'Error',
}

import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateUserRole } from '../../../services/cases.service';
import { useSnackbar } from '../../../store';
import { Role } from '../types';

export const RoleDropdown = (props: GridRenderCellParams) => {
  const { caseId } = useParams();
  const [role, setRole] = useState<Role>();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    const updateUser = async () => {
      if (role && caseId) {
        try {
          await updateUserRole(caseId, props.row.userEmail, role);
          openSnackbar('User role updated successfully!', 'success');
        } catch (error) {
          openSnackbar('Woops! Something wrong happened', 'error');
        }
      }
    };
    updateUser();
  }, [role]);

  if (props.row.userRole === Role.Owner) {
    return <Typography>Owner</Typography>;
  }

  return (
    <Select
      fullWidth
      value={role || props.row.userRole}
      inputProps={{ sx: { p: '8px 12px' } }}
      onChange={(event: SelectChangeEvent<Role>) => {
        setRole(event.target.value as Role);
      }}
    >
      <MenuItem value={Role.Admin}>Admin</MenuItem>
      <MenuItem value={Role.Reviewer}>Reviewer</MenuItem>
      <MenuItem value={Role.Viewer}>Viewer</MenuItem>
    </Select>
  );
};

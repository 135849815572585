import {
  Button,
  Card,
  CardContent,
  Divider,
  Modal,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { elementTypes } from '../../utils/generate-element';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', md: '620px' },
  boxShadow: 24,
};

function AddElementModal({ open, onSelect, onClose, ignoreComponent = [] }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={modalStyle}>
        <Typography variant="h4" sx={{ p: 3 }}>
          Add Web Element
        </Typography>
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            {elementTypes
              .filter(({ type }) => !ignoreComponent.includes(type))
              .map(({ Icon, title, type }) => (
                <Button
                  key={type}
                  sx={{
                    boxShadow: 2,
                    color: '#6B7280',
                    fontSize: '18px',
                    justifyContent: 'flex-start',
                  }}
                  startIcon={Icon()}
                  onClick={() => {
                    onSelect(type);
                    onClose();
                  }}
                >
                  {title}
                </Button>
              ))}
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}

export default AddElementModal;

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword, signIn, verifyPasswordReset } from '../../gcp/auth';
import { PATHS } from '../../utils/routes/paths';
import { useSnackbar } from '../../store';
import { LoadingFullPage } from '../../components/loading-fullpage';

const PasswordReset = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>();

  const { openSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      if (email && values.password) {
        try {
          await resetPassword(location.state.actionCode, values.password);
          await signIn(email, values.password);
          navigate(PATHS.myCases);
        } catch (error) {
          openSnackbar(
            'The code might have expired or the password is too weak',
            'error'
          );
        }
      }
    },
  });

  useEffect(() => {
    if (!location.state.actionCode) {
      openSnackbar('No verification code provided', 'error');
      navigate(PATHS.login);
      return;
    }
    const verifyCode = async () => {
      try {
        const email = await verifyPasswordReset(location.state.actionCode);
        setEmail(email);
        setLoading(false);
      } catch (error) {
        openSnackbar('Invalid or expired code', 'error');
      }
    };
    verifyCode();
  }, []);

  return loading ? (
    <LoadingFullPage />
  ) : (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="sm">
        <Paper sx={{ padding: '32px 24px' }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 0 }}>
              <Typography color="textPrimary" variant="h4">
                Reset your Password
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ my: 1 }}>
              Enter a new password for <strong>{email}</strong>
            </Typography>
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              InputLabelProps={{
                shrink: true,
              }}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting || loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Save new Password
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default PasswordReset;

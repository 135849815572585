import { TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import IntentConfirmationDialog from '../../../components/intent-confirmation-dialog';
import { deleteUserFromCase } from '../../../services/cases.service';
import { useSnackbar } from '../../../store';
import { UserRole } from '../types';

interface DeleteUsersModalProps {
  open: boolean;
  users: UserRole[];
  handleClose: () => void;
  onFinish: () => void;
}

export const DeleteUsersModal = ({
  open,
  handleClose,
  users,
  onFinish,
}: DeleteUsersModalProps) => {
  const { caseId } = useParams();
  const { openSnackbar } = useSnackbar();

  const [confirmationMessage, setConfirmationMessage] = useState('');
  const isMultiple = users.length > 1;
  const expectedConfirmationMessage = isMultiple
    ? 'Delete'
    : users[0]?.userEmail;

  const handleConfirm = async () => {
    if (!caseId) return;
    const promises = users.map((user) =>
      deleteUserFromCase(caseId, user.userEmail)
    );
    try {
      await Promise.all(promises);
      openSnackbar(
        `User${isMultiple ? 's' : ''} deleted successfully`,
        'success'
      );
      onFinish();
      handleClose();
    } catch (error) {
      openSnackbar(`Failed to delete user${isMultiple ? 's' : ''}`, 'error');
    }
  };

  return (
    <IntentConfirmationDialog
      open={open}
      handleClose={handleClose}
      title={
        isMultiple
          ? 'User Delete Confirmation'
          : 'Bulk User Delete Confirmation'
      }
      onCancel={handleClose}
      onConfirm={handleConfirm}
      confirmButtonProps={{
        title: 'Delete',
        disabled: confirmationMessage !== expectedConfirmationMessage,
      }}
      cancelButtonProps={{
        title: 'Cancel',
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '24px',
        }}
        gutterBottom
      >
        {isMultiple
          ? 'Are you sure you want to delete these users?'
          : 'Are you sure you want to delete this user?'}
      </Typography>
      <Typography gutterBottom>
        {isMultiple
          ? 'Performing this action will remove these users ability to login, if they wish to use ClaimScore again they must create a new account; this email will be available to use again.'
          : `Type in the user's email address and press the delete button to confirm:`}
      </Typography>
      {isMultiple ? (
        <Typography gutterBottom>
          {"Type in 'Delete' and click the button to confirm:"}
        </Typography>
      ) : (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '24px',
          }}
          gutterBottom
        >
          {users[0]?.userEmail}
        </Typography>
      )}

      <TextField
        fullWidth
        placeholder={`Type ${
          isMultiple ? 'Delete' : users[0]?.userEmail
        } in to delete`}
        onChange={(event) => {
          setConfirmationMessage(event.target.value);
        }}
      />
    </IntentConfirmationDialog>
  );
};

import React from 'react';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { Currency as CurrencyIcon } from '../../../icons/currency';
import { currencyFormatter } from '../../../utils/msw/number-formatter';
import {
  useLoadingOverlay,
  useSelectedCaseAggregatedData,
} from '../../../store';

export const TotalProfit = (props) => {
  const [selectedCaseAggregatedData] = useSelectedCaseAggregatedData();
  const [loadingOverlay] = useLoadingOverlay();

  return (
    <Card {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">
              TOTAL PAYOUT
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {loadingOverlay.showLoadingOverlay ? (
                <Skeleton />
              ) : (
                currencyFormatter.format(
                  Number(selectedCaseAggregatedData.totalPayout) / 100 || 0
                )
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: 56,
                width: 56,
              }}
            >
              <CurrencyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

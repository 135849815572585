import { format } from 'date-fns';
import sortBy from 'lodash/sortBy';
import indexOf from 'lodash/indexOf';
import { currencyFormatter } from '../../utils/msw/number-formatter';

export const emptyClaim = {
  type: null,
  maxUnits: null,
  householdLimit: null,
  payout: {
    value: '',
    raw: '',
  },
};

export const caseTypeValues = [
  'Defined Purchaser Only',
  'Undefined Purchaser Only',
  'Defined & Undefined Purchaser',
];

export const emptyForm = {
  caseName: '',
  caseNumber: '',
  stateId: '',
  deadline: null,
  claimTypes: [
    { ...emptyClaim, type: 'Non-PoP' },
    { ...emptyClaim, type: 'PoP' },
    { ...emptyClaim, type: 'Direct Notice' },
  ],
  caseType: caseTypeValues[0],
  jurisdiction: {
    unrestricted: false,
    usa: true,
    ust: false,
    canada: false,
  },
};

const jurisdictionValues = {
  usa: 'United States',
  ust: 'US Territories',
  canada: 'Canada',
};

export const emptyCaseDetails = {
  caseName: 'TEST CASE',
  caseNumber: 'TEST NUMBER',
  stateID: 'USDC NJ',
  maxUnits: 3,
  householdLimit: 3,
  payout: 30,
  deadline: '10/9/2022',
  jurisdiction: 'United States',
};

export const checkboxes = {
  unrestricted: false,
  usa: false,
  ust: false,
  canada: false,
};

export const unrestrictedJurisdiction = {
  unrestricted: true,
  usa: true,
  ust: true,
  canada: true,
};

const getOrderedClaimTypes = (claims) => {
  var order = ['Non-PoP', 'PoP', 'Direct Notice'];
  return sortBy(claims, (claim) => indexOf(order, claim.type));
};

export function mapCaseDetailsToForm(caseDetails) {
  const jurisdiction = caseDetails?.jurisdiction;
  if (!jurisdiction) return emptyForm;
  const isUSA = jurisdiction.includes(jurisdictionValues.usa);
  const isUST = jurisdiction.includes(jurisdictionValues.ust);
  const isCanada = jurisdiction.includes(jurisdictionValues.canada);

  const isUnrestricted = isUSA && isUST && isCanada;

  const formValues = {
    caseName: caseDetails.caseName,
    caseNumber: caseDetails.caseNumber,
    stateId: caseDetails.stateID,
    units: caseDetails.maxUnits,
    claimTypes: getOrderedClaimTypes(caseDetails.claimTypes).map((claim) => ({
      ...claim,
      payout: {
        value: currencyFormatter.format(claim.payout),
        raw: claim.payout,
      },
    })),
    householdLimit: caseDetails.householdLimit,
    payout: {
      value: currencyFormatter.format(caseDetails.costPerUnit),
      raw: caseDetails.costPerUnit,
    },
    deadline: new Date(caseDetails.deadline).toISOString(),
    jurisdiction: {
      unrestricted: isUnrestricted,
      usa: isUnrestricted || isUSA,
      ust: isUnrestricted || isUST,
      canada: isUnrestricted || isCanada,
    },
  };
  return formValues;
}

export function mapFormToCaseDetails(form, claimTypes) {
  delete form.jurisdiction.unrestricted;
  return {
    caseName: form.caseName,
    caseNumber: form.caseNumber,
    stateID: form.stateId,
    claimTypes: claimTypes.map((claim) => ({
      maxUnits: Number(claim.maxUnits),
      householdLimit: Number(claim.householdLimit),
      payout: Number(claim.payout.raw),
      type: claim.type,
    })),
    deadline: format(new Date(form.deadline), 'MM/dd/yyyy'),
    jurisdiction: Object.keys(form.jurisdiction)
      .filter((value) => form.jurisdiction[value])
      .map((key) => jurisdictionValues[key]),
    caseType: form.caseType,
  };
}

import { AxiosRequestConfig } from 'axios';
import { getAuthToken } from '../gcp/auth';

export const authenticateInterceptor = async (config: AxiosRequestConfig) => {
  const authorization_token = await getAuthToken();
  const newConfig = { ...config };
  if (newConfig.headers) {
    newConfig.headers.Authorization = `Bearer ${authorization_token}`;
  }
  return newConfig;
};

import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const StyledDiv = styled.div(({ theme }: { theme: Theme }) => ({
  color: theme.palette.background.paper,
  borderRadius: '50%',
  backgroundColor: theme.palette.error.light,
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const Error403 = () => {
  const navigate = useNavigate();

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Stack alignItems="center" sx={{ maxWidth: '600px' }}>
        <StyledDiv>
          <WarningIcon sx={{ fontSize: 30 }} />
        </StyledDiv>
        <Typography
          sx={{ mt: 2 }}
          color="textPrimary"
          variant="h1"
          textAlign="center"
        >
          Access Denied /Forbidden
        </Typography>
        <Typography sx={{ mt: 3 }} variant="body2" textAlign="center">
          {`Sorry, but you don't have permission to access this page,`}
        </Typography>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Typography variant="body2" textAlign="center">
            you can go back to the&nbsp;
          </Typography>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            sx={{ p: '0px' }}
          >
            <Typography
              color="textSecondary"
              variant="body2"
              sx={{
                fontWeight: 'fontWeightBold',
                color: 'primary.main',
                textDecoration: 'underline',
              }}
            >
              previous page.
            </Typography>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Error403;

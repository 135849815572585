import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import CaseFormForm from '../../components/case-form-form';

const CaseForm = () => (
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >
    <Container maxWidth="lg">
      <Grid item lg={8} md={6} xs={12}>
        <CaseFormForm />
      </Grid>
    </Container>
  </Box>
);

export default CaseForm;

import * as React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import classes from './draggable-list-item.module.scss';

const DraggableListItem = ({ id, index, children }) => {
  return (
    <Draggable key={id} draggableId={id} index={index} isDragDisabled={false}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          {children(provided)}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableListItem;

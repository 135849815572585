export enum Role {
  Admin = 'Admin',
  Owner = 'Owner',
  Reviewer = 'Reviewer',
  Viewer = 'Viewer',
  User = 'User',
}

export enum Status {
  Active = 'Active',
  Rejected = 'Rejected',
  PendingEmail = 'Pending Email',
  PendingApproval = 'Pending Approval',
}

export type UserRole = {
  userId: string;
  userEmail: string;
  userStatus: Status;
  userRole: Role;
};

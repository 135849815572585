import React from 'react';
import { v4 as uuid } from 'uuid';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import BorderHorizontalIcon from '@mui/icons-material/BorderHorizontal';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DnsIcon from '@mui/icons-material/Dns';

export const elementTypes = [
  { type: 'markdown', title: 'Paragraph', Icon: () => <TextFieldsIcon /> },
  { type: 'button', title: 'Button', Icon: () => <TouchAppIcon /> },
  {
    type: 'divider',
    title: 'Line Break',
    Icon: () => <BorderHorizontalIcon />,
  },
  {
    type: 'reminder',
    title: 'Reminder',
    Icon: () => <NotificationsActiveIcon />,
  },
  { type: 'selection', title: 'Option Selection', Icon: () => <DnsIcon /> },
];

const baseElement = {
  id: null,
  title: null,
  type: null,
  wrapper: null,
  text: null,
  to: null,
  group: null,
  options: null,
  headline: null,
};

const baseOption = { id: null, description: '', value: '' };

export const getNewOption = () => ({
  ...baseOption,
  id: uuid(),
});

export const generateElement = (type, list) => {
  const newElement = { ...baseElement };
  const newOption = { ...baseOption };
  const elementNumber = list.filter((element) => element.type === type).length;
  switch (type) {
    case 'markdown':
      newElement.title = `Paragraph Section ${
        elementNumber > 0 ? elementNumber : ''
      }`;
      newElement.wrapper = 'none';
      newElement.headline = '';
      newElement.text = '';
      break;
    case 'button':
      newElement.title = `Button ${elementNumber > 0 ? elementNumber : ''}`;
      newElement.wrapper = 'none';
      newElement.text = '';
      newElement.to = '';
      break;
    case 'divider':
      newElement.title = `Line Break ${elementNumber > 0 ? elementNumber : ''}`;
      newElement.wrapper = 'none';
      break;
    case 'reminder':
      newElement.title = `Reminder ${elementNumber > 0 ? elementNumber : ''}`;
      newElement.wrapper = 'reminder';
      newElement.text = '';
      break;
    case 'selection':
      newElement.title = `Option Selection ${
        elementNumber > 0 ? elementNumber : ''
      }`;
      newElement.wrapper = 'none';
      newElement.group = 'number';

      newOption.id = uuid();
      newOption.permanent = true;
      newElement.options = [newOption];

      break;
    default:
      throw new Error(`Unexpected Element: ${type}`);
  }
  newElement.id = uuid();
  newElement.type = type;
  return newElement;
};

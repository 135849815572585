import React from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  IconButtonProps,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

export const CollapsibleHeader = styled(CardContent)`
  display: flex;
  padding: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const CollapsibleTitle = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
`;

export const CollapsibleContainer = styled(CardContent)`
  padding: 0;
  width: 100%;
  &:last-child {
    padding-bottom: 0;
  }
`;

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
}));

export const StyledDataGrid = styled(DataGrid)((props) => ({
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: props.theme.palette.grey[900],
    color: props.theme.palette.background.paper,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  '& .MuiDataGrid-columnSeparator': { display: 'none' },
  '& .MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
    width: 'unset',
  },
  minHeight: props.loading ? '600px' : 0,
  '& .MuiDataGrid-sortIcon': { color: props.theme.palette.background.paper },
  '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
    { opacity: 0.5 },
  '& 	.MuiDataGrid-row': { cursor: 'pointer' },
  '.MuiDataGrid-columnHeader:focus': { outline: 'none' },
}));

export const DetailCard = styled(Card)`
  width: 564px;
  margin: 10px auto;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  border-radius: 8px;
`;

type CompareColor = {
  color: 'primary' | 'secondary';
};

export const CompareChip = styled.div<CompareColor>`
  background: ${(props) => props.theme.palette.compare[`${props.color}Bkg`]};
  color: ${(props) => props.theme.palette.compare[props.color]};
  margin-right: 16px;
  padding: 6px 8px;
  border-radius: 8px;
  font-size: 12px;
  letter-spacing: 1px;
`;

export const SelectedRowDot = styled.div<CompareColor>`
  background: ${(props) =>
    props.color ? props.theme.palette.compare[props.color] : 'transparent'};
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-right: 16px;
`;

export const ColoredPinFilled = styled(PushPinIcon)<CompareColor>`
  color: ${(props) => props.color && props.theme.palette.compare[props.color]};
  margin-right: 16px;
  font-size: 18px;
`;

export const ColoredPinOutlined = styled(PushPinOutlinedIcon)<CompareColor>`
  color: ${(props) => props.color && props.theme.palette.compare[props.color]};
  margin-right: 16px;
  font-size: 18px;
`;

export const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const RulingWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

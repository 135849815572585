import React, { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { DashboardError } from './dashboard-error';
import ErrorBoundary from '../error-boundary';

const DashboardLayoutRoot = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
}));

export const DashboardLayout = ({ withSidebar = false, children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <ErrorBoundary>
        <DashboardLayoutRoot
          sx={{
            paddingLeft: ['0px', null, null, withSidebar ? '280px' : '0px'],
            paddingRight: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {children}
          </Box>
        </DashboardLayoutRoot>
        <DashboardNavbar
          withSidebar={withSidebar}
          onSidebarOpen={() => setSidebarOpen(true)}
        />
        {withSidebar && (
          <DashboardSidebar
            onClose={() => setSidebarOpen(false)}
            open={isSidebarOpen}
          />
        )}
        <DashboardError />
      </ErrorBoundary>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { DataGrid } from '@mui/x-data-grid';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { Paper } from '@mui/material';
import {
  emptyForm,
  mapFormToExportRequest,
  formatClaimantsRequestUnits,
} from './report-filter/utils';
import * as casesClient from '../../services/cases.service';
import {
  useLoadingOverlay,
  usePageNumber,
  usePageSize,
  useSelectedCase,
  useSelectedCaseAggregatedData,
  useSelectedCaseTableRows,
  useTotalTableEntriesCount,
  useReportFilters,
  usePlaintiffID,
} from '../../store';
import {
  currencyFormatter,
  numberFormatter,
} from '../../utils/msw/number-formatter';

const headerStyles = { backgroundColor: 'grey.900', color: 'background.paper' };

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-columnHeaders': headerStyles,
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  '& .MuiDataGrid-columnSeparator': { display: 'none' },
  '& .MuiDataGrid-iconButtonContainer': { visibility: 'visible' },
}));

const columns = [
  {
    field: 'id',
    headerName: 'PLAINTIFF ID',
    flex: 1,
    maxWidth: 330,
    minWidth: 160,
    filterable: false,
  },
  {
    field: 'score',
    flex: 1,
    filterable: false,
    headerName: 'SCORE',
    valueGetter: ({ row }) => numberFormatter.format(row.score),
    maxWidth: 90,
    minWidth: 90,
  },
  {
    field: 'determination',
    flex: 1,
    filterable: false,
    headerName: 'DETERMINATION',
    renderCell: ({ value }) => (
      <div
        style={value === 'REJECTED' ? { color: 'red', fontWeight: '600' } : {}}
      >
        {value}
      </div>
    ),
    maxWidth: 145,
    minWidth: 145,
  },
  {
    filterable: false,
    sortable: false,
    flex: 1,
    field: 'rejectionCodes',
    headerName: 'DEDUCTION CODES',
    valueGetter: ({ row }) =>
      row.rejectionCodes.length > 0 ? row.rejectionCodes.join(', ') : '-',
    minWidth: 200,
  },
  {
    field: 'eligibleUnits',
    flex: 1,
    maxWidth: 140,
    minWidth: 130,
    filterable: false,
    headerName: 'ELIGIBLE UNITS',
  },
  {
    field: 'payoutDue',
    flex: 1,
    maxWidth: 140,
    minWidth: 130,
    filterable: false,
    headerName: 'PAYOUT DUE',
    valueGetter: ({ row }) =>
      currencyFormatter.format(Number(row.payoutDue) / 100 || 0) || '-',
  },
];

export const CaseListTable = ({ rows }) => {
  const { caseId } = useParams();
  const [page, setPage] = usePageNumber();
  const [pageSize, setPageSize] = usePageSize(10);
  const [sort, setSort] = useState({ field: '', sort: 'asc' });
  const [selectedCase, setSelectedCase] = useSelectedCase();
  const [loadingOverlay, setLoadingOverlay] = useLoadingOverlay();
  const [totalTableEntriesCount, setTotalTableEntriesCount] =
    useTotalTableEntriesCount();
  const [, setSelectedCaseTableRows] = useSelectedCaseTableRows();
  const [, setSelectedCaseAggregatedData] = useSelectedCaseAggregatedData();
  const [reportFilters, setReportFilters] = useReportFilters();
  const [plaintiffIDToSearch] = usePlaintiffID();

  const handleLimitChange = (size) => {
    setPageSize(size);
  };

  const handlePageChange = (number) => {
    setPage(number);
  };

  useEffect(() => {
    const getPlaintiffPage = async (showLoading) => {
      if (showLoading) setLoadingOverlay(true);
      let caseID = selectedCase.caseID || caseId;
      const emptyFiltersWithCaseID = mapFormToExportRequest(
        emptyForm,
        caseID,
      );

      if (!reportFilters.caseID) {
        setReportFilters(emptyFiltersWithCaseID);
      }

      const { data: plaintiffData } = await casesClient.getAllPlaintiffs(
        caseID,
        page,
        pageSize,
        sort?.field,
        sort?.sort,
        formatClaimantsRequestUnits(reportFilters),
        plaintiffIDToSearch,
      );

      const reportsWithApprovedDetermination = {
        ...reportFilters,
        determination: {
          approved: true,
          rejected: false,
        },
      };

      const useDefaultReportFilters = isEqual(
        reportFilters,
        emptyFiltersWithCaseID
      );

      const { data: aggregatedData } =
        await casesClient.getCaseAggregatedDetails(
          caseID,
          formatClaimantsRequestUnits(
            useDefaultReportFilters
              ? reportsWithApprovedDetermination
              : reportFilters
          )
        );
      setSelectedCaseTableRows(plaintiffData.results || []);
      setTotalTableEntriesCount(plaintiffData.total || 0);
      setSelectedCaseAggregatedData(aggregatedData.results || {});
      setLoadingOverlay(false);
    };
    getPlaintiffPage(true);
    const interval = setInterval(() => {
      getPlaintiffPage(false);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [
    page,
    pageSize,
    location.pathname,
    setLoadingOverlay,
    setSelectedCaseAggregatedData,
    setSelectedCaseTableRows,
    setTotalTableEntriesCount,
    setSelectedCase,
    selectedCase.caseID,
    sort?.field,
    sort?.sort,
    reportFilters,
  ]);

  return (
    <Paper
      sx={{
        overflow: 'auto',
        minHeight: loadingOverlay.showLoadingOverlay ? '600px' : 0,
      }}
    >
      <StyledDataGrid
        sx={{
          minHeight: loadingOverlay.showLoadingOverlay ? '600px' : 0,
          '& .MuiDataGrid-columnHeader': headerStyles,
          '& .MuiDataGrid-sortIcon': { color: 'background.paper' },
          '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
            { opacity: 0.5 },
          '& .MuiDataGrid-iconButtonContainer': { width: 'unset' },
          '.MuiDataGrid-columnHeader:focus': { outline: 'none' },
        }}
        loading={loadingOverlay.showLoadingOverlay}
        disableColumnMenu
        autoHeight
        sortingMode="server"
        paginationMode="server"
        rows={!loadingOverlay.showLoadingOverlay ? rows : []}
        rowCount={totalTableEntriesCount}
        columns={columns}
        getRowId={() => uuid()}
        pageSize={pageSize}
        onSortModelChange={([sort]) => {
          if (sort) {
            setSort({ field: sort.field, sort: sort.sort });
            setPage(0);
          } else {
            setSort({ field: '', sort: 'asc' });
          }
        }}
        sortModel={[{ field: sort.field ? sort.field : '', sort: sort.sort }]}
        rowsPerPageOptions={[5, 10, 25]}
        onPageChange={handlePageChange}
        page={page}
        onPageSizeChange={handleLimitChange}
        components={{
          LoadingOverlay: () => <div></div>,
        }}
      />
    </Paper>
  );
};

CaseListTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

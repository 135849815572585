import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GCP_API_KEY,
  authDomain: process.env.REACT_APP_GCP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_GCP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_GCP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_GCP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_GCP_APP_ID,
  measurementId: process.env.REACT_APP_GCP_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);

import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { LoadingFullPage } from '../../components/loading-fullpage';
import { PATHS } from '../../utils/routes/paths';

const AuthManagement = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const mode = searchParams.get('mode');
  const actionCode = searchParams.get('oobCode');
  const userId = searchParams.get('userId');

  useEffect(() => {
    switch (mode) {
      case 'resetPassword':
        navigate(PATHS.passwordReset, {
          state: {
            actionCode,
          },
        });
        break;
      case 'verifyEmail':
        navigate(PATHS.emailVerification, {
          state: {
            actionCode,
            userId,
          },
        });
        break;
      default:
        navigate(PATHS.login);
    }
  }, [mode, actionCode]);

  return <LoadingFullPage />;
};

export default AuthManagement;
